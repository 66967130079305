import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as ProfileIcon } from '../../../assets/images/icons/Profile.svg';
import useTranslate from '../../../hooks/useTranslate/useTranslate';

const People = ({ numAdults, numChildren }) => {
  const { t } = useTranslate();
  const childrenText = numChildren > 1 ? t('children') : t('child');

  return (
    <div className="d-flex my-4">
      <div style={{ marginRight: '15px' }}>
        <ProfileIcon />
      </div>
      <div>
        {numAdults} {numAdults > 1 ? t('adults') : t('adult')}
        {numChildren ? `, ${numChildren} ${childrenText}` : ''}
      </div>
    </div>
  );
};

People.propTypes = {
  numAdults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  numChildren: PropTypes.number,
};

export default People;
