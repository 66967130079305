import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import styles from './CurrencyPickerHeader.module.css';

function CurrencyPickerHeader({ currencies, open }) {
  const currency = useSelector((state) => state.appSettings.currencies.current);
  return (
    <div
      className={styles.Currency__currencySelected}
      data-testid="mainCurrencyDesktop"
    >
      {currency ? (
        <>
          {currencies[currency]}
          <span
            className={
              open ? styles.Currency__arrowUp : styles.Currency__arrowDown
            }
            data-testid="spanArrow"
          ></span>
        </>
      ) : (
        <Skeleton width={150} containerTestId="skeleton" />
      )}
    </div>
  );
}
CurrencyPickerHeader.propTypes = {
  currencies: PropTypes.object,
  open: PropTypes.bool,
};

export default CurrencyPickerHeader;
