import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import EditConfirmationBookingsModal from '../EditConfirmationBookingsModal/EditConfirmationBookingsModal';
import styles from './../BookingsConfirmationActionButtons/BookingActionButtonsModal.module.css';

const EditConfirmationBookingsButton = ({ bookings, onEdit }) => {
  const { t } = useTranslate();
  const [
    editConfirmationBookingsModalOpen,
    setEditConfirmationBookingsModalOpen,
  ] = useState(false);

  const handleEditBookingBtnClick = () =>
    bookings.length > 1
      ? setEditConfirmationBookingsModalOpen(true)
      : onEdit(bookings[0]);

  const handleEditConfirmationBookingsModalConfirm = (booking) => {
    setEditConfirmationBookingsModalOpen(false);
    onEdit(booking);
  };

  return (
    <div>
      <EditConfirmationBookingsModal
        open={editConfirmationBookingsModalOpen}
        bookings={bookings}
        onConfirm={handleEditConfirmationBookingsModalConfirm}
        onCancel={() => {
          setEditConfirmationBookingsModalOpen(false);
        }}
      />
      <div className={styles.BookingActionButtonsModal__tool}>
        <div className={styles.BookingActionButtonsModal__header}>
          {t('Changing Reservations')}
        </div>
        <div className={styles.BookingActionButtonsModal__body}>
          {t('Click here to make changes to this reservation.')}
        </div>
        <Button
          className="button transparent w-100"
          onClick={handleEditBookingBtnClick}
        >
          {t('Change this Reservation')}
        </Button>
      </div>
    </div>
  );
};
EditConfirmationBookingsButton.propTypes = {
  bookings: PropTypes.array,
  onEdit: PropTypes.func,
};

export default EditConfirmationBookingsButton;
