import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'reactstrap';
import AccesibilityElement from '../../../../components/AccesibilityElement/AccesibilityElement';
import FeedbackModal from '../../../../components/FeedBackModal/FeedbackModal';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';

import styles from '../../Footer.module.css';

const Contact = ({ loading, address, phone, contactUsLink, productCode }) => {
  const { t } = useTranslate();
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);

  return (
    <>
      <FeedbackModal
        open={openFeedbackModal}
        productCode={productCode}
        onClose={() => setOpenFeedbackModal(false)}
      />
      <ul className={styles.Contact__ul}>
        <li>
          <span>{t('Reservations')}</span>
          <span> · </span>
          {loading ? (
            <Skeleton
              width={150}
              className="mx-2"
              containerTestId="phoneSkeleton"
            />
          ) : (
            <a href={`tel:${phone}`} title="">
              {phone}
            </a>
          )}
        </li>
        <li>
          <span>{t('Address')}</span>
          <span> · </span>
          {address}
        </li>
      </ul>
      <div>
        {contactUsLink && (
          <Button
            className="button transparent"
            onClick={() => window.open(contactUsLink)}
            aria-label="Go to contact us"
          >
            {t('Contact Us')}
          </Button>
        )}
      </div>
      <div className="my-4">
        <AccesibilityElement
          tabIndex={0}
          role="button"
          tagName="span"
          className={styles.Footer__shareButton}
          onClick={() => setOpenFeedbackModal(true)}
          aria-label="Open feedback form"
        >
          {t('Share Your Feedback')}
        </AccesibilityElement>
      </div>
    </>
  );
};

Contact.propTypes = {
  loading: PropTypes.bool,
  address: PropTypes.string,
  phone: PropTypes.string,
  contactUsLink: PropTypes.string,
  productCode: PropTypes.string,
};

export default Contact;
