import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mediaUrls: [],
};

export const mediaSlice = createSlice({
  initialState,
  name: 'mediaSlice',
  reducers: {
    setMedia: (state, action) => {
      state.mediaUrls = action.payload;
    },
  },
});

export const { setMedia } = mediaSlice.actions;

export const fetchMedia = (axios) => async (dispatch) => {
  try {
    let { data } = await axios.get('/bbe-media');
    dispatch(setMedia(data));
  } catch (e) {
    console.error(e);
  }
};

export default mediaSlice.reducer;
