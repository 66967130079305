import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { CHINA_VERSION } from '../../globals';
import usePrevious from '../../hooks/usePrevious/usePrevious';
import { useScreenDetector } from '../../hooks/useScreenDetector/useScreenDetector';
import {
  setCurrencySelectedByUser,
  setCurrentCurrency,
} from '../../redux/slices/appSettingsSlice/appSettingsSlice';
import CurrencyDesktop from './CurrencyDesktop';
import CurrencyMobile from './CurrencyMobile';

const Currency = ({ hotelCurrency }) => {
  const [currencyMobileOpen, setCurrencyMobileOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { isLargeDesktop } = useScreenDetector();

  const currencyParam = searchParams.get('currency');

  const prevHotelCurrency = usePrevious(hotelCurrency);
  const currentCurrency = useSelector(
    (state) => state.appSettings.currencies.current
  );

  const currencies = useSelector((state) => state.appSettings.currencies.list);

  const currencySelectedByUser = useSelector(
    (state) => state.appSettings.currencies.selectedByUser
  );

  const handleCurrencySelectCurrency = async (currencyCode) => {
    dispatch(setCurrencySelectedByUser(true));
    dispatch(setCurrentCurrency(currencyCode));
    setCurrencyMobileOpen(false);
  };

  useEffect(() => {
    if (hotelCurrency) {
      if (currencyParam) {
        dispatch(setCurrencySelectedByUser(true));
        dispatch(setCurrentCurrency(currencyParam));
        const params = new URLSearchParams(searchParams);
        setSearchParams(params);
        return;
      } else if (
        prevHotelCurrency !== hotelCurrency &&
        !currencySelectedByUser
      ) {
        dispatch(setCurrentCurrency(CHINA_VERSION ? 'CNY' : hotelCurrency));
        return;
      }
    }
  }, [
    currencyParam,
    currencySelectedByUser,
    currentCurrency,
    dispatch,
    hotelCurrency,
    prevHotelCurrency,
    searchParams,
    setSearchParams,
  ]);

  const defaultCurrency = CHINA_VERSION ? 'CNY' : hotelCurrency || 'GBP';

  return (
    <>
      {isLargeDesktop ? (
        <div data-testid="desktopCurrencyPicker">
          <CurrencyDesktop
            defaultCurrency={defaultCurrency}
            currencies={currencies}
            onSelectCurrency={handleCurrencySelectCurrency}
          />
        </div>
      ) : (
        <div data-testid="mobileCurrencyPicker">
          <CurrencyMobile
            defaultCurrency={defaultCurrency}
            open={currencyMobileOpen}
            onClose={() => setCurrencyMobileOpen(false)}
            currencies={currencies}
            onCurrencyPickerHeaderClick={() => setCurrencyMobileOpen(true)}
            onSelectCurrency={handleCurrencySelectCurrency}
          />
        </div>
      )}
    </>
  );
};

Currency.propTypes = {
  hotelCurrency: PropTypes.string,
};

export default Currency;
