const bookingInteraction = (interaction) => {
  if (!window.dataLayer) return;

  const data = {
    event: 'bookingInteraction',
    ctaName: interaction,
  };

  window.dataLayer.push(data);
};

export default bookingInteraction;
