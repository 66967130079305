import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import NearbyProduct from './components/NearbyProduct/NearbyProduct';
import NearbyProductMessage from './components/NearbyProductMessage/NearbyProductMessage';

const NearbyProductsList = ({ products, params, productCode }) => {
  const { t } = useTranslate();
  const hotels = useSelector((state) => state.belmond.hotels);
  const media = useSelector((state) => state.media.mediaUrls);

  const getHotelImage = (productCode) => {
    return media.find((mediaItem) =>
      mediaItem.includes(`HeroImages/${productCode}`)
    );
  };

  return (
    <div>
      {' '}
      <NearbyProductMessage
        hotelName={hotels[productCode].shortName}
        multipleAvailableProperties={products.length > 1}
      />
      <div
        style={{
          fontSize: '30px',
          textTransform: 'uppercase',
          margin: '25px 0',
        }}
      >
        {t('Nearby')} {products.length > 1 ? t('Properties') : t('Property')}
      </div>
      {products.map((product) => (
        <NearbyProduct
          key={product.minRoomPrice}
          name={hotels[product.productCode].shortName}
          location={hotels[product.productCode].region}
          currentCode={productCode}
          code={product.productCode}
          price={product.minRoomPrice}
          params={params}
          image={getHotelImage(product.productCode)}
        />
      ))}
    </div>
  );
};

NearbyProductsList.propTypes = {
  products: PropTypes.array,
  params: PropTypes.object,
  productCode: PropTypes.string,
};

export default NearbyProductsList;
