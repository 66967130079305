import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import LoadingButton from '../LoadingButton/LoadingButton';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';
import styles from './ConfirmationModal.module.css';

const ConfirmationModal = ({
  title,
  onConfirm,
  onCancel,
  cancelButton,
  confirmButton,
  content,
  open,
  className,
  loading,
}) => {
  return (
    <Modal
      centered
      isOpen={open}
      external={
        <ModalCloseButton
          onClose={onCancel}
          className={styles.ConfirmationModal__closeButton}
        />
      }
      contentClassName={className || styles.ConfirmationModal__modalWrapper}
      toggle={onCancel}
    >
      <ModalBody className={styles.ConfirmationModal__body}>
        <div className="d-flex flex-column justify-content-center align-items-center text-center">
          {title ? <h2>{title}</h2> : <div className="mb-3" />}
          <div className={styles.ConfirmationModal__content}>{content}</div>
        </div>

        <div className={styles.ConfirmationModal__buttons}>
          {cancelButton && (
            <Button
              className="button transparent mb-2"
              aria-label="Cancel"
              onClick={() => {
                onCancel();
                document.body.style = {};
              }}
            >
              {cancelButton}
            </Button>
          )}
          {confirmButton && (
            <LoadingButton
              className="button mb-2"
              onClick={() => {
                onConfirm();
                document.body.style = {};
              }}
              type="button"
              loading={loading || false}
              ariaLabel="Confirm"
            >
              {confirmButton}
            </LoadingButton>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
ConfirmationModal.propTypes = {
  title: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  cancelButton: PropTypes.string,
  confirmButton: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

export default ConfirmationModal;
