import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Controller } from 'react-hook-form';
import { Input, Label } from 'reactstrap';
import { ReactComponent as CalendarIcon } from '../../assets/images/icons/Calendar.svg';
import useLocalisedMoment from '../../hooks/useLocalisedMoment/useLocalisedMoment';
import Picker from '../Picker/Picker';
import './DatePicker.css';

const DatePicker = ({
  name,
  control,
  label,
  setValue,
  rules,
  autoComplete,
  disabled,
}) => {
  const moment = useLocalisedMoment();
  const [date, setDate] = useState('');
  return (
    <Picker
      className="DatePicker"
      picker={
        <div style={{ position: 'relative' }}>
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <Input
                type="text"
                readOnly
                className="bbeFormControl inputText"
                {...field}
                onChange={(e) => {
                  setValue(name, e.target.value);
                  field.onChange(e.target.value);
                }}
                ref={field.ref}
                aria-label={name}
                id={`${name}ID`}
                data-testid="input"
                aria-describedby={`${name}-error`}
                autoComplete={autoComplete}
                disabled={disabled}
              />
            )}
          />
          {label && <Label className="floatingLabel">{label}</Label>}
          <CalendarIcon className="calendarIcon" />
        </div>
      }
      content={(close) => (
        <Calendar
          className="DatePicker_Calendar"
          minDate={new Date()}
          onChange={(item) => {
            const dateString = moment(item).format('D MMM YYYY');
            setDate(dateString);
            setValue(name, dateString);
            close();
          }}
          date={date ? new Date(date) : null}
        />
      )}
      label="DatePicker"
    />
  );
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  rules: PropTypes.object,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DatePicker;
