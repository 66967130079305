import PropTypes from 'prop-types';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/icons/ArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/images/icons/ArrowUp.svg';
import { ReactComponent as TrashIcon } from '../../assets/images/icons/Trash.svg';
import styles from './Accordian.module.css';

const Accordian = forwardRef(
  ({ title, children, defaultOpen, onRemove }, ref) => {
    const [open, setOpen] = useState(false);
    const isMounted = useRef(false);

    useImperativeHandle(ref, () => ({
      setOpen,
    }));

    useEffect(() => {
      if (!isMounted.current && defaultOpen) {
        setOpen(true);
      }
      isMounted.current = true;
    }, [defaultOpen]);

    return (
      <>
        <button
          aria-label="open accordian"
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!open);
          }}
          className={styles.Accordian__ButtonContainer}
          data-testid={`accordian-${title}`}
        >
          <div className="d-flex justify-content-between align-items-center w-100 text-uppercase">
            <div
              className="d-flex flex-1 align-items-center"
              style={{ fontSize: 14, fontWeight: 600 }}
            >
              {title}
              <TrashIcon
                style={{ marginLeft: 12 }}
                onClick={onRemove}
                aria-label={`Remove ${title}`}
              />
            </div>

            {open ? (
              <div style={{ marginTop: -10 }}>
                <ArrowUpIcon data-testid="arrowUp" />
              </div>
            ) : (
              <ArrowDownIcon data-testid="arrowDown" />
            )}
          </div>
        </button>
        {open && (
          <div className="mt-2" aria-expanded={open}>
            {children}
          </div>
        )}
      </>
    );
  }
);

Accordian.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  defaultOpen: PropTypes.bool,
  onRemove: PropTypes.func,
};

export default Accordian;
