import Cookies from 'universal-cookie';
import { CHINA_VERSION } from '../../globals';

const cookies = new Cookies();

const otherPages = {
  '/findreservation': 'find-reservation',
  '/maintenance': 'maintenance',
};

const pageView = (hotel) => {
  if (!window.dataLayer) return;
  if (!window.DY) return;

  if (CHINA_VERSION) {
    window.dataLayer = [];
    return;
  }

  const data = {
    event: 'virtualPageview',
    pageType: 'Booking Engine Page',
    language: cookies.get('langPref')?.split('-')[0] || 'en',
    productSiteUnivers: hotel ? 'Hotel' : '',
    PiD: hotel?.productCode || 'BEL',
    name: hotel?.shortName || 'Belmond',
    city: hotel?.address.city || 'London',
    country: hotel?.address.country || 'UK',
    pageMidCategory: '',
    pageSubCategory: '',
    pageSubSubCategory: '',
    pageTopCategory: '',
  };

  window.dataLayer.push(data);

  let type = 'CART';
  let dyData = [];

  const otherPageKey = Object.keys(otherPages).find((page) => {
    return window.location.pathname.includes(page);
  });

  if (otherPageKey) {
    type = 'OTHER';
    dyData = otherPages[otherPageKey];
  }

  if (hotel?.productCode) {
    dyData.push(hotel.productCode);
  }

  if (dyData.length)
    // set the DY context here also
    DY.recommendationContext = {
      type,
      data: dyData,
    };
};

export default pageView;
