import { createSlice } from '@reduxjs/toolkit';
import client from '../../../helpers/authClient';
import initiateGoogleOneTap from '../../../hooks/useCheckReach5Session/helpers/googleOneTapSignIn';

const initialState = {
  accessToken: null,
  refreshToken: null,
  profile: null,
};

export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    setAuthTokens: (state, action) => {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    logout: (state) => {
      state.profile = null;
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
});

export const { logout, setAuthTokens, setProfile } = userSlice.actions;

export const fetchGuestProfile = (accessToken, axios) => async (dispatch) => {
  try {
    const res = await axios.get('/guest-profile', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch(setProfile(res.data));
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const fetchReach5Profile = (accessToken, axios) => async (dispatch) => {
  try {
    const accountProfile = await client.getUser({
      accessToken,
    });

    let guestProfileFound = false;
    if (accountProfile.externalId) {
      guestProfileFound = await dispatch(fetchGuestProfile(accessToken, axios));
    }

    if (!guestProfileFound) {
      dispatch(setProfile(accountProfile));
    }
  } catch (e) {
    dispatch(logout());
  }
};

export const refreshAccessToken = (refreshToken) => async (dispatch) => {
  try {
    const res = await client.refreshTokens({
      refreshToken: refreshToken,
      scope: 'openid profile phone full_write',
    });
    dispatch(setAuthTokens(res));
  } catch (e) {
    dispatch(logout());
  }
};

export const getAuthTokens = (authCode) => async (dispatch) => {
  try {
    const res = await client.exchangeAuthorizationCodeWithPkce({
      code: authCode,
      redirectUri: `${window.location.origin}/login/callback`,
    });
    dispatch(setAuthTokens(res));
  } catch (e) {
    console.log(e);
  }
};

let isSafari;
if (typeof navigator !== 'undefined') {
  isSafari = /^((?!chrome|android).)*safari/i.test(navigator?.userAgent);
}

export const checkActiveAuthSession = () => async (dispatch, getState) => {
  const { accessToken, refreshToken } = getState().user;

  try {
    const res = await client.checkSession({
      nonce: 'belmondAuth',
    });
    if (!accessToken || accessToken !== res.accessToken) {
      dispatch(setAuthTokens(res));
    }

    return !!res.accessToken;
  } catch (e) {
    if (refreshToken && isSafari) {
      dispatch(refreshAccessToken(refreshToken));
    } else if (accessToken) {
      dispatch(logout());
    }

    // If user is not logged in and has not visited the site before, show Google One Tap
    if (!document.cookie.includes('visited=true') && !accessToken) {
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 1);
      setTimeout(() => {
        initiateGoogleOneTap();
        document.cookie = `visited=true; expires=${expiryDate.toUTCString()}; path=/`;
      }, 5000);
    }

    return refreshToken || null;
  }
};

export default userSlice.reducer;
