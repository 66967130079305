const bookingFilters = (filterChoice, displayChoice) => {
  if (!window.dataLayer) return;

  const data = {
    event: 'bookingFilter',
    displayChoice,
    filterChoice,
  };

  window.dataLayer.push(data);
};
export default bookingFilters;
