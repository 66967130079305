import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';

import styles from '../../Footer.module.css';

const ExploreOurWorldList = ({ contactUsLink, findUsLink }) => {
  const { t } = useTranslate();
  return (
    <ul className={styles.ExploreOurWorld__ul}>
      {contactUsLink && (
        <li>
          <a href={contactUsLink} target="_blank" rel="noreferrer" title="">
            {t('Contact Us')}
          </a>
        </li>
      )}
      {findUsLink && (
        <li>
          <a href={findUsLink} target="_blank" rel="noreferrer" title="">
            {t('Find Us')}
          </a>
        </li>
      )}
      <li>
        <a
          href="https://www.belmond.com/gift-cards"
          target="_blank"
          rel="noreferrer"
          title=""
        >
          {t('Gift Cards')}
        </a>
      </li>
      <li>
        <a
          href="https://www.belmond.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
          title=""
        >
          {t('Privacy and Cookies')}
        </a>
      </li>
      <li>
        <a
          href="https://www.belmond.com/legal/terms-and-conditions"
          target="_blank"
          rel="noreferrer"
          title=""
        >
          {t('Terms and Conditions')}
        </a>
      </li>
    </ul>
  );
};

ExploreOurWorldList.propTypes = {
  contactUsLink: PropTypes.string,
  findUsLink: PropTypes.string,
};

export default ExploreOurWorldList;
