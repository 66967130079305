export const mapBookings = (bookings) =>
  bookings.map(
    ({
      id,
      encryptedEmail,
      paymentMethod,
      booking: {
        status,
        startDate,
        endDate,
        checkInTime,
        checkOutTime,
        numAdults,
        children,
        agent,
        groupCode,
        promoCode,
        roomType,
        synxisRatePlanCode,
        roomTypeCode,
        roomRates,
        addons,
        isMarketingOptin,
        cancellationPolicy,
        guarantee,
        cancelTimeIn,
        commissionPolicy,
        roomCategoryName,
        rateCurrency,
      },
      property: { code: productCode },
      secondaryGuests,
      guest: {
        firstName,
        lastName,
        personPreNameAdjunct,
        email,
        phone,
        notes,
        address,
      },
    }) => ({
      id,
      encryptedEmail,
      productCode,
      status,
      paymentMethod,
      guest: {
        firstName,
        lastName,
        prefix: personPreNameAdjunct,
        email,
        phone,
        address,
        notes,
      },
      isMarketingOptin,
      secondaryGuests: secondaryGuests.map(({ firstName, lastName }) => ({
        firstName,
        lastName,
      })),
      roomType: {
        title: roomType,
        category: { name: roomCategoryName },
        code: roomTypeCode,
      },
      roomRate: {
        currencyCode: rateCurrency,
        code: synxisRatePlanCode,
        name: roomRates[0].ratePlanDesc,
        guaranteePolicy: guarantee,
        cancellationPolicy,
        commissionPolicy,
        cancelTimeIn,
        pricesPerNight: roomRates.map((rate) => ({
          date: rate.date.replaceAll('-', '/'),
          price: rate.price,
          tax: rate.tax,
        })),
      },
      addons: addons.map((addon) => ({
        code: addon.itemCode,
        price: addon.price,
        tax: addon.tax,
        title: addon.itemDesc,
        currency: addon.currency,
      })),
      startDate,
      endDate,
      checkInTime,
      checkOutTime,
      numAdults,
      children,
      specialCodeType: groupCode ? 'groupCode' : promoCode ? 'promoCode' : null,
      specialCodeValue: groupCode || promoCode || null,
      agentId: agent?.id,
      agentCrmId: agent?.crmId,
      agent,
    })
  );
