const bookingSort = (sortChoice) => {
  if (!window.dataLayer) return;

  const data = {
    event: 'bookingSort',
    sortChoice,
  };

  window.dataLayer.push(data);
};
export default bookingSort;
