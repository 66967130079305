import getMinStayExclusions from './get-min-stay-exclusions';

// classes used
// disabledDate: grey out the day
// disabledPickedDay: red day
// checkOutOnly: closed off triangle
// noArrival: disables the checkOutOnly triangle when it its passed through

const getDayStyles = (
  date,
  moment,
  t,
  availableSelectionRange = [],
  daysAvailability = [],
  selectedRange = [],
  restrictedDates = []
) => {
  const isSelecting =
    !!availableSelectionRange?.length || !selectedRange?.length;

  let styles = { className: '', tooltipContent: null };

  // TODO: TEMP-CALENDAR-FIX
  if (daysAvailability.length === 0) return styles;

  if (!date) return styles;

  // if date is yesterday or before, disable it
  if (moment(date?.date).isBefore(moment(), 'day')) {
    return styles;
  }

  //  apply disabledDate to dates that are neither checkIn or checkOut whilst we aren't selecting
  const dayAvailability = daysAvailability.find(
    ({ date: d }) => d === date.date
  );

  if (!dayAvailability) return styles;

  const { isCheckIn, isCheckOut } = dayAvailability;

  // for all the non selection validation
  if (!isSelecting) {
    const isSelectedDate = selectedRange.find(
      (selectedDate) => selectedDate === date.date
    );

    // TODO: TEMP-CALENDAR-FIX
    if (!isCheckIn && !isCheckOut) {
      styles.className = isSelectedDate ? 'disabledPickedDay' : 'disabledDate';
      styles.tooltipContent = `${t(
        'Dates are unavailable but we may be able to accommodate you.'
      )} ${t('Please, contact the hotel directly.')}`;
      return styles;
    }

    // if a check out only date is selected but not the last selected date, disable it
    // UNLESS, it's a NoArrive date, in which case we should allow it

    if (!isCheckIn && isCheckOut) {
      const isNotLastSelectedDate =
        selectedRange[selectedRange.length - 1] !== date.date ||
        !selectedRange.length;

      const isNoArrive = daysAvailability.find(({ date: d }) => d === date.date)
        .metadata.NoArrive;

      const isPickedDisabled =
        isSelectedDate && isNotLastSelectedDate && !isNoArrive;
      const isDisabled = !isSelectedDate && !isNoArrive;

      const isLastSelectedDate =
        selectedRange[selectedRange.length - 1] === date.date;

      let className = '';
      let tooltipContent = '';

      if (isPickedDisabled) {
        styles.className = 'disabledPickedDay';
        styles.tooltipContent = t('Check-out only');
        return styles;
      }

      if (isDisabled || isLastSelectedDate) {
        // TODO: TEMP-CALENDAR-FIX
        styles.className = 'disabledDate';
        styles.tooltipContent = t('Check-out only');
        return styles;
      }

      if (isNoArrive && selectedRange[0] === date.date) {
        styles.className = 'disabledPickedDay';
        styles.tooltipContent = t('No arrivals on this date.');
        return styles;
      }

      // if its a no arrive but not the first selected date, disable it with noArrival class
      // this shows thats its not strictly blocked, but will be disabled initially for selection

      if (isNoArrive) {
        styles.className = 'noArrival';
        styles.tooltipContent = `${t('No arrivals on this date.')} ${t(
          'You will need to check in before in order to stay this night.'
        )}`;

        return styles;
      }

      styles = {
        className,
        tooltipContent,
      };

      return styles;
    }

    // thats the simple cases out the way..
    // lets now look into some more complex cases around restrictions

    // if we have a min stay arrive restriction on the first selected date
    // and the length of the min stay is greater than the current selection length
    // disable the current date

    const dayAvailability = daysAvailability.find(
      ({ date: d }) => d === selectedRange[0]
    );

    if (!dayAvailability) return styles;

    const {
      metadata: { MinStayArriveValue },
    } = dayAvailability;

    const daysFromTheStart = moment(date.date).diff(
      moment(selectedRange[0]),
      'days'
    );

    // add one to MinStayArrival, as the last day of the range doesn't count
    // ensure the days from start is positive to avoid past nights showing as disabled
    if (
      MinStayArriveValue &&
      selectedRange.length < MinStayArriveValue + 1 &&
      daysFromTheStart >= 0 &&
      daysFromTheStart < MinStayArriveValue
    ) {
      styles.className = 'disabledPickedDay';
      styles.tooltipContent = `${t(
        'You selected a restricted date but we may be able to accommodate you.'
      )} ${t('Please, contact the hotel directly.')}`;
      return styles;
    }

    // now lets look into the min stay through restrictions
    // for each date, look for MinStayThroughValue
    // if it exists, add to a list of excluded dates
    // we already have a function for that! lets use it

    let excludedDates = [];
    for (const [index, selectedDate] of selectedRange.entries()) {
      const dayAvailability = daysAvailability.find(
        ({ date: d }) => d === selectedDate
      );

      if (!dayAvailability) return styles;

      const {
        metadata: { MinStayThroughValue },
      } = dayAvailability;

      if (MinStayThroughValue) {
        const excludedDatesForDate = getMinStayExclusions(
          selectedRange[0],
          index,
          MinStayThroughValue,
          moment,
          true
        );

        excludedDates = [...excludedDates, ...excludedDatesForDate];
      }
    }

    // if the last selected date is beyond any date inside of the excludedDates array
    // then do not disable it, otherwise the min stay throughs are not satisfied

    if (
      selectedRange.includes(date.date) &&
      excludedDates.includes(selectedRange[selectedRange.length - 1])
    ) {
      styles.className = 'disabledPickedDay';
      styles.tooltipContent = `${t(
        'You selected a restricted date but we may be able to accommodate you.'
      )} ${t('Please, contact the hotel directly.')}`;
      return styles;
    }
  }

  // now we have handled the cases where the dates are selected
  // we need to now add some tooltips during selection that show the restrictions on disabled dates

  if (isSelecting) {
    const restrictedDate = restrictedDates.find((d) => d === date.date);

    if (restrictedDate) {
      styles.tooltipContent = `${t(
        'Dates are unavailable but we may be able to accommodate you.'
      )} ${t('Please, contact the hotel directly.')}`;
      return styles;
    }
  }
  return styles;
};

export default getDayStyles;
