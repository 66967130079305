import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'reactstrap';

function ErrorView() {
  const navigate = useNavigate();

  return (
    <div className="d-flex align-items-center justify-content-center w-100">
      <Card className="align-items-center m-5 p-5 justify-content-center text-center">
        <h1>OOPS!</h1>

        <h3>Something went wrong.</h3>
        <h3>Please refresh your browser or try again later.</h3>

        <Button
          className="button my-3"
          onClick={() => navigate('/')}
          aria-label="Go back to home page"
        >
          Go Home
        </Button>
        <Button
          className="button"
          onClick={() => window.location.reload()}
          aria-label="Refresh the current page"
        >
          Refresh the page
        </Button>
      </Card>
    </div>
  );
}

export default ErrorView;
