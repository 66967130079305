import dateFormat from 'dateformat';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { gtmPageView } from '../../gtm/events';
import useAxios from '../../hooks/useAxios/useAxios';
import useLocalisedMoment from '../../hooks/useLocalisedMoment/useLocalisedMoment';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import { fetchHotels } from '../../redux/slices/belmondSlice/belmondSlice';
import styles from './Maintenance.module.css';

const Maintenance = () => {
  const axios = useAxios();
  const moment = useLocalisedMoment();
  const { t } = useTranslate();
  const { hotel } = useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const media = useSelector((state) => state.media.mediaUrls);
  const countryCode = useSelector((state) => state.appSettings.countryCode);

  const getImage = (slug) => {
    return media.find((mediaItem) => mediaItem.includes(`Maintenance/${slug}`));
  };

  useEffect(() => {
    if (!hotel.maintenance) {
      navigate(`/select-room?productCode=${hotel.productCode}`);
    }
  }, [hotel.productCode, hotel.maintenance, navigate]);

  useEffect(() => {
    if (hotel.productCode && countryCode) {
      dispatch(fetchHotels(axios));
    }

    const interval = setInterval(() => {
      if (!document.hidden && countryCode) {
        dispatch(fetchHotels(axios));
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [dispatch, hotel.productCode, axios, countryCode]);

  useEffect(() => {
    gtmPageView();
  }, []);

  const { fromDateTime, toDateTime, message } = hotel.maintenance || {};
  const formattedFromDateTime = moment
    .utc(fromDateTime)
    .format('MMMM Do, YYYY HH:mm');

  const formattedToDateTime = moment
    .utc(toDateTime)
    .format('MMMM Do, YYYY HH:mm');

  return (
    <div>
      <div className="container-xxl d-flex flex-column">
        <div className="d-flex flex-column align-items-center">
          <div className={styles.Maintenance__date}>
            {dateFormat(Date.now(), 'dddd, mmmm dS yyyy')}
          </div>
        </div>
        <div className={styles.Maintenance__header}>
          {t("We're sorry, our systems are temporarily down for maintenance.")}
        </div>
        <div className={styles.Maintenance__imagesContainer}>
          <img
            alt="train"
            src={getImage('train')}
            className={styles.Maintenance__trainImage}
          />
          <img
            alt="pool"
            src={getImage('pool')}
            className={styles.Maintenance__poolImage}
          />
        </div>

        <div style={{ maxWidth: 800 }} className="text-center m-auto">
          <div className="text-center">{message}</div>
          <div className="text-center py-4">
            <div>
              {t('Estimated downtime')}: {formattedFromDateTime} &ndash;{' '}
              {formattedToDateTime} ({t('GMT/UTC Time')})
            </div>
            <div className="mt-4">
              {t(
                'During this downtime our call centres will not be able to make any new booking or change existing booking.'
              )}
            </div>
            <div>
              {t(
                "We'll be back online in a few hours, if you would like to get in touch in the meantime please"
              )}{' '}
              <a
                style={{ color: 'black' }}
                href="https://www.belmond.com/contact-us"
                target="_blank"
                rel="noreferrer"
              >
                {t('contact us')}
              </a>
              .
            </div>
          </div>
        </div>
        <Row
          style={{
            margin: '75px auto',
            width: '100%',
            maxWidth: 1100,
          }}
        >
          <Col md="6">
            <img
              alt="exploreBelmond"
              src={getImage('mountains')}
              className={styles.Maintenance__exploreBelmondImage}
              style={{
                objectPosition: '50% 80%',
              }}
            />
          </Col>
          <Col md="6" className={styles.Maintenance__pictureTextBox}>
            <div style={{ maxWidth: 500 }}>
              <div className={styles.Maintenance__date}>
                {t('Travel')}, {t('Lifestyle')}, {t('Culture')}
              </div>
              <h1>{t('Belmond Stories')}</h1>
              <div className={styles.Maintenance__description}>
                {t('Looking for someone to do while you wait?')}{' '}
                {t(
                  'Browse Belmond Stories for insider travel guides, authentic recipes and inspiring interviews with the characters who shape the world of Belmond.'
                )}
              </div>
              <div>
                <Button
                  className="button"
                  onClick={() =>
                    (window.location.href = 'https://www.belmond.com')
                  }
                >
                  {' '}
                  {t('Explore Belmond Stories')}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Maintenance;
