import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as CalendarIcon } from '../../../assets/images/icons/Calendar.svg';
import useLocalisedMoment from '../../../hooks/useLocalisedMoment/useLocalisedMoment';
import styles from './Dates.module.css';

const Dates = ({ startDate, endDate }) => {
  const moment = useLocalisedMoment();
  const startDateFormatted = moment(startDate).format('ddd, MMM D, YYYY');
  const endDateFormatted = moment(endDate).format('ddd, MMM D, YYYY');

  return (
    <div className="d-flex align-items-center my-4">
      <div style={{ marginRight: '15px' }}>
        <CalendarIcon />
      </div>
      <div className={styles.Dates__dates}>
        <div className="text-nowrap">{startDateFormatted} &ndash; </div>
        <div className={styles.Dates__endDate}>{endDateFormatted}</div>
      </div>
    </div>
  );
};

Dates.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default Dates;
