import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/images/icons/ArrowLeft.svg';
import { useScreenDetector } from '../../../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import { setCurrentBooking } from '../../../../redux/slices/bookingsSlice/bookingsSlice';

const ActionTopBar = ({ productCode, bookingId }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isDesktop } = useScreenDetector();

  const handleBackButtonClick = (productCode, bookingId) => {
    dispatch(setCurrentBooking(bookingId));
    navigate(`/edit-room?productCode=${productCode}`);
  };

  return (
    <div
      className="mb-4"
      style={{
        boxShadow: isDesktop && '0 -8px white,0 4px 12px 0px rgb(0 0 0 / 8%)',
        height: isDesktop ? 80 : 20,
      }}
    >
      {!isDesktop && <hr className="bbe-hr" />}

      <div
        className="container-xxl d-flex align-items-center h-100"
        style={{ marginTop: isDesktop ? 0 : 20 }}
      >
        <button
          aria-label="back to rooms and rates"
          className="d-flex align-items-center fw-600 py-1 text-uppercase"
          style={{
            fontSize: 14,
            paddingRight: 5,
          }}
          onClick={() => handleBackButtonClick(productCode, bookingId)}
        >
          <ArrowLeftIcon style={{ marginRight: 5, marginLeft: 15 }} />
          {t('Rooms & Rates')}
        </button>
      </div>
    </div>
  );
};

ActionTopBar.propTypes = {
  productCode: PropTypes.string,
  bookingId: PropTypes.string,
};

export default ActionTopBar;
