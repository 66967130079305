import { useFormContext } from 'react-hook-form';
import { Input } from 'reactstrap';
import useTranslate from '../../../../../../hooks/useTranslate/useTranslate';
import styles from './SortOptions.module.css';

const SortOptions = () => {
  const { t } = useTranslate();

  const displayOptions = [t('Rooms'), t('Rates')];
  const sortByOptions = [
    t('Recommended'),
    t('Lowest Price'),
    t('Highest Price'),
  ];
  const { setValue, watch } = useFormContext();
  const displayType = watch('displayType');
  const sortByValue = watch('sortBy');

  return (
    <div className={styles.SortOptions__container}>
      <div className={styles.SortOptions__inputContainer}>
        <span className={styles.SortOptions__filterLabel}>{t('Display')}</span>
        <Input
          type="select"
          className={styles.SortOptions__select}
          onChange={(e) =>
            setValue('displayType', e.target.value, { shouldDirty: true })
          }
          value={displayType}
          aria-label="displayValue-select"
        >
          {displayOptions.map((v) => (
            <option value={v} key={v}>
              {v}
            </option>
          ))}
        </Input>
      </div>

      <div className={styles.SortOptions__sortBy}>
        <span className={styles.SortOptions__filterLabel}>{t('Sort By')}</span>

        <Input
          type="select"
          className={styles.SortOptions__select}
          onChange={(e) =>
            setValue('sortBy', e.target.value, { shouldDirty: true })
          }
          value={sortByValue}
          aria-label="sortByValue-select"
        >
          {sortByOptions.map((v) => (
            <option value={v} key={v}>
              {v}
            </option>
          ))}
        </Input>
      </div>
    </div>
  );
};

export default SortOptions;
