import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import { ReactComponent as WhiteCrossIcon } from '../../assets/images/icons/WhiteCross.svg';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import AccesibilityElement from '../AccesibilityElement/AccesibilityElement';
import styles from './PasswordInfo.module.css';

const PasswordInfo = () => {
  const { t } = useTranslate();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleToolTipToogle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        autohide={false}
        target="passwordInformation"
        toggle={handleToolTipToogle}
        trigger="click"
        innerClassName={styles.PasswordInfo__tooltip}
      >
        <div className="d-flex m-2" data-testid="passwordInfo-tooltip">
          <div className="mt-1">
            {t(
              'Passwords must be at least 8 characters and contain one lower case letter (a-z), one upper case letter (A-Z), one number (0-9) and one special character.'
            )}
            {'(!"#$%&\'()*+,-./:;<=>?@[]^_{|}~ and space)'}.
          </div>
          <AccesibilityElement
            className={styles.PasswordInfo__closeToolTipIcon}
            onClick={handleToolTipToogle}
            tabIndex={0}
            ariaLabel="Close tooltip"
            tagName="div"
          >
            <WhiteCrossIcon />
          </AccesibilityElement>
        </div>
      </Tooltip>
      <AccesibilityElement
        tagName="div"
        ariaLabel="Open password info"
        className={styles.PasswordInfo__infoButton}
        id="passwordInformation"
        onClick={handleToolTipToogle}
      >
        i
      </AccesibilityElement>
    </>
  );
};

export default PasswordInfo;
