import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import styles from './Picker.module.css';

const Picker = ({
  picker,
  content,
  onToggle,
  className,
  shown,
  onClose,
  pickerShownClassName,
}) => {
  const boxContainerRef = useRef();

  const [usedShown, setUsedShown] = useState(
    shown !== undefined ? shown : false
  );

  const toggle = useCallback(() => {
    if (onToggle) {
      onToggle(!usedShown);
      if (onClose) {
        onClose(!usedShown);
      }
    }

    setUsedShown(!usedShown);
  }, [onToggle, usedShown, onClose]);

  useEffect(() => {
    if (shown !== undefined) {
      setUsedShown(shown);
    }
  }, [shown]);

  const Content = content(() => toggle(false));

  return (
    <div ref={boxContainerRef} data-testid="wrapperDiv">
      <Popover
        parentElement={boxContainerRef.current}
        containerStyle={{ zIndex: 1050 }}
        isOpen={usedShown}
        reposition={false}
        positions={['bottom']}
        onClickOutside={() => toggle(false)}
        content={
          <div
            className={styles.Picker__dropdownMenu}
            data-testid="pickerContent"
          >
            {Content}
          </div>
        }
      >
        <div
          role="button"
          aria-label="toggle picker dropdown"
          tabIndex={0}
          style={{ cursor: 'pointer' }}
          className={`d-flex w-100 ${className} ${
            usedShown ? pickerShownClassName : ''
          } `}
          onClick={toggle}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              toggle();
            }
          }}
        >
          {picker}
        </div>
      </Popover>
    </div>
  );
};

Picker.propTypes = {
  picker: PropTypes.node,
  content: PropTypes.func,
  onToggle: PropTypes.func,
  className: PropTypes.string,
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  pickerShownClassName: PropTypes.string,
};

export default Picker;
