const beginCheckout = (bookings, hotel) => {
  if (!window.dataLayer) return;

  const data = {
    event: 'beginCheckout',
    currencyCode: hotel.currency,
    products: bookings.map(
      ({
        startDate,
        endDate,
        roomRate: { name: rateName, pricesPerNight },
        roomType: { title },
      }) => ({
        brand: 'Belmond',
        category: rateName,
        checkinDate: startDate,
        checkoutDate: endDate,
        id: title,
        name: hotel.shortName,
        productType: 'Hotel',
        price:
          pricesPerNight.reduce((acc, rate) => acc + rate.price + rate.tax, 0) /
          pricesPerNight.length, // average price per night
        quantity: pricesPerNight.length,
        country: hotel.address.countryName,
        city: hotel.address.city,
      })
    ),
  };

  window.dataLayer.push(data);
};

export default beginCheckout;
