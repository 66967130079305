import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Col, Row } from 'reactstrap';
import { ReactComponent as AddCrossIcon } from '../../assets/images/icons/AddCross.svg';
import { ReactComponent as CancelCrossIcon } from '../../assets/images/icons/CancelCross.svg';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import InputComponent from '../InputComponent/InputComponent';
import styles from './SecondaryGuestsDropdown.module.css';

const SecondaryGuestsDropdown = ({ guestFormKey, numAdults, disabled }) => {
  const { t } = useTranslate();
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `bookingsGuestValues[${guestFormKey}].secondaryGuests`,
  });

  const handleAddGuestBtnClick = () => append({ firstName: '', lastName: '' });
  const handleRemoveGuestBtnClick = (i) => remove(i);

  useEffect(() => {
    append({});
    remove(fields.length);
    // TODO: useFieldArray is not getting intialized with the default values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.SecondaryGuestsDropdown___container}>
      <fieldset>
        <legend style={{ display: 'none' }}>{t('Add Guest')}</legend>
        <Row>
          <div>
            <div>
              {fields.map((item, index) => {
                return (
                  <Row
                    key={item.id}
                    data-testid={`guest-${index + 1}`}
                    className={styles.SecondaryGuestsDropdown__guestsContainer}
                  >
                    <Col md="6">
                      <InputComponent
                        name={`bookingsGuestValues[${guestFormKey}].secondaryGuests.${index}.firstName`}
                        control={control}
                        errors={errors}
                        placeholder=" "
                        label={t('First Name')}
                        autoComplete="given-name"
                        disabled={disabled}
                      />
                    </Col>
                    <Col md="4">
                      <InputComponent
                        name={`bookingsGuestValues[${guestFormKey}].secondaryGuests.${index}.lastName`}
                        control={control}
                        errors={errors}
                        placeholder=" "
                        label={t('Last Name')}
                        autoComplete="family-name"
                        disabled={disabled}
                      />
                    </Col>
                    <Col md="2">
                      <Button
                        className="textButton d-flex text-nowrap align-items-center"
                        onClick={() => handleRemoveGuestBtnClick(index)}
                      >
                        {t('Remove Guest')}
                        <CancelCrossIcon className="ml-1" />
                      </Button>
                    </Col>
                  </Row>
                );
              })}
            </div>
            {fields.length < numAdults - 1 && (
              <div className={styles.SecondaryGuestsDropdown__addContainer}>
                <Button
                  className="textButton d-flex align-items-center"
                  aria-label="Add Guest Details"
                  onClick={handleAddGuestBtnClick}
                >
                  <AddCrossIcon style={{ marginRight: 10 }} />
                  {t('Add Guest Info')}
                </Button>
              </div>
            )}
          </div>
        </Row>
      </fieldset>
    </div>
  );
};
SecondaryGuestsDropdown.propTypes = {
  numAdults: PropTypes.number,
  disabled: PropTypes.bool,
  guestFormKey: PropTypes.string,
};

export default SecondaryGuestsDropdown;
