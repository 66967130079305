import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  forwardRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FORM_STATUS_UNSUBMITTED } from '../../constants';
import navigateAfterRemovingAllBookings from '../../helpers/navigate-after-removing-all-bookings';
import useAxios from '../../hooks/useAxios/useAxios';
import useLocalisedMoment from '../../hooks/useLocalisedMoment/useLocalisedMoment';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import {
  bookingStatuses,
  removeBookings,
} from '../../redux/slices/bookingsSlice/bookingsSlice';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

const AvailabilityWarningModal = forwardRef(({ formStatus }, ref) => {
  const axios = useAxios();
  const { t } = useTranslate();
  const moment = useLocalisedMoment();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const count = useRef(0);
  const formStatusRef = useRef(formStatus);

  const [open, setOpen] = useState(false);
  const [texts, setTexts] = useState({ title: '', content: '' });
  const [bookingsToRemove, setBookingsToRemove] = useState([]);

  const hotels = useSelector((state) => state.belmond.hotels);
  const bookings = useSelector((state) => state.bookings.list);

  const unSubmittedBookings = React.useMemo(
    () =>
      bookings.filter(
        (booking) => booking.status === bookingStatuses.UNSUBMITTED
      ),
    [bookings]
  );

  useImperativeHandle(ref, () => ({
    checkAvailability,
  }));

  const fetchAvailability = useCallback(async () => {
    try {
      const upcomingBookings = unSubmittedBookings.map((booking) =>
        moment(booking.startDate).isBefore(moment(), 'day') ? false : booking
      );
      if (!upcomingBookings.every((booking) => !booking)) {
        const res = await axios.get('/check-room-availability', {
          params: {
            bookings: upcomingBookings
              .filter((i) => i)
              .map((booking) => ({
                startDate: booking.startDate,
                endDate: booking.endDate,
                numAdults: booking.numAdults,
                productCode: booking.productCode,
                children: booking.children,
                specialCodeValue: booking.specialCodeValue,
                specialCodeType: booking.specialCodeType,
                agentId: booking.agentId,
                roomTypeCode: booking.roomType.code,
                ratePlanCode: booking.roomRate.code,
              })),
          },
        });
        return upcomingBookings.map((booking) =>
          booking ? res.data.shift() : false
        );
      } else {
        return upcomingBookings;
      }
    } catch (e) {
      console.log(e);
    }
  }, [unSubmittedBookings, moment, axios]);

  const showModal = useCallback(
    (availability) => {
      setBookingsToRemove(
        unSubmittedBookings.filter((_, index) => !availability[index])
      );
      if (availability.every((available) => !available)) {
        setTexts({
          title: t('No Availability'),
          content: t(
            "We're sorry, but the the selected rooms are no longer available."
          ),
          confirmButton: t('Check Availability'),
        });
      } else {
        setTexts({
          title: t('Availability Warning'),
          content: `${t(
            'We are sorry but some of the selected rooms are no longer available.'
          )} ${t('They have been removed in the cart.')} ${t(
            'Please check availability again.'
          )}'`,
          confirmButton: t('Understood'),
        });
      }
      setOpen(true);
    },
    [unSubmittedBookings, t]
  );

  const checkAvailability = useCallback(async () => {
    try {
      const availability = await fetchAvailability();
      if (
        availability?.some((available) => !available) &&
        // we need to check if the form status is still unsubmitted using reference variable because the form status might have changed by the time the fetchAvailability function is done
        formStatusRef.current === FORM_STATUS_UNSUBMITTED
      ) {
        showModal(availability);
      }
    } catch (e) {
      console.log(e);
    }
  }, [fetchAvailability, showModal]);

  const handleCloseModal = useCallback(() => {
    setOpen(false);
    const removedAllBookings =
      bookingsToRemove.length === unSubmittedBookings.length;
    dispatch(removeBookings(bookingsToRemove.map((booking) => booking.id)));
    if (removedAllBookings) {
      navigateAfterRemovingAllBookings(navigate, unSubmittedBookings);
    }
  }, [unSubmittedBookings, navigate, dispatch, bookingsToRemove]);

  useEffect(() => {
    const intervalId = setInterval(() => count.current++, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (formStatus !== FORM_STATUS_UNSUBMITTED || !unSubmittedBookings.length) {
      return;
    }

    checkAvailability();
    const intervalId = setInterval(() => {
      if (document.hidden === true || document.visibilityState === 'hidden') {
        return;
      }
      if (count.current >= 30) {
        count.current = 0;
        checkAvailability();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [checkAvailability, formStatus, unSubmittedBookings]);

  useEffect(() => {
    if (open && formStatus !== FORM_STATUS_UNSUBMITTED) {
      setOpen(false);
    }
  }, [formStatus, open]);

  useEffect(() => {
    formStatusRef.current = formStatus;
  }, [formStatus]);

  return (
    <ConfirmationModal
      open={open}
      onCancel={handleCloseModal}
      title={texts.title}
      content={
        <p>
          {texts.content}
          <br />
          <br />
          {bookingsToRemove.length > 0 && (
            <strong>{t('Removed Bookings')}:</strong>
          )}
          {bookingsToRemove.map((booking) => (
            <span key={booking.id}>
              <br />
              {hotels[booking.productCode].shortName} - {booking.roomType.title}{' '}
              - {moment(booking.startDate).format('ddd, MMM D, YYYY')} -{' '}
              {moment(booking.endDate).format('ddd, MMM D, YYYY')}
            </span>
          ))}
        </p>
      }
      confirmButton={texts.confirmButton}
      onConfirm={handleCloseModal}
    />
  );
});

AvailabilityWarningModal.propTypes = {
  formStatus: PropTypes.number,
};

AvailabilityWarningModal.defaultProps = {
  formStatus: FORM_STATUS_UNSUBMITTED,
};

export default AvailabilityWarningModal;
