import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ReactComponent as InfoIcon } from '../../../../assets/images/icons/Info.svg';
import { ReactComponent as GreenCheckIcon } from '../../../../assets/images/icons/greenCheck.svg';
import { CHINA_VERSION } from '../../../../globals';
import formatRoomName from '../../../../helpers/formatRoomName/formatRoomName';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import { bookingStatuses } from '../../../../redux/slices/bookingsSlice/bookingsSlice';
import styles from './BookingConfirmationMessage.module.css';

const { CONFIRMED, ORDERED, CANCELLED } = bookingStatuses;

const BookingConfirmationMessage = ({
  bookings,
  currentBookingId,
  addonsChangesConfirmed,
  pendingPaymentEmail,
  loading,
  lastCancelledBooking,
}) => {
  const { t } = useTranslate();
  const [texts, setTexts] = useState({ title: '', description: '' });
  const [bookingReferences, setBookingReferences] = useState([]);
  const booking = bookings.find((b) => b.id === currentBookingId);

  useEffect(() => {
    if (!loading) {
      const someBookingConfirmed = bookings.some(
        (booking) => booking.status === CONFIRMED
      );
      const someBookingOrdered = bookings.some(
        (booking) => booking.status === ORDERED
      );

      setBookingReferences(
        bookings.map((booking) => ({
          id: booking.id,
          status: booking.status,
          roomType: booking.roomType.title,
          categoryName: booking.roomType.category.name,
          productCode: booking.productCode,
          promoCode: booking.specialCodeValue,
        }))
      );

      if (addonsChangesConfirmed) {
        setTexts({
          title: t('Your Enhancements Are Now Confirmed'),
          description: <p>{t('Please check your email for confirmation.')}</p>,
        });
      } else if (!someBookingConfirmed && !someBookingOrdered) {
        setTexts({
          title: t(`Your Reservation is Cancelled`),
          description: t(
            'This reservation is cancelled and the room is no longer booked.'
          ),
        });
      } else if (lastCancelledBooking) {
        setTexts({
          title: `${t(
            'Reservation For Your'
          )} ${lastCancelledBooking.roomType.title.toUpperCase()} ${t(
            'is Cancelled'
          )}`,
          description: t(
            'This reservation is cancelled and the room is no longer booked.'
          ),
        });
      } else if (someBookingOrdered) {
        setTexts({
          title: `${bookings.length > 1 ? t('Bookings') : t('Booking')} ${t(
            'Ordered'
          )}!`,
          description:
            pendingPaymentEmail && CHINA_VERSION ? (
              <p>
                {t(
                  'To confirm the booking, follow the instructions of the email that has been sent to your inbox on'
                )}{' '}
                <span className="OneLinkNoTx">{booking.guest.email}</span>{' '}
              </p>
            ) : (
              t(
                'Please, in order to confirm your booking, provide the credit card as guarantee method.'
              )
            ),
        });
      } else {
        setTexts({
          title: `${bookings.length > 1 ? t('Bookings') : t('Booking')} ${t(
            'Confirmed'
          )}!`,
          description: (
            <p>
              {t('Check')}{' '}
              <span className="OneLinkNoTx">{booking.guest.email}</span>{' '}
              {t('to view your confirmation email')}
            </p>
          ),
        });
      }
    }
  }, [
    loading,
    booking,
    bookings,
    addonsChangesConfirmed,
    pendingPaymentEmail,
    lastCancelledBooking,
    t,
  ]);

  return (
    <div
      className={`${styles.BookingConfirmationMessage__rowContainer} ${
        !loading && styles.BookingConfirmationMessage__borderColor
      } d-print-none`}
    >
      {!loading ? (
        <div>
          <div className="d-flex align-items-center">
            <GreenCheckIcon
              className="flex-shrink-0"
              style={{ marginRight: '10', minWidth: 20 }}
            />
            <div
              className={styles.BookingConfirmationMessage__message}
              aria-live="polite"
            >
              <div className="text-uppercase"> {texts.title}</div>
              <div className={styles.BookingConfirmationMessage__messageEmail}>
                {texts.description}
              </div>
            </div>
          </div>
          <hr className="bbe-hr my-4" />
          <div
            className="d-flex align-items-center"
            style={{ fontWeight: 600 }}
          >
            <InfoIcon className="flex-shrink-0" style={{ marginRight: 10 }} />
            <div className="d-flex flex-column">
              {bookingReferences.map((booking) => (
                <div key={booking.id}>
                  <span style={{ marginRight: 6, textTransform: 'uppercase' }}>
                    {t('Booking Reference')}
                  </span>
                  <span className="OneLinkNoTx">
                    #{booking.id}-{booking.productCode} (
                    {formatRoomName(
                      booking.categoryName,
                      booking.roomType,
                      booking.promoCode
                    )}
                    ){' '}
                    {booking.status === CANCELLED ? (
                      <span>
                        <span
                          className={
                            styles.BookingConfirmationMessage__cancelledMessage
                          }
                        >
                          {t('Cancelled')}
                        </span>{' '}
                      </span>
                    ) : (
                      ``
                    )}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Skeleton height={50} containerTestId="skeleton" />
      )}
    </div>
  );
};

BookingConfirmationMessage.propTypes = {
  loading: PropTypes.bool,
  bookings: PropTypes.arrayOf(PropTypes.object),
  currentBookingId: PropTypes.string,
  addonsChangesConfirmed: PropTypes.bool,
  pendingPaymentEmail: PropTypes.bool,
  lastCancelledBooking: PropTypes.object,
};

export default BookingConfirmationMessage;
