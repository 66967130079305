import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as ModalCrossIcon } from '../../assets/images/icons/ModalCross.svg';

function ModalCloseButton({ className, onClose }) {
  return (
    <button
      aria-label="close modal"
      type="button"
      onClick={onClose}
      className={className}
    >
      <ModalCrossIcon />
    </button>
  );
}
ModalCloseButton.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default ModalCloseButton;
