import React from 'react';
import useLocalisedMoment from '../../../../hooks/useLocalisedMoment/useLocalisedMoment';
import { useScreenDetector } from '../../../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';

const Copyright = () => {
  const { t } = useTranslate();
  const moment = useLocalisedMoment();

  // get localised year in moment
  const currentYearMoment = moment().format('YYYY');

  const { isDesktop } = useScreenDetector();
  return (
    <div className={isDesktop ? 'w-50 pb-4' : ''}>
      <p style={{ fontSize: '10px' }}>
        <b style={{ fontSize: '10px' }}>
          {t('Copyright Belmond Management Limited')} {currentYearMoment}.
        </b>{' '}
        {t(
          'All copyright and other intellectual property rights in all logos,designs, text, images and other materials on this website are owned by Belmond Management Limited or appear with permission of the relevant owner.'
        )}{' '}
        {t("'Belmond' is a registered trade mark.")} {t('All rights reserved.')}
      </p>
    </div>
  );
};

export default Copyright;
