import { paymentTypeMap } from '../../constants';
import titleCase from '../../helpers/title-case';

const addPaymentInfo = (paymentType = '') => {
  if (!window.dataLayer) return;

  const paymentTypeValue =
    paymentTypeMap[paymentType] || titleCase(paymentType);

  const data = {
    event: 'addPaymentInfo',
    paymentType: paymentTypeValue,
  };

  window.dataLayer.push(data);
};

export default addPaymentInfo;
