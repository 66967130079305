import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Input, Modal, ModalBody } from 'reactstrap';
import ModalCloseButton from '../../../../components/ModalCloseButton/ModalCloseButton';
import useLocalisedMoment from '../../../../hooks/useLocalisedMoment/useLocalisedMoment';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import AddToCalendarButtons from '../AddToCalendarButtons/AddToCalendarButtons';
import styles from './AddToCalendarModal.module.css';

const AddToCalendarModal = ({ open, bookings, onClose }) => {
  const { t } = useTranslate();
  const moment = useLocalisedMoment();
  const [selectedBooking, setSelectedBooking] = useState(bookings[0]);
  return (
    <Modal
      isOpen={open}
      toggle={onClose}
      contentClassName={styles.AddToCalendarModal__modalWrapper}
      centered
      external={
        <ModalCloseButton
          className={styles.AddToCalendarModal__closeButton}
          onClose={onClose}
        />
      }
    >
      <ModalBody className={styles.AddToCalendarModal__modalBody}>
        <div>
          <h2 className="text-center">
            {t('Add Travel Dates To Your Calendar')}
          </h2>
          {bookings.length > 1 && (
            <div className="pb-2">
              <hr />
              <div className="mb-4">
                {t('Please select the booking you want to add to calendar.')}
              </div>
              {bookings.map((booking, i) => {
                return (
                  <div
                    key={booking.id}
                    className="d-flex align-items-center mb-4"
                  >
                    <Input
                      type="radio"
                      defaultChecked={i === 0}
                      id={booking.id}
                      name="selectedBookingIndex"
                      value={i}
                      aria-label={`select booking for ${booking.roomType.title}`}
                      onChange={() => setSelectedBooking(booking)}
                      className={styles.AddToCalendarModal__inputCheckbox}
                    />
                    <label htmlFor={booking.id}>
                      <span style={{ fontWeight: 600 }}>
                        {booking.roomType.title}
                      </span>{' '}
                      /{' '}
                      <span style={{ whiteSpace: 'nowrap' }}>
                        {moment(booking.startDate).format('ddd, MMM D, YYYY')}
                      </span>{' '}
                      /{' '}
                      <span style={{ whiteSpace: 'nowrap' }}>
                        #{booking.id}-{booking.productCode}
                      </span>
                    </label>
                  </div>
                );
              })}
              <hr />
            </div>
          )}
          <AddToCalendarButtons
            bookingId={selectedBooking.id}
            productCode={selectedBooking.productCode}
            startDate={selectedBooking.startDate}
            checkInTime={selectedBooking.checkInTime}
            endDate={selectedBooking.endDate}
            roomTypeName={selectedBooking.roomType.title}
            place={selectedBooking.guest.address.countryCode}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

AddToCalendarModal.propTypes = {
  open: PropTypes.bool,
  bookings: PropTypes.array,
  onClose: PropTypes.func,
};

export default AddToCalendarModal;
