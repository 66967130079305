import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import AddressForm from '../AddressForm/AddressForm';
import GuestDetailsForm from '../GuestDetailsForm/GuestDetailsForm';
import SecondaryGuestsDropdown from '../SecondaryGuestsDropdown/SecondaryGuestsDropdown';
import styles from './SingleGuestForm.module.css';

const SingleGuestForm = ({ guestFormKey, numAdults, disabled }) => {
  const { t } = useTranslate();
  const { control } = useFormContext();

  return (
    <div
      className={styles.SingleGuestForm__sectionContainer}
      data-testid="SingleGuestFormDiv"
    >
      <div className={styles.SingleGuestForm__sectionHeading}>
        <h2>{t('Guest Information')}</h2>
      </div>
      <GuestDetailsForm disabled={disabled} guestFormKey={guestFormKey} />
      <div className={styles.SingleGuestForm__sectionHeading}>
        <h2>{t('Address')}</h2>
      </div>
      <AddressForm disabled={disabled} guestFormKey={guestFormKey} />
      {numAdults > 1 && (
        <>
          <div className={styles.SingleGuestForm__sectionHeading}>
            <h2>{t('Add Guest')}</h2>
          </div>
          <SecondaryGuestsDropdown
            disabled={disabled}
            numAdults={numAdults}
            guestFormKey={guestFormKey}
          />
        </>
      )}
      <Row>
        <div className={styles.SingleGuestForm__sectionHeading}>
          <h2>{t('Additional Details and Preferences')}</h2>
        </div>
        <Col>
          <Label className={styles.SingleGuestForm__label}>
            {t('Please note any requests or requirements for your stay')}
          </Label>
          <FormGroup className={styles.SingleGuestForm__formGroup}>
            <Controller
              name={`bookingsGuestValues[${guestFormKey}].notes`}
              control={control}
              render={({ field }) => (
                <textarea
                  type="text"
                  maxLength="200"
                  rows={6}
                  style={{ width: '100%' }}
                  className={styles.SingleGuestForm__input}
                  {...field}
                  placeholder=" "
                  aria-label={`bookingsGuestValues[${guestFormKey}].notes`}
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};
SingleGuestForm.propTypes = {
  numAdults: PropTypes.number,
  disabled: PropTypes.bool,
  guestFormKey: PropTypes.string,
};
export default SingleGuestForm;
