import axios from 'axios';
import { AUTH_DOMAIN, REACT_APP_BBE_CONFIG } from '../../../globals';

const LOGOUT_URL = `https://${AUTH_DOMAIN}/identity/v1/logout`;
const REVOKE_TOKEN_URL = `https://${AUTH_DOMAIN}/oauth/revoke`;

const logoutWithoutRedirect = async (refreshToken) => {
  try {
    if (refreshToken) {
      await axios.post(REVOKE_TOKEN_URL, {
        client_id: REACT_APP_BBE_CONFIG.reach5,
        token: refreshToken,
        token_type_hint: 'refresh_token',
      });
    }

    await fetch(LOGOUT_URL, {
      method: 'GET',
      credentials: 'include',
      referrerPolicy: 'no-referrer',
    });
    // this is a silent logout, so we don't need to do anything if it fails
  } catch (e) {}
};
export default logoutWithoutRedirect;
