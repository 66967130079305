const checkDateInAllowedRange = (
  currentDate,
  daysAvailability,
  minStayValue
) => {
  if (minStayValue <= 1) return true;
  const currentDateIndex = daysAvailability.findIndex(
    (day) => day.date === currentDate
  );

  const { isCheckIn } = daysAvailability[currentDateIndex] || {};
  if (!isCheckIn) return false;

  if (currentDateIndex === -1) return false;

  const requiredAvailableDays = minStayValue - 1; // Convert nights to day range

  // Check forward
  let forwardConsecutiveDays = 0;
  for (let i = currentDateIndex + 1; i < daysAvailability.length; i++) {
    const nextDay = daysAvailability[i];
    const {
      isCheckIn: isNextDayCheckIn,
      metadata: { NoArrive },
    } = nextDay;

    // no arrive means that we can still proceed
    if (isNextDayCheckIn || NoArrive) {
      forwardConsecutiveDays++;
      if (forwardConsecutiveDays >= requiredAvailableDays) return true;
    } else {
      break;
    }
  }

  // Check backward
  let backwardConsecutiveDays = 0;
  for (let i = currentDateIndex - 1; i >= 0; i--) {
    const previousDay = daysAvailability[i];
    const {
      isCheckIn: isPreviousDayCheckIn,
      metadata: { NoArrive },
    } = previousDay;

    if (isPreviousDayCheckIn || NoArrive) {
      backwardConsecutiveDays++;
    } else {
      break;
    }
  }

  // check if the combination of forward and backward days meets the requirement
  return (
    forwardConsecutiveDays + backwardConsecutiveDays >= requiredAvailableDays
  );
};

export default checkDateInAllowedRange;
