import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import client from '../../helpers/authClient';
import useMessage from '../../hooks/useMessage/useMessage';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import InputComponent from '../InputComponent/InputComponent';
import LoadingButton from '../LoadingButton/LoadingButton';
import PasswordInfo from '../PasswordInfo/PasswordInfo';
import styles from './PasswordReset.module.css';

const PasswordReset = ({ onLoginRedirect }) => {
  const { t } = useTranslate();
  const [searchParams] = useSearchParams();
  const verificationCode = searchParams.get('verification_code');
  const email = searchParams.get('email') || '';

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
    defaultValues: { email },
  });

  const [Message, showMessage, closeMessage] = useMessage();

  const [isLoading, setIsLoading] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const errorMessage = (msg) => {
    if (msg.errorMessageKey === 'error.password.policy') {
      return (
        <div>
          <div>{msg.errorUserMsg}:</div>
          {msg.errorDetails.map((e) => (
            <div className="mb-2" key={e.message}>
              {e.message}
            </div>
          ))}
        </div>
      );
    }
    return msg.errorUserMsg;
  };

  const handleFormSubmit = async ({ newPassword }) => {
    setIsLoading(true);
    closeMessage();
    try {
      await client.updatePassword({
        email,
        verificationCode,
        password: newPassword,
      });
      setPasswordUpdated(true);
    } catch (e) {
      showMessage(
        errorMessage(e) || 'Something went wrong, please try again later'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.PasswordReset__container}>
      <div className={styles.PasswordReset__header}>{t('Reset Password')}</div>
      <hr className={styles.PasswordReset__line} />
      {passwordUpdated ? (
        <div className="my-2 d-flex flex-column align-items-center">
          <div className="text-center">
            {t('Your password have been successfully updated!')}
          </div>
          <Button
            onClick={onLoginRedirect}
            className="button my-4"
            aria-label="Go to Log In"
          >
            {t('Login')}
          </Button>
        </div>
      ) : (
        <div>
          <div className="mb-4">
            {t('Please enter and confirm your new password.')}
          </div>
          <Form className="w-100 my-3">
            <label className={styles.PasswordReset__label}>{t('EMAIL')}</label>

            <InputComponent
              name="email"
              control={control}
              errors={errors}
              inputClassName={styles.PasswordReset__input}
              autoComplete="email"
              placeholder="Email"
              disabled
            />
            <label className={styles.PasswordReset__label}>
              {' '}
              <div className="d-flex align-items-center">
                {t('New Password')}
                <PasswordInfo />
              </div>
            </label>

            <InputComponent
              name="newPassword"
              control={control}
              type="password"
              rules={{ required: 'Password is required' }}
              errors={errors}
              autoComplete="new-password"
              inputClassName={styles.PasswordReset__input}
            />
            <label className={styles.PasswordReset__label}>
              {t('Confirm New Password')}
            </label>

            <InputComponent
              name="repeatNewPassword"
              control={control}
              type="password"
              rules={{
                validate: (value) =>
                  value === getValues('newPassword') ||
                  t('The passwords do not match'),
              }}
              errors={errors}
              autoComplete="new-password"
              inputClassName={styles.PasswordReset__input}
            />
            <div className="d-flex justify-content-center">
              <LoadingButton
                onClick={handleSubmit(handleFormSubmit)}
                className="button my-5"
                type="button"
                autoComplete="new-password"
                ariaLabel="Reset Password"
                loading={isLoading}
              >
                {t('Reset Password')}
              </LoadingButton>
            </div>
            <Message />
          </Form>
        </div>
      )}
    </div>
  );
};
PasswordReset.propTypes = {
  onLoginRedirect: PropTypes.func,
};

export default PasswordReset;
