const titleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.replace(word[0], word[0]?.toUpperCase());
    })
    .join(' ');
};

export default titleCase;
