const devLoginCheck = () => {
  const currentTimeStamp = JSON.parse(
    localStorage.getItem('validUser')
  )?.timestamp;

  // checking if user has expired
  if (currentTimeStamp && Date.now() - currentTimeStamp >= 604800000) {
    localStorage.removeItem('validUser');
    window.location.reload();
  }
};

export default devLoginCheck;
