import { paymentTypeMap } from '../../constants';
import titleCase from '../../helpers/title-case';

const purchase = ({ bookings, paymentBrand = '', orderReference, hotel }) => {
  if (!window.dataLayer) return;

  const totalTax = bookings.reduce(
    (acc, booking) =>
      acc +
      booking.roomRate.pricesPerNight.reduce((acc, { tax }) => acc + tax, 0),
    0
  );

  const totalExcludngTax = bookings.reduce(
    (acc, booking) =>
      acc +
      booking.roomRate.pricesPerNight.reduce(
        (acc, { price }) => acc + price,
        0
      ),
    0
  );

  const totalIncludingTax = totalExcludngTax + totalTax;

  const checkoutPaymentType =
    paymentTypeMap[paymentBrand] || titleCase(paymentBrand);

  const data = {
    event: 'purchase',
    currencyCode: hotel.currency,
    checkoutPaymentType,
    numGuests: bookings.reduce(
      (acc, booking) => acc + booking.numAdults + booking.children.length,
      0
    ),
    numRooms: bookings.length,
    transactionCoupon:
      bookings.find(({ specialCodeValue }) => !!specialCodeValue)
        ?.specialCodeValue || '',
    transactionId: orderReference,
    transactionTax: totalTax,
    transactionTotal: totalExcludngTax,
    transactionTotalIncludingTaxes: totalIncludingTax,
    products: bookings.map((booking) => ({
      brand: 'Belmond',
      category: booking.roomRate.name,
      checkinDate: booking.startDate,
      checkoutDate: booking.endDate,
      city: hotel.address.city,
      country: hotel.address.countryName,
      id: booking.roomType.title,
      name: hotel.shortName,
      price:
        booking.roomRate.pricesPerNight.reduce(
          (acc, rate) => acc + rate.price + rate.tax,
          0
        ) / booking.roomRate.pricesPerNight.length, // average price per night
      productType: 'Hotel',
      quantity: booking.roomRate.pricesPerNight.length,
    })),
  };
  window.dataLayer.push(data);
};

export default purchase;
