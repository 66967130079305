const allowedDatadogErrors = [
  {
    // GSI FedCM errors
    condition: (error) =>
      error?.message?.includes('[GSI_LOGGER]: FedCM') &&
      error?.message?.includes('NetworkError: Error retrieving a token'),
    reason: 'GSI FedCM token retrieval error',
  },
];

export default allowedDatadogErrors;
