import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import styles from './SignUpFormConsents.module.css';

const SignUpFormConsents = ({ formControl }) => {
  const { t } = useTranslate();
  return (
    <div>
      <div className={styles.SignUpFormConsents__infoText}>
        {t('We collect and process your Personal data when you sign-up.')}{' '}
        {t(
          'To see how Belmond stores and processes your personal data, please see our Privacy Policy.'
        )}
      </div>
      <div className="d-flex align-items-start mb-4">
        <Controller
          name="marketingConsent"
          control={formControl}
          render={({ field }) => (
            <input
              type="checkbox"
              className="mt-1"
              id="marketingConsent"
              {...field}
            />
          )}
        />
        <label
          htmlFor="marketingConsent"
          className={styles.SignUpFormConsents__checkboxText}
        >
          {t(
            'I would like to be kept updated on exclusive news, travel inspiration and offers from the legendary world of Belmond, and receive tailored communications based on my interests and circumstances.'
          )}
          {t(
            'To learn more about how we store and process your data please visit our'
          )}{' '}
          <a
            href="https://www.belmond.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            {t('Privacy Policy')}
          </a>
          .
        </label>
      </div>
      <div className="d-flex align-items-start mb-4">
        <Controller
          name="profilingConsent"
          control={formControl}
          render={({ field }) => (
            <input
              type="checkbox"
              className="mt-1"
              id="profilingConsent"
              {...field}
            />
          )}
        />
        <label
          htmlFor="profilingConsent"
          className={styles.SignUpFormConsents__checkboxText}
        >
          {t(
            'I agree to allow Belmond to offer me a personalised and tailored experience by analysing my interests, preferences and interactions with Belmond and with other LVMH Maisons.'
          )}
          {t('For more information please see')}{' '}
          <a
            href="https://www.belmond.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            {t("Belmond's Privacy Policy")}
          </a>
          .
        </label>
      </div>
    </div>
  );
};
SignUpFormConsents.propTypes = {
  formControl: PropTypes.object,
};
export default SignUpFormConsents;
