import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';
import useDetermineMultipleHotels from '../../hooks/useDetermineMultipleHotels/useDetermineMultipleHotels';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import BookingsConfirmationActionButtons from '../../views/BookingsConfirmation/components/BookingsConfirmationActionButtons/BookingsConfirmationActionButtons';
import MobileBar from '../MobileBar/MobileBar';

const BookingsConfirmationMobileBar = forwardRef(
  (
    {
      bookings,
      bookingsConfirmationViewRef,
      cancelBookingsError,
      cancelBookingsLoading,
      onEditBooking,
      onCancelBooking,
      actionableBookings,
    },
    ref
  ) => {
    const { t } = useTranslate();
    const mobileBarRef = useRef();
    const multipleHotels = useDetermineMultipleHotels(bookings);

    useImperativeHandle(ref, () => ({
      closeModal: () => mobileBarRef.current.closeModal(),
    }));

    const hotel = useSelector((state) =>
      !multipleHotels ? state.belmond.hotels[bookings[0].productCode] : null
    );

    return (
      <MobileBar
        ref={mobileBarRef}
        barLeftContent={multipleHotels ? 'BELMOND' : hotel.shortName}
        barRightContent={actionableBookings.length ? t('Edit Booking') : ' '}
      >
        <BookingsConfirmationActionButtons
          bookings={bookings}
          bookingsConfirmationViewRef={bookingsConfirmationViewRef}
          mobileView
          cancelBookingsError={cancelBookingsError}
          cancelBookingsLoading={cancelBookingsLoading}
          onClose={() => mobileBarRef.current.closeModal()}
          onEditBooking={onEditBooking}
          onCancelBooking={onCancelBooking}
          actionableBookings={actionableBookings}
        />
      </MobileBar>
    );
  }
);

BookingsConfirmationMobileBar.propTypes = {
  bookings: PropTypes.array,
  bookingsConfirmationViewRef: PropTypes.object,
  cancelBookingsLoading: PropTypes.bool,
  cancelBookingsError: PropTypes.string,
  onEditBooking: PropTypes.func,
  onCancelBooking: PropTypes.func,
  actionableBookings: PropTypes.array,
};

export default BookingsConfirmationMobileBar;
