import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import CounterInput from '../../../../components/CounterInput/CounterInput';
import SelectComponent from '../../../../components/SelectComponent/SelectComponent';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';

const GuestsCount = ({ guestsPerRoomKey, maxAdults, maxChildren }) => {
  const { t } = useTranslate();
  const {
    setValue,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const numChildren = watch(`guestsPerRoom[${guestsPerRoomKey}].numChildren`);

  return (
    <div
      style={{ background: '#faf9f7', cursor: 'default' }}
      className="w-100 d-flex flex-column align-items-start justify-content-center p-3"
    >
      <CounterInput
        watch={watch}
        defaultValue={2}
        name={`guestsPerRoom[${guestsPerRoomKey}].numAdults`}
        setValue={setValue}
        control={control}
        rules={{ required: 'Number of adults must be at least 1' }}
        errors={errors}
        label={t('Adults')}
        max={maxAdults}
      />

      <CounterInput
        watch={watch}
        name={`guestsPerRoom[${guestsPerRoomKey}].numChildren`}
        defaultValue={0}
        setValue={setValue}
        control={control}
        errors={errors}
        label={t('Children')}
        max={maxChildren}
      />

      {Array.from(
        {
          length: numChildren || 0,
        },
        (_, childIndex) => (
          <SelectComponent
            inputClassName="bg-transparent"
            key={`room-${childIndex}-child-age-${childIndex}`}
            control={control}
            //TODO HOW TO HANDLE THIS TRANSLATION
            label={`${t('Child')} ${childIndex + 1} ${t('Age')}`}
            name={`guestsPerRoom[${guestsPerRoomKey}].children[${childIndex}]`}
            setValue={setValue}
            options={Array.from({ length: 18 }, (_, i) => ({
              option: i,
              code: i,
            }))}
            watch={watch}
            errors={errors}
          />
        )
      )}
    </div>
  );
};

GuestsCount.propTypes = {
  guestsPerRoomKey: PropTypes.string,
  maxAdults: PropTypes.number,
  maxChildren: PropTypes.number,
};

export default GuestsCount;
