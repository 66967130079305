import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import { ReactComponent as ArrowRightIcon } from '../../assets/images/icons/ArrowRight.svg';
import { CURRENCY_SYMBOLS } from '../../constants';
import CurrencyPickerContent from '../CurrencyPickerContent/CurrencyPickerContent';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';
import styles from './Currency.module.css';

const CurrencyMobile = ({
  open,
  onClose,
  onCurrencyPickerHeaderClick,
  currencies,
  onSelectCurrency,
  defaultCurrency,
}) => {
  const currentCurrency = useSelector(
    (state) => state.appSettings.currencies.current
  );

  return (
    <>
      <Modal
        isOpen={open}
        contentClassName={styles.Currency__modalWrapper}
        centered
        external={
          <ModalCloseButton
            className={styles.Currency__closeButton}
            onClose={onClose}
          />
        }
      >
        <ModalBody className="">
          <CurrencyPickerContent
            onSelectCurrency={onSelectCurrency}
            defaultCurrency={defaultCurrency}
          />
        </ModalBody>
      </Modal>
      <Button
        className="button transparent d-flex align-items-center justify-content-between w-100"
        onClick={onCurrencyPickerHeaderClick}
        aria-label="Open currency picker"
        data-testid="mainCurrencyMobile"
      >
        <div className="d-flex align-items-center">
          <div style={{ fontWeight: 600, marginRight: 10 }}>
            {CURRENCY_SYMBOLS[currentCurrency]?.symbol}
          </div>
          <span style={{ textTransform: 'capitalize' }}>
            {currencies[currentCurrency]}
          </span>
        </div>
        <ArrowRightIcon />
      </Button>
    </>
  );
};
CurrencyMobile.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCurrencyPickerHeaderClick: PropTypes.func,
  currencies: PropTypes.object,
  onSelectCurrency: PropTypes.func,
  defaultCurrency: PropTypes.string.isRequired,
};

export default CurrencyMobile;
