import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import navigateAfterRemovingAllBookings from '../../helpers/navigate-after-removing-all-bookings';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import {
  bookingStatuses,
  resetBookingsState,
} from '../../redux/slices/bookingsSlice/bookingsSlice';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

const NoAvailabilityModal = ({ open }) => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bookings = useSelector((state) => state.bookings.list);
  const [confirmButtonText, setConfirmButtonText] = useState(
    t('Back to Select a Room')
  );

  const handleConfirm = () => {
    const [{ status, productCode, id, encryptedEmail }] = bookings;
    if (status === bookingStatuses.CONFIRMED) {
      navigate(
        `/confirmation?bookingId=${id}&productCode=${productCode}&email=${encryptedEmail}`,
        { replace: true }
      );
    } else {
      removeBookingsAndNavigate();
    }
  };

  const removeBookingsAndNavigate = () => {
    dispatch(resetBookingsState());
    navigateAfterRemovingAllBookings(navigate, bookings);
  };

  useEffect(() => {
    if (!bookings.length) return;
    const [{ status }] = bookings;
    if (status === bookingStatuses.CONFIRMED) {
      setConfirmButtonText(t('Back to Confirmation'));
    } else {
      setConfirmButtonText(t('Back to Select a Room'));
    }
  }, [bookings, t]);

  return (
    <ConfirmationModal
      open={open}
      onCancel={removeBookingsAndNavigate}
      title={t(
        'Unfortunately, your requested rate is not available during your selected dates.'
      )}
      confirmButton={confirmButtonText}
      onConfirm={handleConfirm}
    />
  );
};

NoAvailabilityModal.propTypes = {
  open: PropTypes.bool,
};

export default NoAvailabilityModal;
