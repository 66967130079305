import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Form, Row } from 'reactstrap';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import InputComponent from '../../components/InputComponent/InputComponent';
import LoadingButton from '../../components/LoadingButton/LoadingButton';
import { CHINA_VERSION } from '../../globals';
import { gtmBookingFindReservation, gtmPageView } from '../../gtm/events';
import useAxios from '../../hooks/useAxios/useAxios';
import useMessage from '../../hooks/useMessage/useMessage';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import styles from './FindReservation.module.css';

const FindReservation = () => {
  const {
    t,
    constants: {
      formRules: { emailRule },
    },
  } = useTranslate();

  const axios = useAxios();

  const [Message, showMessage, closeMessage] = useMessage();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      email: '',
      bookingIdProductCode: '',
    },
    mode: 'onTouched',
  });

  const [loading, setLoading] = useState(false);
  const [formSubmitMessageModal, setFormSubmitMessageModal] = useState(null);

  const handleFormSubmit = useCallback(
    async ({ bookingIdProductCode, email }) => {
      setLoading(true);
      closeMessage();
      gtmBookingFindReservation();

      try {
        let bookingId, productCode;
        const lastDashIndex = bookingIdProductCode.lastIndexOf('-');

        if (lastDashIndex !== -1) {
          bookingId = bookingIdProductCode.slice(0, lastDashIndex);
          productCode = bookingIdProductCode.slice(lastDashIndex + 1);
        } else {
          bookingId = bookingIdProductCode;
          productCode = bookingIdProductCode.match(/\d{4}/)?.[0];
        }

        await axios.post('/request-booking-details-link', {
          bookingId,
          productCode,
          email,
          chinaVersion: CHINA_VERSION,
        });
        setFormSubmitMessageModal(
          `${t(
            'If we have a record of your booking with us, we will send you a recovery email.'
          )} ${t('Please check your inbox.')}`
        );
      } catch (e) {
        if (
          e.response?.status === 401 &&
          e.response?.data?.error === 'NOT_BBE_BOOKING'
        ) {
          setFormSubmitMessageModal(
            `${t('Your booking has been made with a third party.')} ${t(
              'Please, contact the third party to mange or view your booking.'
            )}`
          );
        } else {
          showMessage(
            `${t('We apologize.')} ${t(
              'We cannot locate your reservation.'
            )} ${t('Please check your information and try again.')}`,
            'danger'
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [closeMessage, showMessage, t, axios]
  );

  useEffect(() => {
    document.title = t('Find Your Reservation');
    gtmPageView();
  }, [t]);

  return (
    <>
      <ConfirmationModal
        open={!!formSubmitMessageModal}
        confirmButton={t('Close')}
        onConfirm={() => setFormSubmitMessageModal(null)}
        onCancel={() => setFormSubmitMessageModal(null)}
        content={
          <div style={{ fontSize: '22px' }}>{formSubmitMessageModal}</div>
        }
      />
      <div className="container-xxl px-5 pb-5 d-flex justify-content-center">
        <div className={styles.FindReservation__container}>
          <h2>{t('Find Your Reservation')}</h2>
          <div className="w-100">
            <Form
              onSubmit={handleSubmit(handleFormSubmit)}
              noValidate
              id="find-reservation-form"
            >
              <Row className={styles.FindReservation__form}>
                <Col lg="6">
                  <InputComponent
                    name="bookingIdProductCode"
                    control={control}
                    rules={{
                      required: t(
                        'Booking or confirmation number cannot be blank'
                      ),
                    }}
                    errors={errors}
                    placeholder=" "
                    label={`${t('Confirmation or Booking Number')}*`}
                  />
                </Col>
                <Col lg="6">
                  <InputComponent
                    name="email"
                    control={control}
                    rules={emailRule}
                    errors={errors}
                    passivePlaceholder="alex@example.com"
                    label={`${t('Email')}*`}
                  />
                </Col>
              </Row>
              <div className={styles.FindReservation__containerButton}>
                <LoadingButton
                  loading={loading}
                  ariaLabel="Search for reservation"
                  className="button"
                  type="submit"
                  disabled={!isValid}
                >
                  {t('Find Reservation')}
                </LoadingButton>
              </div>
            </Form>
            <Message />

            <div className={styles.FindReservation__dottedLine}></div>
            <div className={styles.FindReservation__containerFooter}>
              <b>{t("Don't know your confirmation number?")}</b>
              <p>
                {t(
                  'This number was included in an email sent at the time of booking.'
                )}
                {t('Please check your email to recover the number.')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindReservation;
