import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import ModalCloseButton from '../../../../../../components/ModalCloseButton/ModalCloseButton';
import RoomMedia from '../../../../../../components/RoomMedia/RoomMedia';
import formatRoomName from '../../../../../../helpers/formatRoomName/formatRoomName';
import useTranslate from '../../../../../../hooks/useTranslate/useTranslate';
import styles from './RoomTypeModal.module.css';

const RoomTypeModal = ({
  open,
  title,
  categoryName,
  sleeps,
  bedding,
  size,
  images,
  video,
  shortDescription,
  longDescription,
  onRoomMediaModalOpen,
  onClose,
  onClosed,
}) => {
  const { t } = useTranslate();

  const splitDescription = longDescription?.split('<ul>');

  const hasSignatureSuite = splitDescription[1]?.includes(t('Signature Suite'));
  const hasSignatureVilla = splitDescription[1]?.includes(t('Signature Villa'));

  const hasSignatureAmenities = hasSignatureSuite || hasSignatureVilla;
  const signatureTitle = hasSignatureSuite
    ? t('Signature Suite Inclusions')
    : t('Signature Villa Inclusions');

  const amenitiesTitle = hasSignatureAmenities
    ? signatureTitle
    : t('Amenities & Features');

  return (
    <Modal
      isOpen={open}
      toggle={onClose}
      onClosed={onClosed}
      contentClassName={styles.RoomTypeModal__modalWrapper}
      aria-labelledby={`${title}ModalID`}
      external={
        <ModalCloseButton
          onClose={onClose}
          className={styles.RoomTypeModal__closeButton}
        />
      }
      scrollable
      data-testid="roomDetailsModal"
    >
      <ModalBody className={styles.RoomTypeModal__modalBody}>
        <Row className={styles.RoomTypeModal__headingRow}>
          <Col md="6">
            <div className={styles.RoomTypeModal__heading}>
              <h1 className="pb-0">{title}</h1>
              <span style={{ fontSize: 20, marginBottom: 20 }}>
                {formatRoomName(categoryName)}
              </span>

              <div className={styles.Room__roomDetailsModal}>
                <span>
                  {t('Sleeps')} {sleeps}
                </span>
                <span>{bedding}</span>
                <span>
                  {size?.value}
                  {size?.units}
                </span>
              </div>
              <h2>{t('Overview')}</h2>
              <div className={styles.RoomTypeModal__listColumns}>
                <ul className={`${styles.RoomTypeModal__list} diamond-list`}>
                  {parse(
                    splitDescription[1]?.split('</ul>')[0] || longDescription
                  )}
                </ul>
              </div>
            </div>
          </Col>
          <Col md="6" className={styles.RoomTypeModal__column}>
            <RoomMedia
              title={title}
              images={images}
              video={video}
              onToggle={onRoomMediaModalOpen}
            />
          </Col>
        </Row>
        <Row className={styles.RoomTypeModal__detailsRow}>
          <Col md="6">
            <div>
              <div className={styles.RoomTypeModal__features}>
                <h2>{amenitiesTitle}</h2>
                <div className={styles.RoomTypeModal__listColumns}>
                  <ul className={`${styles.RoomTypeModal__list} diamond-list`}>
                    {parse(
                      splitDescription[2]?.split('</ul>')[0] || longDescription
                    )}
                  </ul>
                </div>
              </div>
              {hasSignatureAmenities && (
                <div className={styles.RoomTypeModal__features}>
                  <h2>{t('Amenities & Features')}</h2>
                  <div className={styles.RoomTypeModal__listColumns}>
                    <ul
                      className={`${styles.RoomTypeModal__list} diamond-list`}
                    >
                      {parse(
                        splitDescription[3]?.split('</ul>')[0] ||
                          longDescription
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col md="6">
            {' '}
            <div className={styles.RoomTypeModal__fineText}>
              “{shortDescription?.split('<')[0]?.trim()}”
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

RoomTypeModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  categoryName: PropTypes.string.isRequired,
  sleeps: PropTypes.string,
  bedding: PropTypes.string,
  size: PropTypes.shape({
    value: PropTypes.string,
    units: PropTypes.string,
  }),
  images: PropTypes.arrayOf(PropTypes.string),
  video: PropTypes.string,
  shortDescription: PropTypes.string,
  longDescription: PropTypes.string,
  onRoomMediaModalOpen: PropTypes.func,
  onClose: PropTypes.func,
  onClosed: PropTypes.func,
};

export default RoomTypeModal;
