import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { ReactComponent as CrossIcon } from '../../assets/images/icons/Cross.svg';
import styles from './SlidingMenu.module.css';

const SlidingMenu = ({ isOpen, onClose, children, left, headerContent }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  useEffect(() => {
    const slidingMenu = ref.current;
    const focusElements = slidingMenu.querySelectorAll(
      'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
    );
    const firstEl = focusElements[0];
    const lastEl = focusElements[focusElements.length - 1];

    const handleTrapFocus = (e) => {
      const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        if (document.activeElement === firstEl) {
          lastEl.focus();
          e.preventDefault();
        }
      } else if (document.activeElement === lastEl && isTabPressed) {
        firstEl.focus();
        e.preventDefault();
      }
    };

    if (slidingMenu) {
      slidingMenu.addEventListener('keydown', handleTrapFocus);
    }

    return () => {
      slidingMenu.removeEventListener('keydown', handleTrapFocus);
    };
  }, [children]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        document.getElementById('slidingMenuCloseButton')?.focus();
      }, 100);
    }
  }, [isOpen]);

  return (
    <div
      className={styles.SlidingMenu__wrapper}
      aria-hidden={!isOpen}
      style={{ visibility: isOpen ? 'visible' : 'hidden' }}
      ref={ref}
    >
      <div
        className={`${
          left ? styles.SlidingMenu__main : styles.SlidingMenu__right
        } ${styles[isOpen ? 'active' : '']}`}
        role="menu"
      >
        <div className={headerContent ? '' : 'mt-5'}>
          <div className="d-flex justify-content-center align-items-center">
            {headerContent || null}
            <button
              onClick={onClose}
              aria-label="close menu"
              className={styles.SlidingMenu__closeButton}
              id="slidingMenuCloseButton"
            >
              <CrossIcon data-testid="close modal" />
            </button>
          </div>

          {headerContent ? <hr className="bbe-hr" /> : null}
        </div>
        {children}
      </div>

      <div
        className={styles[isOpen ? 'SlidingMenu__overlay' : '']}
        role="none"
        onClick={onClose}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            onClose();
          }
        }}
        data-testid="overlay"
      ></div>
    </div>
  );
};
SlidingMenu.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  left: PropTypes.bool,
  headerContent: PropTypes.node,
};
export default SlidingMenu;
