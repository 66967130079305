// TODO: consume a caps ENV to be more consistent with the rest of the codebase

let ENV;
switch (process.env.REACT_APP_ENV) {
  case 'production':
    ENV = 'prod';
    break;
  case 'staging':
    ENV = 'stg';
    break;
  case 'development':
    ENV = 'dev';
    break;
  default:
    ENV = 'local';
    break;
}

export const env = ENV;

let baseUrl;

if (env === 'local') {
  const port = process.env.REACT_APP_PORT || 3000;
  baseUrl = `http://localhost:${port}`;
} else if (process.env.REACT_APP_LOCALHOST === '1') {
  baseUrl = 'http://localhost:3001';
} else if (env === 'prod') {
  baseUrl = 'https://reservation.belmond.com';
} else {
  baseUrl = `https://reservation.${env}.belmond.com`;
}

export const BASE_URL = baseUrl;

const serverEnv = env === 'local' ? 'dev' : env;

export const CHINA_VERSION = !!+process.env.REACT_APP_CHINA_VERSION;

const domainSuffix = CHINA_VERSION ? 'vip' : 'com';
export const API_DOMAIN =
  process.env.REACT_APP_LOCALHOST === '1'
    ? 'http://localhost:3000/bbe-api'
    : `https://api.${serverEnv}.bapi.belmond.${domainSuffix}/bbe-api`;

export const AUTH_DOMAIN =
  env === 'prod' || env === 'stg' ? 'auth.belmond.com' : 'auth.dev.belmond.com';

let myAccountDomain;

if (env === 'prod') {
  myAccountDomain = `https://www.belmond.com`;
} else if (env === 'stg') {
  myAccountDomain = `https://www-staging.belmond.com`;
} else {
  myAccountDomain = `https://www-dev.belmond.com`;
}

export const MY_ACCOUNT_DOMAIN = myAccountDomain;

let config = {
  adyen: {
    generic: { clientKey: 'test_YTLG5X3QBREQNEQTCSY45D2XTI6MH4YQ' },
    specific: { CAD: { clientKey: 'test_YTLG5X3QBREQNEQTCSY45D2XTI6MH4YQ' } },
  },
  reach5: 'z8xSXnqD4dsb8Vbqczim',
};

if (env === 'prod' || env === 'stg') {
  config = {
    adyen: {
      generic: { clientKey: 'live_DDWBQ7NQH5BC3AEAVSWL3DJT2QG7X2EY' },
      specific: {
        CAD: { clientKey: 'live_3VJJZYQBWNAZ7NWMR7QOTQLH3EOAQ4EV' },
      },
    },
    reach5: 'xeg15jV1Xxwp3vP60PQ5',
  };
}

export const REACT_APP_BBE_CONFIG = config;
