export function isPlainEnoughObject(o) {
  return o !== null && !Array.isArray(o) && typeof o === 'object';
}

export const mergeState = (inboundState, reducedState) => {
  const newState = { ...reducedState, ...inboundState };
  Object.keys(newState).forEach((key) => {
    // ignore _persist data
    if (key === '_persist') return;
    if (isPlainEnoughObject(reducedState[key])) {
      // if object is plain enough shallow merge the new values
      newState[key] = mergeState(inboundState[key], reducedState[key]);
    }
  });
  return newState;
};

const stateReconciler = (inboundState, originalState, reducedState) => {
  // only rehydrate if inboundState exists and is an object
  if (inboundState && typeof inboundState === 'object') {
    return mergeState(inboundState, reducedState);
  }
  return reducedState;
};

export default stateReconciler;
