import { createSlice } from '@reduxjs/toolkit';
import { API_DOMAIN } from '../../../globals';

const initialState = {
  error: '',
  session: null,
  orderRef: null,
  paymentDataStoreRes: null,
};

export const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    resetPaymentState: () => initialState,
    paymentSession: (state, action) => {
      const [res, status] = action.payload;
      if (status >= 300) {
        state.error = res;
      } else {
        [state.session, state.orderRef] = res;
      }
    },
    clearPaymentSession: (state) => {
      state.error = '';
      state.session = null;
      state.orderRef = null;
    },
    paymentDataStore: (state, action) => {
      const [res, status] = action.payload;
      if (status >= 300) {
        state.error = res;
      } else {
        state.paymentDataStoreRes = res;
      }
    },
  },
});

export const {
  paymentSession,
  clearPaymentSession,
  paymentDataStore,
  addAmount,
  resetPaymentState,
} = slice.actions;

export const createSession =
  (
    type,
    shopperEmail,
    metadata,
    productCode,
    accessToken,
    countryCode,
    payNowData
  ) =>
  async (dispatch) => {
    const response = await fetch(`${API_DOMAIN}/adyen-sessions?type=${type}`, {
      method: 'POST',
      body: JSON.stringify({
        shopperEmail,
        metadata,
        productCode,
        countryCode,
        payNowData,
      }),
      headers: {
        'Content-Type': 'application/json',
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      },
    });
    const jsonResponse = await response.json();
    dispatch(paymentSession([jsonResponse, response.status]));
  };

export const getPaymentDataStore = (productCode) => async (dispatch) => {
  const response = await fetch(
    `${API_DOMAIN}/adyen-get-payment-data-store?productCode=${productCode}`
  );
  dispatch(paymentDataStore([await response.json(), response.status]));
};

export const cancelOrRefundPayment =
  (orderRef, productCode) => async (dispatch) => {
    await fetch(
      `${API_DOMAIN}/adyen-cancel-or-refund-payment?orderRef=${orderRef}&productCode=${productCode}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    dispatch(getPaymentDataStore(productCode));
  };

export default slice.reducer;
