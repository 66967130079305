import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import {
  bookingStatuses,
  resetBookingsState,
} from '../../redux/slices/bookingsSlice/bookingsSlice';
import { resetGuestFormState } from '../../redux/slices/guestFormSlice/guestFormSlice';
import { resetPaymentState } from '../../redux/slices/paymentSlice/paymentSlice';
import styles from './EditBookingBox.module.css';

const { UNSUBMITTED } = bookingStatuses;

const EditBookingBox = ({ currentBooking }) => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onDiscardEditsBtnClick = async () => {
    if (currentBooking.status === UNSUBMITTED) {
      navigate('/checkout', { replace: true });
    } else {
      dispatch(resetGuestFormState());
      dispatch(resetBookingsState());
      dispatch(resetPaymentState());

      const { id, productCode, encryptedEmail } = currentBooking;
      navigate(
        `/confirmation?bookingId=${id}&productCode=${productCode}&email=${encryptedEmail}`,
        { replace: true }
      );
    }
  };

  return (
    <div className={styles.EditBookingBox__editBox}>
      <div className={styles.EditBookingBox__textBox}>
        <div className={styles.EditBookingBox__heading}>
          {t('You Are Editing Your Reservation')}
        </div>
        <span>
          {t(
            'Please select a different room and rate below, or update your stay details above.'
          )}{' '}
          {t('Price and availability may change.')}{' '}
          {t(
            'To edit another room, please discard edits and select to edit another room from the checkout.'
          )}
        </span>
      </div>
      <div>
        <Button
          className="button transparent mt-4 w-100"
          aria-label="Discard your edits"
          onClick={onDiscardEditsBtnClick}
        >
          {t('Discard Edits')}
        </Button>
      </div>
    </div>
  );
};

EditBookingBox.propTypes = {
  currentBooking: PropTypes.shape({
    id: PropTypes.string.isRequired,
    productCode: PropTypes.string.isRequired,
    encryptedEmail: PropTypes.string,
    status: PropTypes.string.isRequired,
  }),
};

export default EditBookingBox;
