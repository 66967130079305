import checkDateInAllowedRange from './check-date-in-allowed-range';

const determineDateRangeAvailability = (availability) => {
  return availability.map((dayData, index) => {
    if (!dayData.isCheckIn) {
      return { ...dayData };
    }

    const minStayValue = Math.max(
      dayData.metadata.MinStayArriveValue || 1,
      dayData.metadata.MinStayThroughValue || 1
    );

    const isDateInAllowedRange = checkDateInAllowedRange(
      dayData.date,
      availability,
      minStayValue
    );

    const wasCheckInBefore = dayData.isCheckIn;
    const newDayData = {
      ...dayData,
      isCheckIn: isDateInAllowedRange,
    };

    // If the check-in is not valid, we need to invalidate the check-out for the next day
    if (
      wasCheckInBefore &&
      !isDateInAllowedRange &&
      index < availability.length - 1
    ) {
      const nextDay = { ...availability[index + 1], isCheckOut: false };
      availability[index + 1] = nextDay;
    }

    return newDayData;
  });
};

export default determineDateRangeAvailability;
