import { google, ics, office365, outlook } from 'calendar-link';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import { ReactComponent as AppleCalendarIcon } from '../../../../assets/images/icons/AppleCalendarIcon.svg';
import { ReactComponent as GoogleCalendarIcon } from '../../../../assets/images/icons/GoogleCalendarIcon.svg';
import { ReactComponent as Office365CalendarIcon } from '../../../../assets/images/icons/Office365Icon.svg';
import { ReactComponent as OutlookCalendarIcon } from '../../../../assets/images/icons/OutlookIcon.svg';
import useLocalisedMoment from '../../../../hooks/useLocalisedMoment/useLocalisedMoment';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from './AddToCalendarButtons.module.css';

const AddToCalendarButtons = ({
  startDate,
  endDate,
  checkInTime,
  place,
  roomTypeName,
  bookingId,
  productCode,
}) => {
  const { t } = useTranslate();
  const moment = useLocalisedMoment();
  const checkInTimeAMPM = moment(checkInTime, 'HH:mm').format('h:mm A');
  const description = `${t("We're looking forward to your stay with us!")} ${t(
    'Please come to the front desk when you arrive.'
  )} ${t('Check-in time is any time after')} ${checkInTimeAMPM}. ${t(
    'For your convenience, your confirmation number is'
  )} #${bookingId}-${productCode}.`;
  const event = {
    title: `${t('My Stay at')} - ${roomTypeName}`,
    description,
    start: startDate,
    end: endDate,
    location: place,
  };

  return (
    <div
      data-testid="calendarButtons"
      className={styles.AddToCalendarButtons__container}
    >
      <Button
        className="button transparent w-100"
        onClick={() => window.open(google(event), '_blank')}
      >
        <div className="d-flex align-items-center justify-content-center relative">
          <GoogleCalendarIcon className={styles.AddToCalendarButtons__icon} />
          Google {t('calendar')}
        </div>
      </Button>
      <Button
        className="button transparent w-100"
        onClick={() => window.open(outlook(event), '_blank')}
      >
        <div className="d-flex align-items-center justify-content-center relative">
          <OutlookCalendarIcon className={styles.AddToCalendarButtons__icon} />
          Outlook {t('calendar')}
        </div>
      </Button>
      <Button
        className="button transparent w-100"
        onClick={() => window.open(office365(event), '_blank')}
      >
        <div className="d-flex align-items-center justify-content-center relative">
          <Office365CalendarIcon
            className={styles.AddToCalendarButtons__icon}
          />
          Office 365 {t('calendar')}
        </div>
      </Button>
      <Button
        className="button transparent w-100"
        onClick={() => window.open(ics(event), '_blank')}
      >
        <div className="d-flex align-items-center justify-content-center relative">
          <AppleCalendarIcon className={styles.AddToCalendarButtons__icon} />
          Apple {t('calendar')}
        </div>
      </Button>
    </div>
  );
};

AddToCalendarButtons.propTypes = {
  endDate: PropTypes.string,
  checkInTime: PropTypes.string,
  place: PropTypes.string,
  roomTypeName: PropTypes.string,
  startDate: PropTypes.string,
  bookingId: PropTypes.string,
  productCode: PropTypes.string,
};

export default AddToCalendarButtons;
