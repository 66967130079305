import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import { ReactComponent as CrossIcon } from '../../../../../../assets/images/icons/Cross.svg';
import styles from './FilterChip.module.css';

const FilterChip = ({ onRemove, title }) => {
  return (
    <span className={styles.FilterChip__box}>
      <span>{title}</span>
      {onRemove && (
        <Button
          className="textButton d-flex align-items-center"
          onClick={onRemove}
          aria-label={`remove ${title}`}
        >
          <CrossIcon style={{ marginLeft: 10 }} />
        </Button>
      )}
    </span>
  );
};
FilterChip.propTypes = {
  onRemove: PropTypes.func,
  title: PropTypes.string,
};

export default FilterChip;
