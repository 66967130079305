import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import singleGuestFormStyles from '../../../../components/SingleGuestForm/SingleGuestForm.module.css';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';

const AgentDetails = () => {
  const { t } = useTranslate();
  const agentProfile = useSelector((state) => state.bookings.agentProfile);

  return (
    <Row className="mt-4 mx-1">
      <div className={singleGuestFormStyles.SingleGuestForm__sectionContainer}>
        <div className={singleGuestFormStyles.SingleGuestForm__sectionHeading}>
          <h2>{t('Travel Agent Information')}</h2>
        </div>
        <Row>
          <Col md="4">
            <p className="AgentDetails__agencyDetails">
              <span> {agentProfile.companyName}</span>
              <br />
              <span>
                {agentProfile.address.address} {agentProfile.address.postalCode}
              </span>
              <br />
              <span>
                {agentProfile.address.city}, {agentProfile.address.countryCode}
              </span>
            </p>
          </Col>
          <Col md="8">
            <div className="d-flex flex-row">
              <div>
                <b>{t('Email')}:</b>
                <br />
                <b>{t('Telephone')}:</b>
                <br />
                <b>{t('ID number')}:</b>
              </div>
              <div className="ms-3">
                {agentProfile.email}
                <br />
                {agentProfile.phone}
                <br />
                {agentProfile.id}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Row>
  );
};

export default AgentDetails;
