import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ReactComponent as EditPenIcon } from '../../../../../../assets/images/icons/EditPen.svg';
import { ReactComponent as BedIcon } from '../../../../../../assets/images/icons/bedIcon.svg';
import { useScreenDetector } from '../../../../../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../../../../../hooks/useTranslate/useTranslate';
import styles from '../../MultiRoomPicker.module.css';

const MultiRoomPickerItem = ({
  room,
  editing,
  onHighlightRoom,
  highlightedRoomIndex,
  index,
  guests,
}) => {
  const { t } = useTranslate();
  const { isDesktop } = useScreenDetector();
  const bookings = useSelector((state) => state.bookings.list);
  const media = useSelector((state) => state.media.mediaUrls);

  const imagesForSelectedRoomType = media.filter((mediaItem) =>
    mediaItem.includes(`${room?.productCode}/${room?.roomType.code}`)
  );

  const { roomType, roomRate } = room || {};
  const { children, numAdults } = guests;
  const numChildren = children.length;

  const childrenText = numChildren > 1 ? t('children') : t('child');
  const adultsText = numAdults > 1 ? t('adults') : t('adult');

  // room is selectable if the next to the last chosen room
  const isRoomSelectable =
    highlightedRoomIndex >= index || index <= bookings.length;
  const editingRoom = editing && index !== highlightedRoomIndex;

  if (!room)
    return (
      <div
        className="d-flex justify-content-center h-100"
        style={{
          minWidth: isDesktop ? '30%' : '100%',
          padding: !isDesktop && '0 10px',
        }}
      >
        <div className="d-flex flex-column align-items-center w-100 h-100">
          <button
            disabled={editingRoom || !isRoomSelectable}
            type="button"
            key={`MultiRoomPickerItem-${index}`}
            onClick={() => onHighlightRoom(index)}
            className={`${styles.MultiRoomPicker__roomButton}
          ${
            index === highlightedRoomIndex &&
            styles.MultiRoomPicker__roomButtonSelected
          }`}
            style={{
              opacity: index !== highlightedRoomIndex ? 0.3 : 1,
              background: '#fff',
              padding: !isDesktop && '0 10px',
              minWidth: ' 100%',
              ...(highlightedRoomIndex === index
                ? { background: '#faf9f7' }
                : {}),
            }}
          >
            <div
              className={`d-flex h-100 w-100 ${
                !isDesktop && 'justify-content-center'
              }`}
            >
              {isDesktop && (
                <div
                  className={styles.MultiRoomPicker__bedIconContainer}
                  data-testid="bed-icon"
                >
                  <BedIcon />
                </div>
              )}
              <div
                className="d-flex flex-column justify-content-center"
                style={{
                  alignItems: isDesktop ? 'flex-start' : 'center',
                  paddingLeft: 16,
                }}
              >
                <div className="fw-600 text-uppercase">
                  {t('Choose Room')} {index + 1}
                </div>
                <span>
                  {numAdults} {adultsText}
                  {numChildren > 0 && `, ${numChildren} ${childrenText}`}
                </span>
              </div>
            </div>
          </button>
        </div>
      </div>
    );
  else
    return (
      <div
        className="d-flex justify-content-center"
        style={{
          minWidth: isDesktop ? '30%' : '100%',
          marginLeft: isDesktop ? 10 : 'auto',
          marginRight: isDesktop ? 10 : 'auto',
          padding: !isDesktop && '0 10px',
        }}
      >
        <div className="d-flex flex-column align-items-center w-100">
          <button
            disabled={editing && index === highlightedRoomIndex}
            type="button"
            key={`MultiRoomPickerItem-${index}`}
            onClick={() => onHighlightRoom(index)}
            className={`
              ${styles.MultiRoomPicker__roomButton}
              ${
                index === highlightedRoomIndex &&
                styles.MultiRoomPicker__roomButtonSelected
              }
              d-flex flex-row align-items-center
            `}
            style={{
              position: 'relative',
              opacity: editing && index !== highlightedRoomIndex ? 0.3 : 1,
              padding: !isDesktop && '0 30px',
              minWidth: '100%',
              ...(highlightedRoomIndex === index
                ? { background: '#faf9f7' }
                : {}),
            }}
          >
            <div
              className="d-flex flex-row overflow-hidden position-relative"
              style={{
                height: 84,
                width: 84,
                minWidth: 84,
              }}
            >
              <img
                src={imagesForSelectedRoomType[0]}
                alt={`Selected Room ${index}`}
                className="m-auto"
                style={{
                  opacity: index !== highlightedRoomIndex ? 0.5 : 1,
                  minHeight: '100%',
                  minWidth: '100%',
                  objectFit: 'cover',
                }}
              />

              {index !== highlightedRoomIndex && !editing && (
                <div
                  className="position-absolute"
                  style={{
                    margin: '30px 0 0 36px',
                  }}
                >
                  <EditPenIcon />
                </div>
              )}
            </div>
            <div
              className="d-flex flex-column px-3 py-2"
              style={{
                alignItems: isDesktop ? 'flex-start' : 'center',
                opacity: index !== highlightedRoomIndex ? 0.5 : 1,
              }}
            >
              <div
                className="fw-600 text-uppercase"
                style={{
                  textAlign: isDesktop ? 'left' : 'center',
                  paddingLeft: !isDesktop && 20,
                }}
              >
                {roomType.title}{' '}
                {!isDesktop && (
                  <EditPenIcon style={{ marginLeft: 3, marginBottom: 3 }} />
                )}
              </div>
              <span>
                {numAdults} {adultsText}{' '}
                {numChildren > 0 ? `${numChildren} ${childrenText}` : ''}
              </span>
              <span>{roomRate.name}</span>
            </div>
          </button>
        </div>
      </div>
    );
};

MultiRoomPickerItem.propTypes = {
  room: PropTypes.shape({
    productCode: PropTypes.string,
    id: PropTypes.string,
    roomType: PropTypes.shape({
      images: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      code: PropTypes.string,
    }),
    roomRate: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  editing: PropTypes.bool.isRequired,
  onHighlightRoom: PropTypes.func.isRequired,
  highlightedRoomIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  guests: PropTypes.shape({
    numAdults: PropTypes.number.isRequired,
    children: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
};

export default MultiRoomPickerItem;
