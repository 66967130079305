import React from 'react';
import { ReactComponent as LinkOutIcon } from '../../../../assets/images/icons/LinkOut.svg';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';

import styles from '../../Footer.module.css';

const JoinOurFamilyList = () => {
  const { t } = useTranslate();
  return (
    <ul className={styles.JoinOurFamilyList__ul}>
      <li>
        <a
          href="https://www.belmondpro.com/"
          target="_blank"
          rel="noreferrer"
          title=""
        >
          {t('Travel Professionals')}
          <LinkOutIcon className={styles.icon} />
        </a>
      </li>
      <li>
        <a
          href="https://www.belmondevents.com/"
          target="_blank"
          rel="noreferrer"
          title=""
        >
          {t('Event Planners')}
          <LinkOutIcon className={styles.icon} />
        </a>
      </li>
      <li>
        <a
          href="https://www.belmond.com/media-centre/"
          target="_blank"
          rel="noreferrer"
          title=""
        >
          {t('Media Centre')}
          <LinkOutIcon className={styles.icon} />
        </a>
      </li>
      <li>
        <a
          href="https://www.belmond.com/development"
          target="_blank"
          rel="noreferrer"
          title=""
        >
          {t('Development')}
          <LinkOutIcon className={styles.icon} />
        </a>
      </li>
      <li>
        <a
          href="https://careers.belmond.com/"
          target="_blank"
          rel="noreferrer"
          title=""
        >
          {t('Careers')}
          <LinkOutIcon className={styles.icon} />
        </a>
      </li>
    </ul>
  );
};

export default JoinOurFamilyList;
