import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'reactstrap';
import { useScreenDetector } from '../../../../../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../../../../../hooks/useTranslate/useTranslate';
import FilterChip from '../FilterChip/FilterChip';

const ActiveFilters = ({ availableFilters, onClearAll, onApply }) => {
  const { t } = useTranslate();
  const { isTablet } = useScreenDetector();
  const { watch, setValue, getValues } = useFormContext();

  const roomView = watch('filters.roomView');
  const roomCategory = watch('filters.roomCategory');
  const roomType = watch('filters.roomType');

  const filtersMap = Object.values(availableFilters).reduce((acc, array) => {
    array.forEach(({ code, name }) => {
      acc[code] = name;
    });
    return acc;
  }, {});

  const activeFilters = [
    { name: 'filters.roomView', filters: roomView },
    { name: 'filters.roomCategory', filters: roomCategory },
    { name: 'filters.roomType', filters: roomType },
  ];

  const totalActiveFilterCount = activeFilters.reduce(
    (total, filterObj) => total + filterObj.filters.length,
    0
  );

  const handleFilterChipRemove = (filter, filterName) => {
    const filteredArray = getValues(filterName).filter(
      (item) => item !== filter
    );
    setValue(filterName, filteredArray);
    onApply();
  };

  const clearButton = (
    <Button
      className="hoverEffectButton mx-3"
      onClick={onClearAll}
      aria-label="Clear all filters"
    >
      {t('Clear all')}
    </Button>
  );

  return totalActiveFilterCount ? (
    <div>
      <hr className="bbe-hr my-3" />
      <div className="d-inline-flex align-items-center">
        {!isTablet ? (
          <div className="d-block">
            {activeFilters.map((filterObj) =>
              filterObj.filters.map((filter) => (
                <FilterChip
                  key={filter}
                  title={filtersMap[filter]}
                  onRemove={() =>
                    handleFilterChipRemove(filter, filterObj.name)
                  }
                />
              ))
            )}
            {clearButton}
          </div>
        ) : (
          <div className="d-block">
            <FilterChip title={`${totalActiveFilterCount} Filters`} />
            {clearButton}
          </div>
        )}
      </div>
    </div>
  ) : null;
};

ActiveFilters.propTypes = {
  availableFilters: PropTypes.object,
  onClearAll: PropTypes.func,
  onApply: PropTypes.func,
};

export default ActiveFilters;
