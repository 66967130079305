import { getSelectedRange } from '.';

const minStayDisabledReason =
  'You selected a restricted date but we may be able to accommodate you. Please, contact the hotel directly.';

// check if the selected range contains any disallowed days
// this is most useful for the initial dates entetered when the calendar is first loade

const checkIfDisabledDateIsPicked = (sDate, eDate, dAvailability, moment) => {
  const range = getSelectedRange(sDate, eDate, moment);
  const selectedAvailability = dAvailability.filter(({ date }) =>
    range.includes(date)
  );

  let disabledReason =
    'You have selected a restricted date. A date within your stay is not available.';

  // cant be disabled picked day, if no day is picked
  if (selectedAvailability.length === 0) return null;

  const isDisabledDatePicked = selectedAvailability.some(
    ({ date, isCheckIn, isCheckOut, metadata }) => {
      // lets consider the metadata
      // lets start with MinStayThrough
      // lets get the highest minStayThrough throughout all the dates in the range
      // if the highest minStayThrough is greater than the range, return as disabled
      // remembering ignore the last days min stay through value

      const minStayThrough = Math.max(
        ...selectedAvailability
          .slice(0, -1)
          .map(({ metadata }) => metadata?.MinStayThroughValue || 0)
      );

      // add one here, because we are not staying the last day
      if (minStayThrough + 1 > range.length) {
        disabledReason = minStayDisabledReason;
        return true;
      }

      // now lets consider the MinStayArrive
      // if the value in MinStayArrival on the first day in the range is greater than the range
      // return as disabled

      const startDateMetadata = selectedAvailability.find(
        ({ date }) => date === sDate
      );

      // negative one because we are not counting the first day
      if (startDateMetadata?.metadata?.MinStayArriveValue > range.length - 1) {
        disabledReason = minStayDisabledReason;
        return true;
      }

      // we can now move onto more of the generic checks
      // if both check in and check out are selected, we don't need to check for disabled days
      if (isCheckIn && isCheckOut) {
        return false;
      }

      // if neither are true, we can return as disabled
      if (!isCheckIn && !isCheckOut) {
        return true;
      }

      // if the first day but NoArrive is selected, return as disabled
      if (!isCheckIn && metadata?.NoArrive && moment(date).isSame(sDate)) {
        return true;
      }

      // if any of the days excluding the last day are check-out only, return as disabled
      // lets ignore NoArrive
      if (isCheckOut && !moment(date).isSame(eDate) && !metadata?.NoArrive) {
        return true;
      }

      // if any of the days excluding the first day are check-in only, return as disabled
      // not sure this is a real case but just in case, lets implement
      return !!(isCheckIn && !moment(date).isSame(sDate));
    }
  );

  return isDisabledDatePicked ? disabledReason : null;
};

export default checkIfDisabledDateIsPicked;
