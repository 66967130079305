import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import InputComponent from '../InputComponent/InputComponent';
import PhoneInput from '../PhoneInput/PhoneInput';
import SelectComponent from '../SelectComponent/SelectComponent';
import styles from './GuestDetailsForm.module.css';

const GuestDetailsForm = ({ guestFormKey, disabled }) => {
  const {
    t,
    constants: {
      prefixOptions,
      formRules: { emailRule },
    },
  } = useTranslate();

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  return (
    <div className="mb-4">
      <Row>
        <Col
          xs="12 mb-3"
          md="6"
          className={styles.GuestDetailsForm__prefixGroup}
        >
          <div style={{ width: '30%', marginRight: 30 }}>
            <SelectComponent
              name={`bookingsGuestValues[${guestFormKey}].prefix`}
              control={control}
              label={t('Prefix')}
              setValue={setValue}
              options={prefixOptions}
              watch={watch}
              errors={errors}
              autoComplete="honorific-prefix"
              disabled={disabled}
            />
          </div>
          <InputComponent
            name={`bookingsGuestValues[${guestFormKey}].firstName`}
            control={control}
            rules={{ required: t('First name cannot be blank') }}
            errors={errors}
            placeholder=" "
            label={
              <>
                {t('First Name')}
                <span className={styles.GuestDetailsForm__required}>*</span>
              </>
            }
            autoComplete="given-name"
            disabled={disabled}
          />
        </Col>
        <Col md="6">
          <InputComponent
            name={`bookingsGuestValues[${guestFormKey}].lastName`}
            control={control}
            rules={{ required: t('Last name cannot be blank') }}
            errors={errors}
            placeholder=" "
            label={
              <>
                {t('Last Name')}
                <span className={styles.GuestDetailsForm__required}>*</span>
              </>
            }
            autoComplete="family-name"
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <PhoneInput
            name={`bookingsGuestValues[${guestFormKey}].phone`}
            control={control}
            errors={errors}
            hasError={!!errors?.bookingsGuestValues?.[guestFormKey]?.phone}
            rules={{
              required: t('Phone number cannot be blank'),
              minLength: { value: 8, message: t('Invalid phone number') },
            }}
            label={
              <>
                {t('Phone')}
                <span className={styles.GuestDetailsForm__required}>*</span>
              </>
            }
            disabled={disabled}
          />
        </Col>
        <Col md="6">
          <InputComponent
            name={`bookingsGuestValues[${guestFormKey}].email`}
            control={control}
            rules={{
              ...emailRule,
              required: emailRule.required,
            }}
            errors={errors}
            passivePlaceholder="alex@example.com"
            label={
              <>
                {t('Email')}
                <span className={styles.GuestDetailsForm__required}>*</span>
              </>
            }
            autoComplete="email"
            disabled={disabled}
          />
          <div className={styles.GuestDetailsForm__confirmationMessage}>
            {t('The email address to which we will send your confirmation.')}
          </div>
        </Col>
      </Row>
    </div>
  );
};
GuestDetailsForm.propTypes = {
  guestFormKey: PropTypes.string,
  disabled: PropTypes.bool,
};

export default GuestDetailsForm;
