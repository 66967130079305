import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from 'reactstrap';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/images/icons/ArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/images/icons/ArrowUp.svg';
import { ReactComponent as HamburgerStairs } from '../../../../assets/images/icons/HamburgerStairs.svg';
import { gtmBookingFilters } from '../../../../gtm/events';
import usePrevious from '../../../../hooks/usePrevious/usePrevious';
import { useScreenDetector } from '../../../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from './DisplayOptionsPanel.module.css';
import ActiveFilters from './components/ActiveFilters/ActiveFilters';
import FilterOptions from './components/FilterOptions/FilterOptions';
import SortOptions from './components/SortOptions/SortOptions';

const DisplayOptionsPanel = ({
  onFiltersChange,
  onSortByChange,
  onDisplayTypeChange,
  lowerPriceAvailable,
  higherPriceAvailable,
  availableFilters,
  defaultValues,
  baseCurrency,
}) => {
  const { t } = useTranslate();
  const { isMobile } = useScreenDetector();
  const isFirstRenderDisplay = useRef(true);
  const isFirstRenderSort = useRef(true);

  const form = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const [filtersCollapsed, setFiltersCollapsed] = useState(true);
  const [sortOptionsShown, setSortOptionsShown] = useState(false);

  const displayType = form.watch('displayType');
  const sortBy = form.watch('sortBy');
  const minPrice = form.watch('filters.minPrice');
  const maxPrice = form.watch('filters.maxPrice');

  const prevLowerPriceAvailable = usePrevious(lowerPriceAvailable);
  const prevHigherPriceAvailable = usePrevious(higherPriceAvailable);

  const handleFiltersFormSubmit = ({ filters }) => {
    onFiltersChange(filters);
  };

  const handleClearAllFilters = () => {
    const filters = {
      roomView: [],
      roomCategory: [],
      roomType: [],
      minPrice: lowerPriceAvailable,
      maxPrice: higherPriceAvailable,
    };

    form.reset({ ...defaultValues, filters });
    onFiltersChange(filters);
  };

  useEffect(() => {
    if (
      prevLowerPriceAvailable === null ||
      (prevLowerPriceAvailable !== lowerPriceAvailable &&
        minPrice === prevLowerPriceAvailable)
    ) {
      form.setValue('filters.minPrice', lowerPriceAvailable);
    }
    if (
      prevHigherPriceAvailable === null ||
      (prevHigherPriceAvailable !== higherPriceAvailable &&
        maxPrice === prevHigherPriceAvailable)
    ) {
      form.setValue('filters.maxPrice', higherPriceAvailable);
    }
  }, [
    lowerPriceAvailable,
    higherPriceAvailable,
    minPrice,
    maxPrice,
    prevLowerPriceAvailable,
    prevHigherPriceAvailable,
    form,
  ]);

  useEffect(() => {
    if (isFirstRenderDisplay.current) {
      isFirstRenderDisplay.current = false;
      return;
    }

    onDisplayTypeChange(displayType);
    gtmBookingFilters('none', displayType);
  }, [displayType, onDisplayTypeChange]);

  useEffect(() => {
    if (isFirstRenderSort.current) {
      isFirstRenderSort.current = false;
      return;
    }

    onSortByChange(sortBy);
  }, [onSortByChange, sortBy]);

  return (
    <div className={styles.DisplayOptionsPanel__container}>
      <FormProvider {...form}>
        <div
          className={`d-flex ${
            sortOptionsShown && isMobile ? 'flex-column' : 'flex-row'
          } justify-content-between align-items-center `}
        >
          <div
            className={`d-flex align-items-center ${
              sortOptionsShown ? 'w-100' : ''
            }`}
          >
            {isMobile && (
              <button
                style={{
                  marginRight: 10,
                  marginBottom: sortOptionsShown ? 20 : 0,
                }}
                onClick={() => setSortOptionsShown((prev) => !prev)}
              >
                <HamburgerStairs className="test" />
              </button>
            )}
            {(sortOptionsShown || !isMobile) && <SortOptions />}
          </div>
          <div
            className={`d-flex justify-content-end ${isMobile ? 'w-100' : ''}`}
          >
            <Button
              className="button transparent position-relative d-flex align-items-center justify-content-center"
              aria-label="show filters"
              onClick={() => setFiltersCollapsed((prev) => !prev)}
            >
              <div className="d-flex align-items-center">
                <div style={{ paddingRight: 18 }}>
                  {t(filtersCollapsed ? 'Show Filters' : 'Hide Filters')}
                </div>
                {filtersCollapsed ? (
                  <ArrowDownIcon style={{ right: 15, position: 'absolute' }} />
                ) : (
                  <ArrowUpIcon style={{ right: 15, position: 'absolute' }} />
                )}
              </div>
            </Button>
          </div>
        </div>
        {!filtersCollapsed && (
          <FilterOptions
            baseCurrency={baseCurrency}
            availableFilters={availableFilters}
            lowerPriceAvailable={lowerPriceAvailable}
            higherPriceAvailable={higherPriceAvailable}
            onClearAll={handleClearAllFilters}
            onApply={form.handleSubmit(handleFiltersFormSubmit)}
          />
        )}
        {filtersCollapsed && (
          <div>
            <ActiveFilters
              onApply={form.handleSubmit(handleFiltersFormSubmit)}
              availableFilters={availableFilters}
              onClearAll={handleClearAllFilters}
            />
          </div>
        )}
      </FormProvider>
    </div>
  );
};

DisplayOptionsPanel.propTypes = {
  onFiltersChange: PropTypes.func,
  onDisplayTypeChange: PropTypes.func,
  availableFilters: PropTypes.object,
  onSortByChange: PropTypes.func,
  defaultValues: PropTypes.object,
  lowerPriceAvailable: PropTypes.number,
  higherPriceAvailable: PropTypes.number,
  baseCurrency: PropTypes.string,
};

export default DisplayOptionsPanel;
