import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import formatRoomName from '../../../../helpers/formatRoomName/formatRoomName';
import useLocalisedMoment from '../../../../hooks/useLocalisedMoment/useLocalisedMoment';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from './../BookingsConfirmationActionButtons/BookingActionButtonsModal.module.css';

const EditConfirmationBookingsModal = ({
  open,
  bookings,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslate();
  const moment = useLocalisedMoment();
  const [selectedBooking, setSelectedBooking] = useState();

  return (
    <ConfirmationModal
      title={t('Which room do you want to edit?')}
      cancelButton={t('Do Not Edit')}
      confirmButton={t('Edit Now')}
      content={
        <FormGroup>
          {bookings?.map((booking, i) => (
            <div
              className={styles.BookingActionButtonsModal__inputContainer}
              key={booking.id}
            >
              <Input
                type="radio"
                className={styles.BookingActionButtonsModal__inputCheckbox}
                value={i}
                name="editRadio"
                onChange={() => setSelectedBooking(booking)}
                aria-label={`Edit ${booking.id}`}
              />
              <div className={styles.BookingActionButtonsModal__modalText}>
                <span className="bold text-nowrap">
                  {formatRoomName(
                    booking.roomType.category.name,
                    booking.roomType.title
                  )}{' '}
                </span>{' '}
                /{' '}
                <span className="text-nowrap">
                  {moment(booking.startDate).format('ddd, MMM D, YYYY')}
                </span>{' '}
                /{' '}
                <span className="text-nowrap">
                  #{booking.id}-{booking.productCode}
                </span>
              </div>
            </div>
          ))}
        </FormGroup>
      }
      open={open}
      onConfirm={() => selectedBooking && onConfirm(selectedBooking)}
      onCancel={onCancel}
    />
  );
};
EditConfirmationBookingsModal.propTypes = {
  open: PropTypes.bool,
  bookings: PropTypes.array,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default EditConfirmationBookingsModal;
