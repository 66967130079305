import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import { ReactComponent as CrossIcon } from '../../../assets/images/icons/Cross.svg';
import { ReactComponent as EditPenIcon } from '../../../assets/images/icons/EditPen.svg';
import useTranslate from '../../../hooks/useTranslate/useTranslate';

import { bookingsCartStatuses } from '../../../redux/slices/bookingsSlice/bookingsSlice';
import styles from './BookingActionButtons.module.css';

const { EDIT_BOOKING } = bookingsCartStatuses;

const BookingActionButtons = ({
  onEdit,
  onRemove,
  roomTitle,
  showEdit,
  bookingsCartStatus,
}) => {
  const { t } = useTranslate();
  if (bookingsCartStatus === EDIT_BOOKING) return null;
  return (
    <div>
      <hr className="bbe-hr" />
      <div className={styles.BookingActionButtons__container}>
        {showEdit ? (
          <Button
            className="textButton normal d-flex align-items-center"
            onClick={onEdit}
            aria-label={`Edit ${roomTitle} Room`}
          >
            {t('Edit')}
            <EditPenIcon style={{ marginLeft: '6px' }} />
          </Button>
        ) : (
          <div className="flex-1" />
        )}

        <Button
          className="textButton normal d-flex align-items-center"
          onClick={onRemove}
          aria-label={`Remove ${roomTitle} Room`}
        >
          {t('Remove')}
          <CrossIcon style={{ marginLeft: '6px' }} />
        </Button>
      </div>
    </div>
  );
};
BookingActionButtons.propTypes = {
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  roomTitle: PropTypes.string.isRequired,
  showEdit: PropTypes.bool,
  bookingsCartStatus: PropTypes.string.isRequired,
};

export default BookingActionButtons;
