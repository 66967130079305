import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'reactstrap';
import usePrintPrice from '../../hooks/usePrintPrice/usePrintPrice';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import styles from './BelmondSingleHotel.module.css';

const BelmondSingleHotel = ({
  heroImg,
  shortName,
  region,
  maintenance,
  productCode,
  lowestRatePrice,
  onClick,
  loading,
}) => {
  const { t } = useTranslate();
  const { printPrice } = usePrintPrice(productCode);
  const [imgSrc, setImgSrc] = useState('');
  const [isFallback, setIsFallback] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);

  // Define fallback image URL
  const fallbackImg = 'images/logo-square.png';

  useEffect(() => {
    const img = new Image();
    img.src = heroImg;

    img.onload = () => {
      setImgSrc(heroImg);
      setIsFallback(false);
      setIsImageLoading(false);
    };

    img.onerror = () => {
      setImgSrc(fallbackImg);
      setIsFallback(true);
      setIsImageLoading(false);
    };

    // Cleanup function
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [heroImg]);

  return (
    <div
      className={`${styles.BelmondSingleHotel__cardWrapper} ${
        maintenance ? styles.BelmondSingleHotel__maintenanceMode : ''
      }`}
    >
      <div className={styles.BelmondSingleHotel__imageWrapper}>
        {isImageLoading ? (
          <Skeleton containerClassName={styles.BelmondSingleHotel__skeleton} />
        ) : (
          <img
            src={imgSrc}
            className={`${styles.BelmondSingleHotel__image} ${
              isFallback ? styles.BelmondSingleHotel__fallbackImage : ''
            }`}
            alt={shortName}
          />
        )}
        {maintenance && (
          <div className={styles.BelmondSingleHotel__maintenanceLabel}>
            {t('Website Maintenance')}
          </div>
        )}
      </div>
      <div className={styles.BelmondSingleHotel__title}>
        <h2 className="OneLinkNoTx">{shortName}</h2>
        <div className={styles.BelmondSingleHotel__regionName}>{region}</div>
      </div>
      {!maintenance && (
        <>
          <hr className="bbe-hr" style={{ margin: '0 15px' }} />
          <div className={styles.BelmondSingleHotel__lowerPart}>
            {loading ? (
              <Skeleton
                width={130}
                height={20}
                containerTestId="price-skeleton"
              />
            ) : (
              <div>
                {lowestRatePrice && (
                  <div>
                    {t('Rates from')}{' '}
                    <span className={styles.BelmondSingleHotel__ratePrice}>
                      {printPrice(lowestRatePrice, true)}
                    </span>
                  </div>
                )}
              </div>
            )}
            <div>
              <Button
                className="button"
                aria-label={`Select ${shortName}`}
                onClick={() => onClick(productCode)}
              >
                <div className="mx-4 text-white">{t('Select')}</div>
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

BelmondSingleHotel.propTypes = {
  heroImg: PropTypes.string,
  shortName: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  maintenance: PropTypes.oneOfType([
    PropTypes.shape({
      productCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
      fromDateTime: PropTypes.string.isRequired,
      toDateTime: PropTypes.string.isRequired,
      chinaOnly: PropTypes.bool,
      message: PropTypes.string,
    }),
    PropTypes.oneOf([false]),
  ]),
  productCode: PropTypes.string.isRequired,
  lowestRatePrice: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default BelmondSingleHotel;
