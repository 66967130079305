import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { getPersistConfig } from 'redux-deep-persist';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { initStateWithPrevTab } from 'redux-state-sync';
import thunk from 'redux-thunk';
import { env } from '../globals';
import migrations from './migrations';
import appSettingsReducer from './slices/appSettingsSlice/appSettingsSlice';
import availabilityReducer from './slices/availabilitySlice/availabilitySlice';
import belmondReducer from './slices/belmondSlice/belmondSlice';
import bookingsReducer from './slices/bookingsSlice/bookingsSlice';
import guestFormReducer from './slices/guestFormSlice/guestFormSlice';
import mediaReducer from './slices/mediaSlice/mediaSlice';
import paymentReducer from './slices/paymentSlice/paymentSlice';
import userReducer from './slices/userSlice/userSlice';
import stateReconciler from './state-reconciler';

const rootReducer = combineReducers({
  payment: paymentReducer,
  user: userReducer,
  appSettings: appSettingsReducer,
  belmond: belmondReducer,
  bookings: bookingsReducer,
  guestForm: guestFormReducer,
  availability: availabilityReducer,
  media: mediaReducer,
});

const persistConfig = getPersistConfig({
  key: 'belmond',
  storage,
  version: 1,
  migrate: createMigrate(migrations, { debug: false }),
  stateReconciler,
  rootReducer,
  blacklist: ['guestForm', 'payment.session', 'availability'],
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  devTools: env !== 'production',
  middleware: [thunk],
  reducer: persistedReducer,
});

initStateWithPrevTab(store);

export const persistor = persistStore(store);
