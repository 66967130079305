import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useScreenDetector } from '../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import styles from './Footer.module.css';
import Contact from './components/Contact/Contact';
import Copyright from './components/Copyright/Copyright';
import ExploreOurWorldList from './components/ExploreOurWorld/ExploreOurWorldList';
import FooterMobile from './components/FooterMobile/FooterMobile';
import JoinOurFamilyList from './components/JoinOurFamilyList/JoinOurFamilyList';
import Newsletter from './components/Newsletter/Newsletter';
import SocialIcons from './components/SocialIcons/SocialIcons';

const Footer = ({
  loading,
  instagramLink,
  facebookLink,
  address,
  phone,
  contactUsLink,
  findUsLink,
  productCode,
}) => {
  const { t } = useTranslate();
  const [newsletterSubmitted, setNewsletterSubmitted] = useState(false);
  const { isDesktop } = useScreenDetector();

  const newsletterEnabled = useSelector(
    (state) => state.appSettings.featureFlags.newsletterEnabled
  );

  const handleNewsletterSubmitted = () => {
    setNewsletterSubmitted(true);
  };

  return (
    <div className="d-print-none">
      {isDesktop && (
        <>
          <hr className="bbe-hr" />
          <div
            className="d-flex container-xxl py-4"
            data-testid="Footer--desktop"
          >
            <div className={`${styles.Footer__bbeFooter} mt-5 mx-3`}>
              <Row className="mb-5">
                <Col md="4">
                  <h5 style={{ textTransform: 'uppercase' }}>
                    {t('Contact Us')}
                  </h5>
                  <Contact
                    loading={loading}
                    address={address}
                    phone={phone}
                    contactUsLink={contactUsLink}
                    productCode={productCode}
                  />
                  <div className="d-flex align-items-center">
                    <h5 style={{ textTransform: 'uppercase' }}>
                      {t('Follow Us')}
                    </h5>
                    <SocialIcons
                      instagramLink={instagramLink}
                      facebookLink={facebookLink}
                    />
                  </div>
                </Col>
                <Col md="2">
                  <h5 style={{ textTransform: 'uppercase' }}>
                    {t('Explore Our World')}
                  </h5>
                  <div className={styles.Footer__linksList}>
                    <ExploreOurWorldList
                      contactUsLink={contactUsLink}
                      findUsLink={findUsLink}
                    />
                  </div>
                </Col>
                <Col md="2">
                  <h5 style={{ textTransform: 'uppercase' }}>
                    {t('Join Our Family')}
                  </h5>
                  <div className={styles.Footer__linksList}>
                    <JoinOurFamilyList />
                  </div>
                </Col>

                {newsletterEnabled && (
                  <Col md="4">
                    {!newsletterSubmitted ? (
                      <>
                        <h5>
                          SIGN UP FOR EXCLUSIVE NEWS, TRAVEL INSPIRATION AND
                          OFFERS
                        </h5>
                        <Newsletter
                          onNewsletterSubmitted={handleNewsletterSubmitted}
                        />
                      </>
                    ) : (
                      <p>
                        Thank you for signing up. You're all set to receive the
                        latest news and offers from Belmond.
                      </p>
                    )}
                  </Col>
                )}
              </Row>

              <hr className="bbe-hr" />
              <Row>
                <div className={styles.Footer__footerText}>
                  <Copyright />
                </div>
              </Row>
            </div>
          </div>
        </>
      )}
      {!isDesktop && (
        <FooterMobile
          loading={loading}
          address={address}
          phone={phone}
          contactUsLink={contactUsLink}
          instagramLink={instagramLink}
          facebookLink={facebookLink}
          productCode={productCode}
          findUsLink={findUsLink}
        />
      )}
    </div>
  );
};

Footer.propTypes = {
  loading: PropTypes.bool,
  instagramLink: PropTypes.string,
  facebookLink: PropTypes.string,
  address: PropTypes.string,
  phone: PropTypes.string,
  contactUsLink: PropTypes.string,
  findUsLink: PropTypes.string,
  productCode: PropTypes.string,
};

export default Footer;
