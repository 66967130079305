import { createSlice } from '@reduxjs/toolkit';
import { CHINA_VERSION } from '../../../globals';
import staticEnTranslations from '../../../translations/en-us.json';

const initialState = {
  countryCode: null,
  currencies: {
    current: CHINA_VERSION ? 'CNY' : 'GBP',
    exchanges: {},
    selectedByUser: false,
    list: {},
  },
  languages: {
    current: CHINA_VERSION ? 'zh-cn' : 'en-us',
    list: [],
  },
  timezone: '',
  translations: {
    'zh-cn': {},
    'en-us': staticEnTranslations,
  },
  featureFlags: {
    loginEnabled: true,
    newsletterEnabled: true,
    highestAgentCommissionEnabled: true,
  },
};

export const appSettingsSlice = createSlice({
  initialState,
  name: 'appSettingsSlice',
  reducers: {
    setCurrentCurrency: (state, action) => {
      state.currencies.current = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies.list = action.payload;
    },
    setCurrencySelectedByUser: (state, action) => {
      state.currencies.selectedByUser = action.payload;
    },
    setExchanges: (state, action) => {
      state.currencies.exchanges = action.payload;
    },
    setCurrentLanguage: (state, action) => {
      state.languages.current = action.payload;
    },
    setLanguageList: (state, action) => {
      state.languages.list = action.payload;
    },
    setCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
    setloginEnabled: (state, action) => {
      state.featureFlags.loginEnabled = action.payload;
    },
    setNewsletterEnabled: (state, action) => {
      state.featureFlags.newsletterEnabled = action.payload;
    },
    setHighestAgentCommissionEnabled: (state, action) => {
      state.featureFlags.highestAgentCommissionEnabled = action.payload;
    },
    setStaticTranslations: (state, action) => {
      state.translations[action.payload.language] = action.payload.translations;
    },
    setTimezone: (state, action) => {
      state.timezone = action.payload;
    },
  },
});

export const {
  setCountryCode,
  setCurrencies,
  setCurrencySelectedByUser,
  setCurrentCurrency,
  setCurrentLanguage,
  setExchanges,
  setIpAddressCountryCode,
  setLanguageList,
  setloginEnabled,
  setNewsletterEnabled,
  setHighestAgentCommissionEnabled,
  setStaticTranslations,
  setTimezone,
} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;

export const fetchLanguages = (axios) => async (dispatch) => {
  try {
    const res = await axios.get('/available-languages');
    dispatch(setLanguageList(res?.data));
  } catch (e) {
    console.error(e);
  }
};

export const fetchExchanges = (axios) => async (dispatch) => {
  try {
    const res = await axios.get('/exchanges');
    dispatch(setExchanges(res?.data));
  } catch (e) {
    console.error(e);
  }
};

export const fetchStaticCnTranslations = (axios) => async (dispatch) => {
  try {
    const res = await axios.get(`static-translations`);
    dispatch(
      setStaticTranslations({ language: 'zh-cn', translations: res?.data })
    );
  } catch (e) {
    console.error(e);
  }
};
