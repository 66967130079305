import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { ReactComponent as BedIcon } from '../../../../../assets/images/icons/bedIcon.svg';
import useTranslate from '../../../../../hooks/useTranslate/useTranslate';
import Icon from './Icon';

const RoomRateDetails = ({
  rateContent,
  shortDescription,
  longDescription,
  name,
}) => {
  const { t } = useTranslate();
  const title =
    name === 'Room Rate' ? (
      <div className="my-2 fw-600 d-flex">
        <BedIcon style={{ marginRight: 15 }} />
        {t('Accommodation Only')}
      </div>
    ) : null;

  return (
    <>
      <div className="d-flex align-items-center">
        {rateContent.mealPlanList ? (
          <div style={{ margin: '0 10px 5px 0' }}>
            <Icon rateContent={rateContent} />
          </div>
        ) : null}

        {rateContent.mealPlanList ? (
          <div className="my-2 fw-600">{rateContent.mealPlanList[0].name}</div>
        ) : (
          title
        )}
      </div>
      <ul className="my-2 diamond-list">
        <div>{parse(shortDescription)}</div>
      </ul>
      {longDescription && (
        <ul className="my-2 diamond-list">
          <div>{parse(longDescription)}</div>
        </ul>
      )}
    </>
  );
};

RoomRateDetails.propTypes = {
  rateContent: PropTypes.shape({
    mealPlanList: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  }),
  shortDescription: PropTypes.string,
  longDescription: PropTypes.string,
  name: PropTypes.string,
};

export default RoomRateDetails;
