import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHINA_VERSION } from '../../globals';
import { fetchReach5Profile } from '../../redux/slices/userSlice/userSlice';
import useAxios from '../useAxios/useAxios';

const useCheckReach5Session = () => {
  const dispatch = useDispatch();
  const axios = useAxios();
  const accessToken = useSelector((state) => state.user.accessToken);
  const userProfile = useSelector((state) => state.user.profile);

  useEffect(() => {
    if (accessToken && !userProfile) {
      dispatch(fetchReach5Profile(accessToken, axios));
    }
  }, [accessToken, userProfile, dispatch, axios]);
};

const noopHook = () => {};

export default CHINA_VERSION ? noopHook : useCheckReach5Session;
