import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { createRef, useCallback, useRef } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, Form } from 'reactstrap';
import Accordian from '../../../../components/Accordian/Accordian';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import { bookingStatuses } from '../../../../redux/slices/bookingsSlice/bookingsSlice';
import GuestsCount from './GuestsCount';
import styles from './GuestsField.module.css';

const { CONFIRMED } = bookingStatuses;

const GuestsField = ({ onClose, mobile, onDone, productCode }) => {
  const { t } = useTranslate();
  const guestsPerRoomLastId = useRef(0);
  const { getValues: getParentFormValues, setValue: setParentFormValue } =
    useFormContext();

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      guestsPerRoom: getParentFormValues('guestsPerRoom').reduce(
        (prev, curr) => ({ ...prev, [guestsPerRoomLastId.current++]: curr }),
        {}
      ),
    },
  });

  const currentHotel = useSelector(
    (state) => state.belmond.hotels[productCode]
  );

  const editingConfirmedBooking =
    useSelector((state) => state.bookings.list[0]?.status) === CONFIRMED;

  const maximumNumberOfRooms = currentHotel?.totalRooms > 49 ? 5 : 3;
  const maxNumberOfAdults = currentHotel?.maximumNumberOfGuests.adults;
  const maxNumberOfChildren = currentHotel?.maximumNumberOfGuests.children;

  const guestsPerRoom = form.watch('guestsPerRoom');
  const guestsPerRoomKeys = Object.keys(guestsPerRoom);

  const accordiansRef = useRef({});
  accordiansRef.current = guestsPerRoomKeys.reduce(
    (prev, key) => ({
      ...prev,
      [key]: accordiansRef.current[key] || createRef(),
    }),
    {}
  );

  const validateForm = useCallback(() => {
    form.setError('guestsPerRoom', null);
    let valid = true;
    guestsPerRoomKeys.forEach((key) => {
      const room = guestsPerRoom[key];

      if (room.numAdults < 1) {
        form.setError(`guestsPerRoom[${key}].numAdults`, {
          message: t('Number of adults must be at least 1.'),
        });
        valid = false;
        accordiansRef.current[key].current?.setOpen(true);
      }

      // check that all children ages are selected
      if (room.numChildren > 0) {
        room.children.forEach((age, j) => {
          if (!age) {
            form.setError(`guestsPerRoom[${key}].children[${j}]`, {
              //TODO HOW TO HANDLE THIS TRANSLATION
              message: `${t('Please select child')} ${j + 1} ${t('age')}.`,
            });
            valid = false;
            accordiansRef.current[key].current?.setOpen(true);
          }
        });
      }
    });
    return valid;
  }, [form, guestsPerRoomKeys, guestsPerRoom, t]);

  const handleDoneButtonClick = useCallback(() => {
    const valid = validateForm();

    if (valid) {
      const newGuestsPerRoom = Object.values(guestsPerRoom).map(
        (roomGuests) => ({
          ...roomGuests,
          children: roomGuests.children?.slice(0, roomGuests.numChildren),
        })
      );

      const sameGuestsPerRoom = isEqual(
        getParentFormValues('guestsPerRoom'),
        newGuestsPerRoom
      );

      setParentFormValue('guestsPerRoom', newGuestsPerRoom, {
        shouldDirty: true,
      });

      onDone(!sameGuestsPerRoom);
    }
  }, [
    validateForm,
    guestsPerRoom,
    getParentFormValues,
    setParentFormValue,
    onDone,
  ]);

  const handleAddRoomButtonClick = useCallback(
    (e) => {
      e.stopPropagation();

      const newGuestsPerRoom = {
        ...guestsPerRoom,
        [guestsPerRoomLastId.current++]: {
          numAdults: 2,
          children: [],
        },
      };
      guestsPerRoomKeys.forEach((key) =>
        accordiansRef.current[key].current?.setOpen(false)
      );
      form.setValue('guestsPerRoom', newGuestsPerRoom, {
        shouldValidate: false,
      });
    },
    [guestsPerRoom, guestsPerRoomKeys, form]
  );

  const handleAccordianRemove = useCallback(
    (e, guestsPerRoomKey) => {
      e.stopPropagation();
      const newGuestsPerRoom = { ...guestsPerRoom };
      delete newGuestsPerRoom[guestsPerRoomKey];
      form.setValue('guestsPerRoom', newGuestsPerRoom);
      form.setError('guestsPerRoom', null);
    },
    [guestsPerRoom, form]
  );

  return (
    <Form className={styles.GuestField__container} noValidate>
      <FormProvider {...form}>
        <div className="d-flex flex-column w-100 mb-3 ">
          {guestsPerRoomKeys.length > 1 ? (
            guestsPerRoomKeys.map((key, i) => (
              <Accordian
                key={`guest-count-accordian-${key}`}
                ref={accordiansRef.current[key]}
                title={`${t('Room')} ${i + 1}`}
                defaultOpen={i === guestsPerRoomKeys.length - 1}
                onRemove={(e) => handleAccordianRemove(e, key)}
              >
                <GuestsCount
                  guestsPerRoomKey={key}
                  maxAdults={maxNumberOfAdults}
                  maxChildren={maxNumberOfChildren}
                />
              </Accordian>
            ))
          ) : (
            <GuestsCount
              guestsPerRoomKey={guestsPerRoomKeys[0]}
              maxAdults={maxNumberOfAdults}
              maxChildren={maxNumberOfChildren}
            />
          )}
        </div>
      </FormProvider>
      {guestsPerRoomKeys.length < maximumNumberOfRooms &&
        !editingConfirmedBooking && (
          <div className="d-flex justify-content-center">
            <Button
              className="button transparent bold"
              aria-label="Add Another Room"
              onClick={handleAddRoomButtonClick}
            >
              {t('Add Another Room')}
            </Button>
          </div>
        )}
      <div
        className={`d-flex align-items-center justify-content-around mt-3 ${
          mobile && 'flex-column-reverse'
        }`}
        data-testid="action-buttons"
      >
        <Button
          className="hoverEffectButton"
          onClick={onClose}
          aria-label="Close guests picker"
          type="button"
        >
          {t('Cancel')}
        </Button>
        <Button
          className="button"
          aria-label="Apply changes"
          type="button"
          onClick={handleDoneButtonClick}
        >
          {t('Done')}
        </Button>
      </div>
    </Form>
  );
};

GuestsField.propTypes = {
  onClose: PropTypes.func,
  mobile: PropTypes.bool,
  onDone: PropTypes.func,
  productCode: PropTypes.string,
};

export default GuestsField;
