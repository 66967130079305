import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { CHINA_VERSION } from '../../globals';
import useGoogleMapsLookup from '../../hooks/useGoogleMapsLookup/useGoogleMapsLookup';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import InputComponent from '../InputComponent/InputComponent';
import Label from '../Label/Label';
import SelectComponent from '../SelectComponent/SelectComponent';
import styles from './AddressForm.module.css';
import countries from './Countries.json';

const AddressForm = ({ guestFormKey, disabled }) => {
  const { t } = useTranslate();
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const inputRef = useRef();
  const [placeValues, googleMapsLoaded] = useGoogleMapsLookup(
    !CHINA_VERSION ? inputRef : null
  );

  useEffect(() => {
    if (placeValues) {
      Object.entries(placeValues).forEach(([key, value]) => {
        setValue(`bookingsGuestValues[${guestFormKey}].address.${key}`, value, {
          shouldValidate: true,
        });
      });
      setValue(`bookingsGuestValues[${guestFormKey}].find`, '');
    }
  }, [placeValues, setValue, guestFormKey]);

  return (
    <>
      {!CHINA_VERSION && (
        <Row className="mb-3">
          <Col md="6">
            {/* we have a space in placeholder in order to keep the label inside the input field  */}
            {googleMapsLoaded && (
              <InputComponent
                ref={inputRef}
                name={`bookingsGuestValues[${guestFormKey}].find`}
                control={control}
                errors={errors}
                placeholder=" "
                label={
                  <span style={{ fontWeight: '500' }}>
                    {t('Find Your Address')}
                  </span>
                }
                autoComplete="off"
                extraClassName={styles.AddressForm__icon}
                disabled={disabled}
              />
            )}
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <Col md="6">
          <InputComponent
            name={`bookingsGuestValues[${guestFormKey}].address.address`}
            control={control}
            rules={{ required: t('Address cannot be blank') }}
            errors={errors}
            placeholder=" "
            label={<Label text={t('Address')} required />}
            autoComplete="street-address"
            disabled={disabled}
          />
        </Col>
        <Col md="6">
          <InputComponent
            name={`bookingsGuestValues[${guestFormKey}].address.city`}
            control={control}
            rules={{ required: t('City cannot be blank') }}
            errors={errors}
            placeholder=" "
            label={<Label text={t('City')} required />}
            autoComplete="address-level2"
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="6">
          <InputComponent
            name={`bookingsGuestValues[${guestFormKey}].address.state`}
            control={control}
            errors={errors}
            placeholder=" "
            label={<Label text={t('State / Province')} />}
            autoComplete="address-level1"
            disabled={disabled}
          />
        </Col>
        <Col md="6">
          <SelectComponent
            name={`bookingsGuestValues[${guestFormKey}].address.countryCode`}
            control={control}
            label={<Label text={t('Country / Region')} required />}
            setValue={setValue}
            options={countries}
            watch={watch}
            errors={errors}
            rules={{ required: t('Country / Region cannot be blank') }}
            autoComplete="country"
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <InputComponent
            name={`bookingsGuestValues[${guestFormKey}].address.postalCode`}
            control={control}
            rules={{ required: t('Zip / Postal Code  cannot be blank') }}
            errors={errors}
            placeholder=" "
            label={<Label text={t('Zip / Postal Code')} required />}
            autoComplete="postal-code"
            disabled={disabled}
          />
        </Col>
      </Row>
    </>
  );
};
AddressForm.propTypes = {
  guestFormKey: PropTypes.string,
  disabled: PropTypes.bool,
};

export default AddressForm;
