import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as ErrorCrossIcon } from '../../../../assets/images/icons/ErrorCross.svg';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import bookingConfirmationMessageStyles from '../BookingConfirmationMessage/BookingConfirmationMessage.module.css';
import styles from './FailedBookingsMessage.module.css';

const FailedBookingsMessage = ({ bookings }) => {
  const { t } = useTranslate();
  const hotels = useSelector((state) => state.belmond.hotels);
  return (
    <div
      data-testid="failed-bookings-message"
      className={`${bookingConfirmationMessageStyles.BookingConfirmationMessage__rowContainer} ${styles.FailedBookingsMessage__borderColor} d-print-none`}
    >
      <div>
        <div className="d-flex align-items-center">
          <ErrorCrossIcon style={{ marginRight: 10, minWidth: 20 }} />
          <div
            className={
              bookingConfirmationMessageStyles.BookingConfirmationMessage__message
            }
            aria-live="polite"
          >
            {t(
              'We are sorry, although some bookings has been created, others have not.'
            )}{' '}
            {t('Please try to book the failed ones again or contact us.')}
            <div
              className={
                bookingConfirmationMessageStyles.BookingConfirmationMessage__messageEmail
              }
            >
              {bookings.map(({ booking, error }, i) => (
                <span key={`booking-error-${i}`}>
                  - <b>{hotels[booking.productCode].shortName}:</b>{' '}
                  {t('Check in')}: {booking.checkIn} {t('Check out')}:{' '}
                  {booking.checkOut}, {booking.numAdults} {t('adults')}.{' '}
                  <b>{t('Reason')}:</b>{' '}
                  {error === 'NO_AVAILABILITY'
                    ? t('No availability')
                    : t('Unknown error')}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FailedBookingsMessage.propTypes = {
  bookings: PropTypes.arrayOf(PropTypes.object),
};

export default FailedBookingsMessage;
