import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FormGroup, Input, Label } from 'reactstrap';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import styles from './SelectComponent.module.css';

const SelectComponent = ({
  name,
  control,
  options,
  label,
  setValue,
  watch,
  errors,
  rules,
  autoComplete,
  disabled,
  inputClassName,
  number = false,
  placeholderOption,
}) => {
  const { t } = useTranslate();
  const currentValue = watch(name);

  return (
    <FormGroup className={styles.SelectComponent__formGroupSelect}>
      <div className="position-relative">
        <Label
          className={`${styles.SelectComponent__label} ${
            (currentValue || currentValue === 0) &&
            styles.SelectComponent__prefixFloat
          }`}
          for={`${name}ID`}
        >
          {label}
        </Label>
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue=""
          render={({ field }) => (
            <Input
              type="select"
              className={`${styles.SelectComponent__bbeFormSelect} ${inputClassName}`}
              {...field}
              onChange={(e) => {
                const value = number
                  ? parseInt(e.target.value)
                  : e.target.value;
                setValue(name, value);
                field.onChange(value);
              }}
              ref={field.ref}
              aria-label={name}
              id={`${name}ID`}
              data-testid="input"
              aria-describedby={`${name}-error`}
              autoComplete={autoComplete}
              disabled={disabled}
            >
              <option value="" tabIndex={-1} disabled>
                {placeholderOption}
              </option>
              {options.map((data) => (
                <option key={data.code} value={data.code} tabIndex={-1}>
                  {data.option}
                </option>
              ))}
            </Input>
          )}
        />

        <span className={styles.SelectComponent__prefixArrow}></span>
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <div
            className={styles.SelectComponent__errorText}
            id={`${name}-error`}
          >
            {t('Error')}: {message}
          </div>
        )}
      />
    </FormGroup>
  );
};
SelectComponent.propTypes = {
  control: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  options: PropTypes.array,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  errors: PropTypes.object,
  rules: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  autoComplete: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  inputClassName: PropTypes.string,
  number: PropTypes.bool,
  placeholderOption: PropTypes.string,
};

export default SelectComponent;
