import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Col, Form, Modal, ModalBody, Row } from 'reactstrap';
import { ReactComponent as LinkOutIcon } from '../../assets/images/icons/LinkOut.svg';
import useAxios from '../../hooks/useAxios/useAxios';
import useMessage from '../../hooks/useMessage/useMessage';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import DatePicker from '../DatePicker/DatePicker';
import InputComponent from '../InputComponent/InputComponent';
import LoadingButton from '../LoadingButton/LoadingButton';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';
import SelectComponent from '../SelectComponent/SelectComponent';
import styles from './FeedbackModal.module.css';

const FeedbackModal = ({ onClose, productCode, open }) => {
  const {
    t,
    constants: {
      errorMessage,
      prefixOptions,
      formRules: { emailRule },
    },
  } = useTranslate();

  const axios = useAxios();

  const categoryOptions = [
    {
      option: t('Feedback about my Belmond experience'),
      code: 'Feedback about my Belmond experience',
    },
    {
      option: t('Question regarding a specific Belmond product'),
      code: 'Question regarding a specific Belmond product',
    },
    {
      option: t('Help with the website'),
      code: 'Help with the website',
    },
    {
      option: t('General enquiry'),
      code: 'General enquiry',
    },
    {
      option: t('Other'),
      code: 'Other',
    },
  ];

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    getValues,
  } = useForm({
    mode: 'all',
  });
  const [Message, setMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);

  const hotel = useSelector((state) => state.belmond.hotels[productCode]);
  const hotels = useSelector((state) => state.belmond.hotels);
  const hotelValues = useMemo(() => Object.values(hotels), [hotels]);

  const handleSubmitForm = async (data) => {
    setLoading(true);
    const body = {
      feedbackMessage: data.textBoxFeedback,
      url: window.location.href,
      category: data.categoryFeedback,
      title: data.titleFeedback,
      firstname: data.firstNameFeedback,
      lastname: data.lastNameFeedback,
      email: data.emailFeedback,
      phone: data.phoneFeedback,
      productName: data.productFeedback,
      checkInDate: data.checkInDate,
      checkOutDate: data.checkOutDate,
    };

    try {
      await axios.post('/share-your-feedback', body);
      setFeedbackSent(true);
    } catch {
      setMessage(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      onClosed={() => setFeedbackSent(false)}
      toggle={onClose}
      isOpen={open}
      contentClassName={styles.FeedbackModal__modalWrapper}
      external={
        <ModalCloseButton
          className={styles.FeedbackModal__closeButton}
          onClose={onClose}
        />
      }
      scrollable
    >
      <ModalBody className={styles.FeedbackModal__body}>
        <h2 className="text-center text-uppercase">
          {t('Guest Feedback Form')}
        </h2>
        {!feedbackSent && (
          <Form onSubmit={handleSubmit(handleSubmitForm)} noValidate>
            <div className="mb-5 text-center">
              <div className={styles.FeedbackModal__lightFont}>
                {t('We Would Like To Hear About Your Experience With Us')}
              </div>
            </div>
            <div className={styles.FeedbackModal__subHeadingText}>
              {t('Category')}
            </div>
            <Row className="my-2">
              <Col xs="12" sm="4">
                <SelectComponent
                  name="categoryFeedback"
                  control={control}
                  label={t('Please select...')}
                  setValue={setValue}
                  options={categoryOptions}
                  watch={watch}
                  errors={errors}
                  inputClassName={styles.FeedbackModal__fitContent}
                />
              </Col>
            </Row>
            <div className={styles.FeedbackModal__subHeadingText}>
              {t('Personal Details')}
            </div>
            <Row className="my-2">
              <Col xs="12" sm="6" xl="4">
                <SelectComponent
                  name="titleFeedback"
                  control={control}
                  label={t('Title')}
                  setValue={setValue}
                  options={prefixOptions}
                  watch={watch}
                  errors={errors}
                  autoComplete="honorific-prefix"
                />
              </Col>
              <Col xs="12" sm="6" xl="4">
                <InputComponent
                  name="firstNameFeedback"
                  control={control}
                  rules={{ required: t('First name is required') }}
                  errors={errors}
                  label={
                    <>
                      {t('First Name')} <span>*</span>
                    </>
                  }
                  autoComplete="given-name"
                  placeholder=" "
                />
              </Col>
              <Col xs="12" sm="6" xl="4">
                <InputComponent
                  name="lastNameFeedback"
                  control={control}
                  rules={{ required: t('Last name is required') }}
                  errors={errors}
                  label={
                    <>
                      {t('Last Name')} <span>*</span>
                    </>
                  }
                  autoComplete="last-name"
                  placeholder=" "
                />
              </Col>
            </Row>
            <Row className="my-2">
              <Col xs="12" sm="6" xl="4">
                <InputComponent
                  name="emailFeedback"
                  control={control}
                  rules={emailRule}
                  errors={errors}
                  passivePlaceholder="alex@example.com"
                  label={
                    <>
                      {t('Email')} <span>*</span>
                    </>
                  }
                  autoComplete="email"
                />
              </Col>
              <Col xs="12" sm="6" xl="4">
                <InputComponent
                  name="repeatEmailFeedback"
                  control={control}
                  rules={{
                    pattern: {
                      message: t('Please provide a valid email address'),
                      value:
                        /^[A-Z0-9._%+-]+@[A-Z0-9-]+(?:\.[A-Z0-9]+)*\.[A-Z]{2,63}$/i,
                    },
                    required: t('Email is required'),
                    validate: (value) =>
                      value === getValues('emailFeedback') ||
                      t('The emails do not match'),
                  }}
                  errors={errors}
                  passivePlaceholder="alex@example.com"
                  label={
                    <>
                      {t('Confirm email')} <span>*</span>
                    </>
                  }
                  autoComplete="email"
                />
              </Col>
              <Col xs="12" sm="6" xl="4">
                <InputComponent
                  name="phoneFeedback"
                  control={control}
                  rules={{
                    pattern: {
                      message: t('Please provide a valid phone number'),
                      value: /^\+?[0-9]*$/,
                    },
                    required: t('Phone number is required'),
                  }}
                  errors={errors}
                  label={
                    <>
                      {t('Telephone')} <span>*</span>
                    </>
                  }
                  autoComplete="tel"
                  placeholder=" "
                />
              </Col>
            </Row>
            <div className={styles.FeedbackModal__subHeadingText}>
              {t('Booking Details')}
            </div>
            <Row className="my-2">
              <Col xs="12" sm="6" xl="4">
                <SelectComponent
                  name="productFeedback"
                  control={control}
                  label={hotel ? hotel.name : t('Please select...')}
                  setValue={setValue}
                  options={hotelValues.map(({ name }) => ({
                    option: name,
                    code: name,
                  }))}
                  watch={watch}
                  errors={errors}
                  disabled={!!hotel?.productCode}
                  autoComplete="honorific-prefix"
                />
              </Col>
              <Col xs="12" sm="6" xl="4">
                <DatePicker
                  name="checkInDate"
                  control={control}
                  label={t('Check In Date')}
                  setValue={setValue}
                  watch={watch}
                  errors={errors}
                />
              </Col>
              <Col xs="12" sm="6" xl="4">
                <DatePicker
                  label={t('Check Out Date')}
                  name="checkOutDate"
                  control={control}
                  setValue={setValue}
                  watch={watch}
                  errors={errors}
                />
              </Col>
            </Row>
            <div className={styles.FeedbackModal__subHeadingText}>
              {t('Your Feedback')}
            </div>
            <div className="my-4">
              <Controller
                name="textBoxFeedback"
                control={control}
                render={({ field }) => (
                  <textarea
                    type="text"
                    maxLength="200"
                    rows={1}
                    style={{ width: '100%' }}
                    className={styles.FeedbackModal__textBox}
                    {...field}
                    placeholder={t('Share Your Experience With Us')}
                    aria-label="textBoxFeedback"
                  />
                )}
              />
            </div>
            <div className={styles.FeedbackModal__lightFont}>
              {t(
                'For further information about how we use your data, please see our'
              )}{' '}
              <a
                className={styles.FeedbackModal__link}
                href="https://www.belmond.com/privacy-policy"
                rel="noreferrer"
                target="_blank"
              >
                {t('privacy policy')}
                <LinkOutIcon className={styles.FeedbackModal__icon} />
              </a>
            </div>
            <div>
              <LoadingButton
                className="button transparent mt-4"
                ariaLabel="Submit feedback"
                type="submit"
                loading={loading}
              >
                {t('Submit')}
              </LoadingButton>
            </div>
          </Form>
        )}
        {feedbackSent && (
          <div className="text-center">
            <div>
              {getValues('firstNameFeedback')}, {t('thank you')}
            </div>
            <div>{t('for your feedback')}</div>
          </div>
        )}
        <div className="my-3">
          <Message />
        </div>
      </ModalBody>
    </Modal>
  );
};

FeedbackModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  productCode: PropTypes.string,
};

export default FeedbackModal;
