const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const request = () =>
  new Promise((res) => {
    if (!global.geoip2) {
      console.error('geoip2 is not available yet.');
      return res(null);
    }

    geoip2.country(
      ({ country }) => {
        res(country.iso_code);
      },
      (error) => {
        console.error(error);
        res(null);
      }
    );
  });

const getCountryCode = async (retries = MAX_RETRIES) => {
  try {
    const countryCode = await request();
    if (countryCode) {
      return countryCode;
    } else if (retries > 0) {
      await wait(RETRY_DELAY);
      return getCountryCode(retries - 1);
    } else {
      console.error('Max retries reached. Unable to fetch country code.');
      return 'GB';
    }
  } catch (error) {
    console.error('Error fetching country code:', error);
    return 'GB';
  }
};

export default getCountryCode;
