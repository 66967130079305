import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

const useTranslate = () => {
  const currentLanguage = useSelector(
    (state) => state.appSettings.languages.current
  );

  const staticTranslations = useSelector(
    (state) => state.appSettings.translations
  );

  const t = useCallback(
    (text) => staticTranslations?.[currentLanguage]?.[text] || text,
    [currentLanguage, staticTranslations]
  );

  const constants = useMemo(
    () => ({
      errorMessage: `${t('Oops!')} ${t('Something went wrong.')} ${t(
        'Please refresh your browser or try again later.'
      )}`,
      prefixOptions: [
        {
          option: t('Dr.'),
          code: 'Dr',
        },
        {
          option: t('Prof.'),
          code: 'Prof',
        },
        {
          option: t('Miss.'),
          code: 'Miss',
        },
        {
          option: t('Mx.'),
          code: 'Mx',
        },
        {
          option: t('Mrs.'),
          code: 'Mrs',
        },
        {
          option: t('Ms.'),
          code: 'Ms',
        },
        {
          option: t('Mr.'),
          code: 'Mr',
        },
      ],
      formRules: {
        emailRule: {
          pattern: {
            message: t('Error: Please provide a valid email address'),
            value: /^[A-Z0-9._%+-]+@[A-Z0-9-]+(?:\.[A-Z0-9]+)*\.[A-Z]{2,63}$/i,
          },
          required: t('Email is required'),
        },
      },
    }),
    [t]
  );

  return { t, constants };
};

export default useTranslate;
