import React from 'react';
import useTranslate from '../../hooks/useTranslate/useTranslate';

import styles from './RoomsNotMatchingFiltersMessage.module.css';

const RoomsNotMatchingFiltersMessage = () => {
  const { t } = useTranslate();
  return (
    <div
      className={styles.RoomsNotMatchingFiltersMessage__container}
      aria-label="There are no rooms matching the filters"
    >
      <div data-testid="message-for-unmatching-room-filters">
        {t(
          'There are no rooms matching the selected filters during these requested dates.'
        )}{' '}
        {t('Please try searching for different dates or change the filters.')}
      </div>
    </div>
  );
};

export default RoomsNotMatchingFiltersMessage;
