import { useSelector } from 'react-redux';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import ViewLoader from '../../components/ViewLoader/ViewLoader';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { SelectHotelLoader } from './components';

const loaders = {
  '/': <SelectHotelLoader />,
};

const scriptOnlyViews = ['/findreservation'];

const GeneralViewWrapper = () => {
  const { scriptsLoaded, metadataLoaded } = useOutletContext();
  const belmondPhone = useSelector((state) => state.belmond.phone);
  const { pathname } = useLocation();

  const isScriptOnlyView = scriptOnlyViews.includes(pathname);
  return (
    <div data-testid="general-view">
      <Header phone={belmondPhone} />
      {(scriptsLoaded && metadataLoaded) ||
      (isScriptOnlyView && scriptsLoaded) ? (
        <Outlet />
      ) : (
        loaders[pathname] ?? <ViewLoader />
      )}

      <Footer
        address="4B Victoria House, Bloomsbury Square, London WC1B 4DA"
        phone={belmondPhone}
        facebookLink="https://www.facebook.com/belmondtravel"
        instagramLink="https://www.instagram.com/belmond"
      />
    </div>
  );
};

export default GeneralViewWrapper;
