import PropTypes from 'prop-types';
import React from 'react';
import useTotalPrice from '../../../../hooks/useTotalPrice/useTotalPrice';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';

const TotalPriceBox = ({ bookings }) => {
  const { t } = useTranslate();
  const { totalPrice, differentCurrencies } = useTotalPrice(bookings);

  if (differentCurrencies) return null;

  return (
    <div className="d-flex flex-column">
      <div
        className="d-flex justify-content-between mt-4"
        style={{
          border: ' 1px solid #dbd9d9',
          fontWeight: 600,
          padding: '25px 30px',
        }}
      >
        <div className="text-uppercase">{t('Total')}</div>
        <div>
          <div className="d-flex justify-content-end">{totalPrice}</div>
          <div style={{ fontWeight: 400 }}>({t('tax included')})</div>
        </div>
      </div>
    </div>
  );
};

TotalPriceBox.propTypes = {
  bookings: PropTypes.array.isRequired,
};

export default TotalPriceBox;
