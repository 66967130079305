import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, Col, Form, Row } from 'reactstrap';
import usePrevious from '../../../../../../hooks/usePrevious/usePrevious';
import useTranslate from '../../../../../../hooks/useTranslate/useTranslate';
import FilterSection from '../FilterSection/FilterSection';
import PriceRangeSlider from '../PriceRangeSlider/PriceRangeSlider';
import styles from './FilterOptions.module.css';

const FilterOptions = ({
  availableFilters,
  lowerPriceAvailable,
  higherPriceAvailable,
  onApply,
  onClearAll,
  baseCurrency,
}) => {
  const { t } = useTranslate();
  const { control, setValue } = useFormContext();
  const selectedCurrency = useSelector(
    (state) => state.appSettings.currencies.current
  );

  const watchedFilters = useWatch({
    control,
    name: 'filters',
  });

  const prevWatchedFilters = usePrevious(cloneDeep(watchedFilters));
  const prevSelectedCurrency = usePrevious(selectedCurrency);

  // if currency changes, reset the price range
  useEffect(() => {
    if (prevSelectedCurrency !== selectedCurrency) {
      setValue('filters.minPrice', lowerPriceAvailable);
      setValue('filters.maxPrice', higherPriceAvailable);
    }
  }, [
    higherPriceAvailable,
    lowerPriceAvailable,
    prevSelectedCurrency,
    selectedCurrency,
    setValue,
  ]);

  useEffect(() => {
    if (
      !isEqual(prevWatchedFilters, watchedFilters) ||
      prevSelectedCurrency !== selectedCurrency
    ) {
      onApply(watchedFilters);
    }
  }, [
    prevWatchedFilters,
    watchedFilters,
    onApply,
    selectedCurrency,
    prevSelectedCurrency,
  ]);

  const isFiltered =
    watchedFilters?.roomView.length > 0 ||
    watchedFilters?.roomCategory.length > 0 ||
    watchedFilters?.roomType.length > 0 ||
    watchedFilters?.minPrice !== lowerPriceAvailable ||
    watchedFilters?.maxPrice !== higherPriceAvailable;

  return (
    <div className={styles.FilterOptions__container}>
      <Form>
        <Row className={styles.FilterOptions__row}>
          <Col md="12">
            <FilterSection
              title={t('Room View')}
              name="filters.roomView"
              values={availableFilters?.views}
            />
          </Col>
        </Row>
        <Row className={styles.FilterOptions__row}>
          <Col md="12">
            <FilterSection
              title={t('Room Category')}
              name="filters.roomCategory"
              values={availableFilters?.roomCategories}
            />
          </Col>
        </Row>
        <Row className={styles.FilterOptions__row}>
          <Col md="12">
            <FilterSection
              title={t('Room Type')}
              name="filters.roomType"
              values={availableFilters?.roomTypes}
            />
          </Col>
        </Row>

        <Row className={styles.FilterOptions__row}>
          <Col md="12">
            <PriceRangeSlider
              lowerPriceAvailable={lowerPriceAvailable}
              higherPriceAvailable={higherPriceAvailable}
              baseCurrency={baseCurrency}
            />
          </Col>
        </Row>

        <div className={styles.FilterOptions__buttons}>
          {isFiltered && (
            <Button
              className="hoverEffectButton mx-4 text-nowrap text-uppercase"
              aria-label="clear all filters"
              onClick={onClearAll}
            >
              {t('Clear All')}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};
FilterOptions.propTypes = {
  onApply: PropTypes.func,
  onClearAll: PropTypes.func,
  availableFilters: PropTypes.object,
  lowerPriceAvailable: PropTypes.number,
  higherPriceAvailable: PropTypes.number,
  baseCurrency: PropTypes.string,
};
export default FilterOptions;
