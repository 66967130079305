import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { ReactComponent as ArrowUpIcon } from '../../assets/images/icons/ArrowUp.svg';
import styles from './MobileBar.module.css';

const MobileBar = forwardRef(
  ({ children, barLeftContent, barRightContent, disabled }, ref) => {
    const [modalOpen, setModalOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      openModal: () => setModalOpen(true),
      closeModal: () => setModalOpen(false),
    }));

    useEffect(() => {
      if (modalOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }, [modalOpen]);

    return (
      <div className="fixed-bottom">
        {disabled && (
          <div
            className={styles.MobileBar__disabledOverlay}
            data-testid="disabled-overlay"
          ></div>
        )}
        <div className={styles.MobileBar__mobileBar}>
          <Modal
            isOpen={modalOpen}
            contentClassName={styles.MobileBar__modalWrapper}
            scrollable
            centered
          >
            <ModalBody>{children}</ModalBody>
          </Modal>
          <button
            className={styles.MobileBar__mobilePrice}
            onClick={() => setModalOpen(true)}
            aria-label="Open mobile bar"
            disabled={disabled}
          >
            <div>{barLeftContent}</div>
            <div className="d-flex align-items-center">
              {barRightContent} <ArrowUpIcon style={{ marginLeft: 16 }} />
            </div>
          </button>
        </div>
      </div>
    );
  }
);

MobileBar.propTypes = {
  children: PropTypes.node,
  barLeftContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  barRightContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
};

export default MobileBar;
