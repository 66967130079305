import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';

const NearbyProductMessage = ({ hotelName, multipleAvailableProperties }) => {
  const { t } = useTranslate();
  return (
    <div style={{ border: '1px solid black', padding: '15px' }}>
      <div
        style={{
          textTransform: 'uppercase',
          fontWeight: 600,
          lineHeight: 2,
        }}
      >
        {t('No Rooms Found')}
      </div>
      <div style={{ lineHeight: '22px' }}>
        {t('We apologize for the inconvenience.')}{' '}
        {t('There are no available rooms at')}{' '}
        <span className="bold">{hotelName}</span>{' '}
        {t(
          `matching your criteria, however you may be interested in our nearby ${
            multipleAvailableProperties ? 'properties' : 'property'
          } below.`
        )}
      </div>
    </div>
  );
};

NearbyProductMessage.propTypes = {
  hotelName: PropTypes.string,
  multipleAvailableProperties: PropTypes.bool,
};

export default NearbyProductMessage;
