import PropTypes from 'prop-types';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/icons/ArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/images/icons/ArrowUp.svg';
import styles from './SelectRoomMobilePicker.module.css';

const SelectRoomMobilePickerSection = ({
  onClick,
  title,
  children,
  open,
  isCalendar,
}) => {
  return (
    <div>
      <button
        className={styles.SelectRoomMobilePicker__section}
        style={{
          borderBottom: open ? '1px solid black' : '1px solid #dbd9d9',
        }}
        type="button"
        onClick={onClick}
        aria-expanded={open}
      >
        {title}
        <div>{open ? <ArrowUpIcon /> : <ArrowDownIcon />}</div>
      </button>
      {open && (
        <div
          aria-label={title}
          style={
            isCalendar
              ? {
                  zIndex: 2,
                  display: 'flex',
                  justifyContent: 'center',
                }
              : {}
          }
        >
          {children}
        </div>
      )}
    </div>
  );
};

SelectRoomMobilePickerSection.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  isCalendar: PropTypes.bool,
};

export default SelectRoomMobilePickerSection;
