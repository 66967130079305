import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import useTotalPrice from '../../hooks/useTotalPrice/useTotalPrice';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import { bookingsCartStatuses } from '../../redux/slices/bookingsSlice/bookingsSlice';
import BookingsCart from '../BookingsCart/BookingsCart';
import MobileBar from '../MobileBar/MobileBar';

const BookingsCartMobileBar = forwardRef(
  (
    {
      status,
      bookings,
      currentBookingId,
      error,
      pendingAddonsActions,
      addonsLoading,
      selectRoomForm,
      onRemoveAddon,
      onConfirmChangesAddons,
      onDiscardChangesAddons,
      disabled,
    },
    ref
  ) => {
    const { t } = useTranslate();
    const { totalPrice, differentCurrencies } = useTotalPrice(bookings);
    const mobileBarRef = useRef();

    const barRightContent = differentCurrencies ? t('View Prices') : totalPrice;
    useImperativeHandle(ref, () => ({
      openModal: () => mobileBarRef.current.openModal(),
      closeModal: () => mobileBarRef.current.closeModal(),
    }));

    return (
      <MobileBar
        ref={mobileBarRef}
        barLeftContent={t('My Itinerary')}
        barRightContent={barRightContent}
        disabled={disabled}
      >
        <BookingsCart
          status={status}
          bookings={bookings}
          currentBookingId={currentBookingId}
          pendingAddonsActions={pendingAddonsActions}
          onClose={() => mobileBarRef.current.closeModal()}
          error={error}
          addonsLoading={addonsLoading}
          selectRoomForm={selectRoomForm}
          onRemoveAddon={onRemoveAddon}
          onConfirmChangesAddons={onConfirmChangesAddons}
          onDiscardChangesAddons={onDiscardChangesAddons}
        />
      </MobileBar>
    );
  }
);

BookingsCartMobileBar.propTypes = {
  status: PropTypes.oneOf(Object.values(bookingsCartStatuses)),
  bookings: PropTypes.array,
  currentBookingId: PropTypes.string,
  pendingAddonsActions: PropTypes.object,
  error: PropTypes.string,
  addonsLoading: PropTypes.bool,
  selectRoomForm: PropTypes.object,
  onRemoveAddon: PropTypes.func,
  onConfirmChangesAddons: PropTypes.func,
  onDiscardChangesAddons: PropTypes.func,
  disabled: PropTypes.bool,
};

export default BookingsCartMobileBar;
