import PropTypes from 'prop-types';
import styles from './Label.module.css';

const Label = ({ text, required }) => (
  <>
    {text}
    {required && <span className={styles.Label__required}>*</span>}
  </>
);

Label.propTypes = {
  text: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default Label;
