const formatRoomName = (roomCategoryName, roomTypeName, promoCode) => {
  if (['COMP', 'DISC'].includes(promoCode)) {
    return roomTypeName;
  }
  const formattedRoomCategoryName = roomCategoryName.toLowerCase().endsWith('s')
    ? roomCategoryName.slice(0, -1)
    : roomCategoryName;

  const formattedRoomTypeName = roomTypeName ? ` - ${roomTypeName}` : '';

  return `${formattedRoomCategoryName}${formattedRoomTypeName}`;
};

export default formatRoomName;
