import { createBrowserRouter } from 'react-router-dom';
import Root from '../Root';
import GeneralViewWrapper from '../layout/GeneralViewWrapper/GeneralViewWrapper';
import SingleHotelViewWrapper from '../layout/SingleHotelViewWrapper/SingleHotelViewWrapper';
import BookingsConfirmation from '../views/BookingsConfirmation/BookingsConfirmation';
import ErrorView from '../views/ErrorView/ErrorView';
import FindReservation from '../views/FindYourReservation/FindReservation';
import GuestForm from '../views/GuestForm/GuestForm';
import LoginRedirect from '../views/LoginRedirect/LoginRedirect';
import Maintenance from '../views/Maintenance/Maintenance';
import SelectHotel from '../views/SelectHotel/SelectHotel';
import SelectRoom from '../views/SelectRoom/SelectRoom';
import { TcView } from '../views/TcView/TcView';
import ErrorBoundary from './components/ErrorBoundary';

const hotelViews = {
  element: (
    <ErrorBoundary>
      <SingleHotelViewWrapper />
    </ErrorBoundary>
  ),
  children: [
    {
      path: '/maintenance',
      element: <Maintenance />,
    },
    {
      path: '/select-room',
      element: <SelectRoom />,
    },
    {
      path: '/edit-room',
      element: <SelectRoom editing={true} />,
    },
    {
      path: '/checkout',
      element: <GuestForm />,
    },
    {
      path: '/confirmation',
      element: <BookingsConfirmation />,
    },
  ],
};

const nonHotelViews = {
  element: (
    <ErrorBoundary>
      <GeneralViewWrapper />
    </ErrorBoundary>
  ),
  children: [
    {
      path: '/',
      element: <SelectHotel />,
    },
    {
      path: '/findreservation',
      element: <FindReservation />,
    },
  ],
};

export default createBrowserRouter([
  {
    element: (
      <ErrorBoundary>
        <Root />
      </ErrorBoundary>
    ),
    errorElement: <ErrorView />,
    children: [
      hotelViews,
      nonHotelViews,
      { path: '/tcapp', element: <TcView /> },
      { path: '*', element: <ErrorView /> },
    ],
  },
  {
    element: (
      <ErrorBoundary>
        <LoginRedirect />
      </ErrorBoundary>
    ),
    errorElement: <ErrorView />,
    path: '/login/callback',
  },
]);
