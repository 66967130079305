import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button, Form } from 'reactstrap';
import client from '../../helpers/authClient';
import useMessage from '../../hooks/useMessage/useMessage';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import { setAuthTokens } from '../../redux/slices/userSlice/userSlice';
import InputComponent from '../InputComponent/InputComponent';
import LoadingButton from '../LoadingButton/LoadingButton';
import SocialLoginButtons from '../SocialLoginButtons/SocialLoginButtons';
import PasswordResetForm from './PasswordResetForm';
import styles from './SignInForm.module.css';

const SignInForm = ({ onSignUpBtnClick, onError, emailVerified }) => {
  const {
    t,
    constants: {
      errorMessage,
      formRules: { emailRule },
    },
  } = useTranslate();

  const dispatch = useDispatch();
  const [resetPassword, setResetPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Message, setMessage] = useMessage();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    mode: 'all',
  });

  const loginEmail = watch('loginEmail');
  const loginPassword = watch('loginPassword');

  const handleFormSubmit = async ({ loginEmail, loginPassword }) => {
    setLoading(true);
    try {
      const res = await client.loginWithPassword({
        email: loginEmail,
        password: loginPassword,
        auth: {
          redirectUri: `${window.location.origin}`,
          useWebMessage: true,
        },
      });

      if (res.idTokenPayload.emailVerified) {
        dispatch(setAuthTokens(res));
      }
    } catch (e) {
      setMessage(e.errorUserMsg || errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleSocialButtonsError = (errMessage) => {
    setMessage(errMessage);
    setLoading(false);
    onError();
  };

  return (
    <div className={styles.SignInForm__container}>
      {loading && <div className={styles.SignInForm__overlay}></div>}
      {resetPassword ? (
        <PasswordResetForm onCancel={() => setResetPassword(false)} />
      ) : (
        <>
          <div className={styles.SignInForm__header}>{t('LOG IN')}</div>
          <hr className={styles.SignInForm__line} />
          {emailVerified && (
            <div className="d-flex flex-column text-center mt-3">
              <div className="my-2">
                {t('Your account has been successfully verified.')}
              </div>
              {t('Please log in to access your profile.')}
            </div>
          )}
          <SocialLoginButtons
            onError={handleSocialButtonsError}
            onButtonsClick={() => setLoading(true)}
          />
          <div className={styles.SignInForm__dottedLine}></div>
          <div style={{ fontWeight: 500, textTransform: 'uppercase' }}>
            {t('or')}
          </div>
          <Form
            className="w-100"
            onSubmit={handleSubmit(handleFormSubmit)}
            noValidate
          >
            <div className="my-5">
              <label
                className={styles.SignInForm__label}
                htmlFor="loginEmailID"
              >
                {t('Email Address')}*
              </label>
              <InputComponent
                name="loginEmail"
                control={control}
                rules={emailRule}
                errors={errors}
                autoComplete="email"
                inputClassName={styles.SignInForm__input}
              />
            </div>
            <label
              className={styles.SignInForm__label}
              htmlFor="loginPasswordID"
            >
              {t('Password')}*
            </label>
            <InputComponent
              name="loginPassword"
              control={control}
              type="password"
              rules={{ required: 'Password is required' }}
              errors={errors}
              autoComplete="current-password"
              inputClassName={styles.SignInForm__input}
            />

            {/* reach5 errors */}
            <div data-testid="SignInForm--message">
              <Message />
            </div>
            <div className="mt-4">
              <button
                className={styles.SignInForm__textButton}
                type="button"
                onClick={() => setResetPassword(true)}
                aria-label="Recover your password"
              >
                {t("Don't remember your password?")}
              </button>
            </div>
            <div className="text-center my-5">
              <LoadingButton
                className="button"
                type="submit"
                ariaLabel="Log In"
                loading={loading}
                disabled={!(loginPassword && loginEmail)}
              >
                {t('Log In')}
              </LoadingButton>
            </div>
          </Form>
          <div className={styles.SignInForm__dottedLine}></div>
          <div className={styles.SignInForm__bold}>
            {t('Do not have an account?')}
          </div>
          <div className="d-flex m-3 justify-content-center">
            <Button
              onClick={onSignUpBtnClick}
              className="button transparent transparent-border"
              aria-label="Go to Sign Up"
            >
              {t('Sign Up')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
SignInForm.propTypes = {
  onSignUpBtnClick: PropTypes.func,
  onError: PropTypes.func,
  emailVerified: PropTypes.bool,
};
export default SignInForm;
