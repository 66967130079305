import propTypes from 'prop-types';
import React from 'react';
import { createSearchParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import usePrintPrice from '../../../../hooks/usePrintPrice/usePrintPrice';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from './NearbyProduct.module.css';

const NearbyProduct = ({
  price,
  name,
  location,
  code,
  currentCode,
  params,
  image,
}) => {
  const { t } = useTranslate();
  const { printPrice } = usePrintPrice(currentCode);

  const navigateToHotel = () => {
    window.open(
      `/select-room?productCode=${code}&${createSearchParams({
        ...params,
      }).toString()}`
    );
  };

  return (
    <div className={styles.NearbyProduct__container}>
      <div className={styles.NearbyProduct__imageContainer}>
        <img src={image} alt={`${code}`} />
      </div>
      <div className={styles.NearbyProduct__information}>
        <div className={styles.NearbyProduct__hotelContainer}>
          <div className={styles.NearbyProduct__hotelTitle}>{name}</div>
          <div className={styles.NearbyProduct__location}>{location}</div>
        </div>
        <div className={styles.NearbyProduct__priceContainer}>
          <div style={{ textAlign: 'right' }}>
            {t('From')}{' '}
            <span className={styles.NearbyProduct__price}>
              {printPrice(price, true)}
            </span>
          </div>
          <div className={styles.NearbyProduct__taxes}>
            {t('Per night including taxes and fees')}
          </div>
          <Button className="button" onClick={navigateToHotel}>
            {t('View Rates')}
          </Button>
        </div>
      </div>
    </div>
  );
};

NearbyProduct.propTypes = {
  price: propTypes.number,
  name: propTypes.string,
  location: propTypes.string,
  code: propTypes.string,
  params: propTypes.object,
  currentCode: propTypes.string,
  image: propTypes.string,
};

export default NearbyProduct;
