const pageCloseWarning = (e, type) => {
  e.preventDefault();
  // unlikely to show, but old browsers may show this message
  e.returnValue = `Please do not leave the page. If you do, any new/changed bookings will still be made, and you will recieve confirmaton email.`;
};

export const addPageCloseWarning = (type = 'create') => {
  window.addEventListener('beforeunload', pageCloseWarning);
};

export const clearPageCloseWarning = (type = 'create') => {
  window.removeEventListener('beforeunload', pageCloseWarning);
};
