import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { gtmBookingInteraction } from '../../gtm/events';
import useLocalisedMoment from '../../hooks/useLocalisedMoment/useLocalisedMoment';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import { setCurrentCalendarMonth } from '../../redux/slices/availabilitySlice/availabilitySlice';
import { RangeDatePicker } from '../../views/SelectRoom/components';
import GuestsField from '../../views/SelectRoom/components/GuestsField/GuestsField';
import SpecialCode from '../../views/SelectRoom/components/SpecialCode/SpecialCode';
import styles from './SelectRoomMobilePicker.module.css';
import SelectRoomMobilePickerSection from './SelectRoomMobilePickerSection';

const SelectRoomMobilePicker = ({
  onClose,
  productCode,
  onSubmit,
  activeSection,
}) => {
  const { t } = useTranslate();
  const moment = useLocalisedMoment();
  const dispatch = useDispatch();
  const {
    getValues,
    formState: { isDirty },
  } = useFormContext();

  const media = useSelector((state) => state.media.mediaUrls);

  const [guestsSectionOpen, setGuestsSectionOpen] = useState(false);
  const [calendarSectionOpen, setCalendarSectionOpen] = useState(false);
  const [specialCodesSectionOpen, setSpecialCodesSectionOpen] = useState(false);
  const [focus, setFocus] = useState(null);

  const handleCalendarSectionClick = () => {
    setFocus('startDate');
    setCalendarSectionOpen((prev) => !prev);
  };

  const handleGuestsFieldDone = () => {
    setGuestsSectionOpen(false);
    setCalendarSectionOpen(true);
    setFocus('startDate');
  };

  const handleSpecialCodesApply = () => {
    setSpecialCodesSectionOpen(false);
    setCalendarSectionOpen(true);
    setFocus('startDate');
  };

  const logoImage = media.find((mediaItem) =>
    mediaItem.includes(`logo_${productCode}`)
  );

  useEffect(() => {
    if (!focus) {
      setCalendarSectionOpen(false);
    }
  }, [focus]);

  useEffect(() => {
    if (activeSection === 'guests') {
      setGuestsSectionOpen(true);
    } else if (activeSection === 'calendar') {
      setFocus('startDate');
      setCalendarSectionOpen(true);
    }
  }, [activeSection]);

  const handleCalendarClose = useCallback(() => {
    const startDate = getValues('startDate');
    const formattedStartDate = moment(startDate).format('YYYY-MM');
    dispatch(
      setCurrentCalendarMonth({ productCode, month: formattedStartDate })
    );
  }, [dispatch, getValues, moment, productCode]);

  return (
    <div className={styles.SelectRoomMobilePicker__container}>
      <div className={styles.SelectRoomMobilePicker__main}>
        <div className={styles.SelectRoomMobilePicker__header}>
          <div>
            <img style={{ height: 45 }} src={logoImage} alt="belmond logo" />
          </div>
          <button
            className={styles.SelectRoomMobilePicker__close}
            onClick={onClose}
            data-testid="SelectRoomMobilePicker--close"
          ></button>
        </div>
        <hr className="bbe-hr" />
        <div className={styles.SelectRoomMobilePicker__body}>
          <SelectRoomMobilePickerSection
            title={t('Rooms & Guests')}
            open={guestsSectionOpen}
            onClick={() => setGuestsSectionOpen((prev) => !prev)}
          >
            <div className="my-3">
              <GuestsField
                onClose={() => setGuestsSectionOpen((prev) => !prev)}
                onDone={(guestsChanged) => {
                  guestsChanged && onSubmit();
                  handleGuestsFieldDone();
                  setGuestsSectionOpen(false);
                }}
                mobile
                productCode={productCode}
              />
            </div>
          </SelectRoomMobilePickerSection>
          <SelectRoomMobilePickerSection
            title={t('Dates')}
            open={calendarSectionOpen}
            onClick={handleCalendarSectionClick}
            isCalendar
          >
            <div className="my-4 d-flex justify-content-center">
              <RangeDatePicker
                focus={focus}
                onChangeFocus={setFocus}
                months={1}
                daySize={43}
                productCode={productCode}
                onClose={handleCalendarClose}
              />
            </div>
          </SelectRoomMobilePickerSection>
          <SelectRoomMobilePickerSection
            title={t('Special Code')}
            open={specialCodesSectionOpen}
            onClick={() => setSpecialCodesSectionOpen((prev) => !prev)}
          >
            <div className="mb-4">
              <SpecialCode
                productCode={productCode}
                onClose={() => setSpecialCodesSectionOpen(false)}
                onApply={handleSpecialCodesApply}
                onRemoveCode={handleSpecialCodesApply}
                mobile
              />
            </div>
          </SelectRoomMobilePickerSection>
          <div className="pb-5 pt-3">
            <Button
              // do this, as well as calling the submit fn in the parent component
              onClick={() => {
                gtmBookingInteraction('Search');
                onSubmit();
                onClose();
              }}
              className={`button ${isDirty ? '' : 'transparent'} my-2 w-100`}
              aria-label="Search rooms"
            >
              {t('Search')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
SelectRoomMobilePicker.propTypes = {
  onClose: PropTypes.func,
  productCode: PropTypes.string,
  onSubmit: PropTypes.func,
  activeSection: PropTypes.string,
};

export default SelectRoomMobilePicker;
