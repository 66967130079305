import client from '../../../helpers/authClient';

const initiateGoogleOneTap = async () => {
  try {
    await client.instantiateOneTap({
      redirectUri: window.location.href,
      responseType: 'token',
    });
  } catch (e) {
    console.log(e);
  }
};
export default initiateGoogleOneTap;
