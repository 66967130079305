import { ErrorMessage } from '@hookform/error-message';
import propTypes from 'prop-types';
import React from 'react';
import PhoneInputWithCountrySelect from 'react-phone-number-input/react-hook-form';
import 'react-phone-number-input/style.css';
import { useSelector } from 'react-redux';
import { Label } from 'reactstrap';
import styles from './PhoneInput.module.css';

const PhoneInput = ({
  control,
  errors,
  name,
  label,
  rules,
  disabled,
  hasError,
}) => {
  const countryCode = useSelector((state) => state.appSettings.countryCode);

  return (
    <div className="mb-3">
      <Label className={styles.PhoneInput__label}>{label}</Label>
      <PhoneInputWithCountrySelect
        defaultValue=""
        aria-label={name}
        international
        defaultCountry={countryCode}
        name={name}
        control={control}
        rules={rules}
        numberInputProps={{ className: styles.PhoneInput__input }}
        className={`${styles.PhoneInput__container} ${
          hasError ? styles.PhoneInput__error : ''
        }`}
        id={`${name}ID`}
        countrySelectProps={{
          className: styles.PhoneInput__countrySelect,
          tabIndex: -1,
        }}
        disabled={disabled}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <div
            className={styles.PhoneInput__errorText}
            id={`${name}-error`}
            data-testid={`${name}-error`}
          >
            {'Error'}: {message}
          </div>
        )}
      />
    </div>
  );
};

PhoneInput.propTypes = {
  control: propTypes.object,
  errors: propTypes.object,
  name: propTypes.string,
  label: propTypes.object,
  rules: propTypes.object,
  disabled: propTypes.bool,
  hasError: propTypes.bool,
};

export default PhoneInput;
