const getMinStayExclusions = (
  startDate,
  daysFromStart,
  minStayThroughValue,
  moment,
  ignoreFirstDay = false
) => {
  if (minStayThroughValue < 1) return [];
  const exclusions = [];

  for (
    let j = 0 + +ignoreFirstDay;
    j < minStayThroughValue - daysFromStart;
    j++
  ) {
    exclusions.push(
      moment(startDate)
        .add(j + daysFromStart, 'days')
        .format('YYYY-MM-DD')
    );
  }

  return exclusions;
};

export default getMinStayExclusions;
