import { BBE_STATUSES } from '../constants';

const isBBEStatusAllowed = (bbeStatus) => {
  const allowedStatuses = BBE_STATUSES[bbeStatus];
  if (!allowedStatuses) {
    return false;
  }
  return allowedStatuses.includes(process.env.REACT_APP_ENV);
};

export default isBBEStatusAllowed;
