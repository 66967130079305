import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as ClockIcon } from '../../../assets/images/icons/Clock.svg';
import formatTime from '../../../helpers/formatTime';
import { useScreenDetector } from '../../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../../hooks/useTranslate/useTranslate';

const BookingsCartTime = ({ productCode }) => {
  const { t } = useTranslate();
  const hotel = useSelector((state) => state.belmond.hotels[productCode]);
  const { isMobile } = useScreenDetector();

  const checkInTime = formatTime(hotel.checkInTime);
  const checkOutTime = formatTime(hotel.checkOutTime);

  return (
    <div className="d-flex flex-row justify-space-between my-4">
      {isMobile ? (
        <div className="d-flex align-items-center">
          <ClockIcon style={{ marginRight: 15 }} />
          <div className="d-flex flex-column">
            <div>
              {t('Check in from')} {checkInTime}
            </div>
            <div>
              {t('Check out by')} {checkOutTime}
            </div>
          </div>
        </div>
      ) : (
        <>
          <ClockIcon style={{ marginRight: 15 }} /> {t('Check in from')}{' '}
          {checkInTime} &ndash; {t('Check out by')} {checkOutTime}
        </>
      )}
    </div>
  );
};

BookingsCartTime.propTypes = {
  productCode: PropTypes.string.isRequired,
};

export default BookingsCartTime;
