import PropTypes from 'prop-types';
import React from 'react';
import usePrintPrice from '../../../hooks/usePrintPrice/usePrintPrice';
import styles from './TaxesAndFees.module.css';

const TaxesAndFeesItem = ({ charge, productCode, currencyCode }) => {
  const { printPrice } = usePrintPrice(productCode);
  const price = printPrice({
    value: charge.amount,
    baseCurrency: currencyCode,
  }).price;

  return (
    <div key={charge.code}>
      <span className={styles.TaxesAndFees__priceRow}>
        <span>{charge.name}</span>
        <span>{price}</span>
      </span>
    </div>
  );
};
TaxesAndFeesItem.propTypes = {
  charge: PropTypes.object,
  productCode: PropTypes.string,
  currencyCode: PropTypes.string.isRequired,
};

export default TaxesAndFeesItem;
