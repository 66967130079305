import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormGroup, Label } from 'reactstrap';
import { ReactComponent as AddCrossIcon } from '../../assets/images/icons/AddCross.svg';
import { ReactComponent as MinusCircleIcon } from '../../assets/images/icons/MinusCircle.svg';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import styles from './CounterInput.module.css';

const CounterInput = forwardRef(
  ({ name, label, rules, disabled, max = 5, defaultValue = 0 }, ref) => {
    const { t } = useTranslate();
    const {
      watch,
      setValue,
      control,
      formState: { errors },
    } = useFormContext();

    const currentValue = watch(name) || defaultValue;

    const handleIncrement = () => {
      const newValue = currentValue + 1 <= max ? currentValue + 1 : max;
      setValue(name, newValue);
    };

    const handleDecrement = () => {
      const newValue = currentValue - 1 >= 0 ? currentValue - 1 : 0;
      setValue(name, newValue);
    };

    const handleBeforeInput = (event) => {
      const notANumber = isNaN(Number(event.data));
      const startsWithZero =
        Number(event.data) === 0 && Number(event.target.value) === 0;
      const isGreaterThanMax =
        Number(event.target.value + Number(event.data)) > max;

      if (notANumber || startsWithZero || isGreaterThanMax) {
        event.preventDefault();
      }
    };

    return (
      <FormGroup className="w-100">
        <div className="d-flex flex-column w-100 position-relative">
          {label && (
            <Label className={styles.CounterInput__label} for={`${name}ID`}>
              {label}
            </Label>
          )}
          <Controller
            defaultValue={defaultValue}
            name={name}
            rules={rules}
            control={control}
            render={({ field }) => (
              <div className="d-flex justify-content-between w-100 ">
                <button
                  data-testid={`CounterInput--decrement-${name}`}
                  onClick={() => handleDecrement()}
                  type="button"
                  className={styles.CounterInput__counterButton}
                  style={{ marginRight: '10%' }}
                  aria-label="minus button"
                >
                  <MinusCircleIcon />
                </button>
                <input
                  // prevent non-numeric input
                  onBeforeInput={handleBeforeInput}
                  type="text"
                  className={`${styles.CounterInput__input} ${
                    errors[name]
                      ? styles.CounterInput__inputTextError
                      : styles.CounterInput__inputText
                  }
                `}
                  {...field}
                  ref={ref || field.ref}
                  aria-label={name}
                  id={`${name}ID`}
                  aria-describedby={`${name}-error`}
                  required={rules}
                  disabled={disabled}
                />
                <button
                  data-testid={`CounterInput--increment-${name}`}
                  onClick={() => handleIncrement()}
                  type="button"
                  className={styles.CounterInput__counterButton}
                  style={{ marginLeft: '10%', paddingTop: 3 }}
                  aria-label="add button"
                >
                  <AddCrossIcon />
                </button>
              </div>
            )}
          />
        </div>

        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <div
              className={styles.CounterInput__errorText}
              id={`${name}-error`}
              data-testid={`${name}-error`}
            >
              {t('Error')}: {message}
            </div>
          )}
        />
      </FormGroup>
    );
  }
);
CounterInput.propTypes = {
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  rules: PropTypes.object,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  ref: PropTypes.object,
  max: PropTypes.number,
  defaultValue: PropTypes.number,
};

export default CounterInput;
