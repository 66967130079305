import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Col, Row } from 'reactstrap';
import { useScreenDetector } from '../../hooks/useScreenDetector/useScreenDetector';
import styles from './SkeletonLoading.module.css';

const SkeletonLoading = () => {
  const { isMobile } = useScreenDetector();
  return (
    <Row className={styles.SkeletonLoading__container}>
      <Col md="5 p-0">
        <Skeleton
          height={isMobile ? 340 : 380}
          className={styles.SkeletonLoading__leftColumn}
          containerTestId="skeleton"
        />
      </Col>
      <Col md="7 p-0" className={styles.SkeletonLoading__rightColumn}>
        <div className={styles.SkeletonLoading__infoContainer}>
          <Skeleton height={45} width={250} />
          <div className="my-3"></div>
          <Skeleton height={40} width={130} />
          <div className="my-3"></div>
          <Skeleton height={120} />
          <hr className="bbe-hr my-3 w-100" />
          <Skeleton height={80} />
          <div className="my-3"></div>
          <div className="d-flex justify-content-end">
            <div className={styles.SkeletonLoading__button}>
              {' '}
              <Skeleton height={40} width={isMobile ? '100%' : 130} />
            </div>
            <div className="mx-2"></div>
            <div className={styles.SkeletonLoading__button}>
              <Skeleton height={40} width={isMobile ? '100%' : 130} />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SkeletonLoading;
