import PropTypes from 'prop-types';
import React, { useCallback, useState, useMemo } from 'react';
import { Alert } from 'reactstrap';
import styles from './useMessage.module.css';

const BaseMessage = ({
  open = false,
  message = '',
  color = 'warning',
  className = '',
  onClose = () => {},
}) => (
  <Alert
    color={color}
    isOpen={open}
    className={`${className} ${styles[color]}`}
    toggle={onClose}
  >
    {message}
  </Alert>
);

BaseMessage.propTypes = {
  color: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default function useMessage() {
  const [shown, setShown] = useState(false);
  const [props, setProps] = useState({});

  // Create the memoized component directly instead of a factory
  const MessageComponent = useMemo(() => {
    const Component = ({ className = '' }) => (
      <BaseMessage
        open={shown}
        color={props.color}
        message={props.message}
        className={className}
        onClose={() => setShown(false)}
      />
    );

    Component.propTypes = {
      className: PropTypes.string,
      color: PropTypes.string,
      message: PropTypes.string.isRequired,
    };

    return Component;
  }, [shown, props.color, props.message]);

  const showMessage = useCallback(
    (message, color = 'warning', fixed = true) => {
      setProps({ color, message });
      setShown(true);
      if (!fixed) {
        setTimeout(() => setShown(false), 6000);
      }
    },
    []
  );

  const closeMessage = useCallback(() => setShown(false), []);

  return [MessageComponent, showMessage, closeMessage];
}
