import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import PermanentModal from '../../../../components/PermanentModal/PermanentModal';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import Payment from './Payment';
import styles from './PaymentSwitcher.module.css';

const PaymentSwitcher = forwardRef(
  (
    {
      onPaymentOptionChange,
      paymentProps,
      threedsModalOpen,
      onShowMessage,
      onCloseMessage,
    },
    ref
  ) => {
    const { t } = useTranslate();

    const payNowRef = useRef();
    const payAtHotelRef = useRef();

    // temporarily default to false in order to assert that PROD is fixed and working correctly
    const payNowEnabled = false;

    const [paymentOption, setPaymentOption] = useState(
      payNowEnabled ? null : 'payAtHotel'
    );
    // Add a key state to force rerender
    const [rerenderKey, setRerenderKey] = useState(0);

    useImperativeHandle(ref, () => ({
      submit: () =>
        payNowEnabled && paymentOption === 'payNow'
          ? payNowRef.current.submit()
          : payAtHotelRef.current.submit(),
      waitForThreeDSIframe: () =>
        payNowEnabled && paymentOption === 'payNow'
          ? payNowRef.current.waitForThreeDSIframe()
          : payAtHotelRef.current.waitForThreeDSIframe(),
    }));

    const handlePaymentMethods = (paymentOption) => {
      if (!payNowEnabled) return;
      if (paymentOption === 'payNow') {
        payAtHotelRef.current.close();
        payNowRef.current.open();
      } else {
        payNowRef.current.close();
        payAtHotelRef.current.open();
      }

      setPaymentOption(paymentOption);
      onPaymentOptionChange(paymentOption);
    };

    const handleHardReload = () => {
      // Increment the key to force a rerender of Payment components
      setRerenderKey((prevKey) => prevKey + 1);
    };

    return (
      <div>
        {payNowEnabled && (
          <div className={styles.PaymentSwitcher__paymentContainer}>
            <div className={styles.PaymentSwitcher__title}>PAY NOW</div>{' '}
            <div className={styles.PaymentSwitcher__infoText}>
              You will pay securely for your stay now.
            </div>
            <PermanentModal
              isModal={paymentOption === 'payNow' && threedsModalOpen}
            >
              {threedsModalOpen && <h2>{t('Secure authentication')}</h2>}
              <Payment
                onShowMessage={onShowMessage}
                onCloseMessage={onCloseMessage}
                key={`pay-now-${rerenderKey}`}
                ref={payNowRef}
                {...paymentProps}
                openFirstPaymentMethod={false}
                forceShowingPaymentMethodCheckBox={payNowEnabled}
                ariaLabelPrefix="payNow"
                onHardReload={handleHardReload}
                onClick={() =>
                  paymentOption !== 'payNow' && handlePaymentMethods('payNow')
                }
              />
            </PermanentModal>
          </div>
        )}

        <div
          className={
            payNowEnabled ? styles.PaymentSwitcher__paymentContainer : null
          }
        >
          {payNowEnabled && (
            <>
              <div className={styles.PaymentSwitcher__title}>
                PAY AT THE HOTEL
              </div>
              <div className={styles.PaymentSwitcher__infoText}>
                Your credit card will not be charged – we only need it to
                guarantee your booking. You will make the payment yourself when
                you check-in.
              </div>
            </>
          )}
          <PermanentModal
            isModal={paymentOption === 'payAtHotel' && threedsModalOpen}
          >
            {threedsModalOpen && <h2>{t('Secure authentication')}</h2>}

            <Payment
              key={`pay-at-hotel-${rerenderKey}`}
              ref={payAtHotelRef}
              {...paymentProps}
              onShowMessage={onShowMessage}
              onCloseMessage={onCloseMessage}
              payNowData={null}
              openFirstPaymentMethod={!payNowEnabled}
              forceShowingPaymentMethodCheckBox={payNowEnabled}
              ariaLabelPrefix="payAtHotel"
              onHardReload={handleHardReload}
              onClick={() =>
                paymentOption !== 'payAtHotel' &&
                handlePaymentMethods('payAtHotel')
              }
            />
          </PermanentModal>
        </div>
      </div>
    );
  }
);

PaymentSwitcher.propTypes = {
  onPaymentOptionChange: PropTypes.func.isRequired,
  paymentProps: PropTypes.object.isRequired,
  onShowMessage: PropTypes.func.isRequired,
  onCloseMessage: PropTypes.func.isRequired,
  threedsModalOpen: PropTypes.bool,
};

export default PaymentSwitcher;
