import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getAuthTokens } from '../../redux/slices/userSlice/userSlice';

const LoginRedirect = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.user.accessToken);

  const [searchParams, setSearchParams] = useSearchParams();
  const socialNetworkAuthCodeParam = searchParams.get('code');
  const redirectStateParam = searchParams.get('state');

  useEffect(() => {
    if (socialNetworkAuthCodeParam && !accessToken) {
      dispatch(getAuthTokens(socialNetworkAuthCodeParam)).then(() => {
        const params = new URLSearchParams(searchParams);
        params.delete('code');
        setSearchParams(params);
      });
    }
  }, [
    socialNetworkAuthCodeParam,
    accessToken,
    dispatch,
    setSearchParams,
    searchParams,
  ]);

  useEffect(() => {
    if (redirectStateParam && !socialNetworkAuthCodeParam) {
      const { returnUrl, params } = JSON.parse(atob(redirectStateParam));
      const url = new URL(window.location.origin + returnUrl);
      window.location.assign(url + params);
    }
  }, [redirectStateParam, socialNetworkAuthCodeParam]);
  return <></>;
};

export default LoginRedirect;
