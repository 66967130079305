import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import styles from './RoomMedia.module.css';

const RoomMedia = ({
  title,
  images,
  video,
  defaultView = 'images',
  modalView = false,
}) => {
  const { t } = useTranslate();
  const [activeView, setActiveView] = useState(defaultView);
  const [imageCarouselIndex, setImageCarouselIndex] = useState(0);

  const handleImageCarouselChangeSliderIndex = (index) => {
    setImageCarouselIndex(index);
  };

  const totalNumberOfItemsInImageCarousel = images.length + (video ? 1 : 0);

  useEffect(() => {
    setActiveView(defaultView);
  }, [defaultView]);

  return (
    <div
      data-testid="roomMediaContainer"
      className={`d-flex ${
        modalView ? 'flex-column' : 'flex-column-reverse'
      } h-100`}
    >
      <div className={styles.RoomMedia__tools}>
        <div className="d-flex justify-content-center">{title}</div>
        <div>
          {imageCarouselIndex + 1} {t('of')} {totalNumberOfItemsInImageCarousel}
        </div>
      </div>

      <div className="w-100" style={{ height: '92%' }}>
        <ImageCarousel
          videoFirst={activeView === 'video'}
          video={video}
          images={images}
          title={title}
          index={imageCarouselIndex}
          onChangeSliderIndex={handleImageCarouselChangeSliderIndex}
        />
      </div>
    </div>
  );
};
RoomMedia.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  video: PropTypes.string,
  defaultView: PropTypes.oneOf(['images', 'video']),
  modalView: PropTypes.bool,
};

export default RoomMedia;
