// when making calendar availability calls,
// we will get information for things like MinStayThrough

// if this exists on 2+ rooms on a date,
// we would want to use the maximum value of the min stay through

// this function will merge the metadata objects

const mergeAvailabilityMetadata = (availability) => {
  const metadataObjects = availability.filter(
    ({ metadata }) => !!Object.keys(metadata).length
  );

  const metadata = metadataObjects.reduce((acc, { metadata }) => {
    Object.keys(metadata).forEach((key) => {
      if (key.endsWith('Value')) {
        if (acc[key]) {
          acc[key] = Math.max(acc[key], metadata[key]);
        } else {
          acc[key] = metadata[key];
        }
      } else {
        acc[key] = metadata[key];
      }
    });

    return acc;
  }, {});

  return metadata;
};

export default mergeAvailabilityMetadata;
