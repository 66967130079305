const navigateAfterRemovingAllBookings = (navigateFn, bookings) => {
  if (
    bookings.length === 1 ||
    !bookings.slice(1).some((b) => b.productCode !== bookings[0].productCode)
  ) {
    const booking = bookings[0];
    let path = `/select-room?productCode=${booking.productCode}`;
    navigateFn(path, { replace: true });
  } else {
    navigateFn('/', { replace: true });
  }
};

export default navigateAfterRemovingAllBookings;
