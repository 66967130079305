import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import CancelConfirmationBookingsModal from '../CancelConfirmationBookingsModal/CancelConfirmationBookingsModal';
import styles from './../BookingsConfirmationActionButtons/BookingActionButtonsModal.module.css';

const CancelConfirmationBookingsButton = ({
  bookings,
  error,
  loading,
  onCancel,
}) => {
  const { t } = useTranslate();
  const [
    cancelConfirmationBookingsModalOpen,
    setCancelConfirmationBookingsModalOpen,
  ] = useState(false);

  useEffect(() => {
    return () => (document.body.style.overflow = 'auto');
  }, []);

  return (
    <div>
      <CancelConfirmationBookingsModal
        open={cancelConfirmationBookingsModalOpen}
        bookings={bookings}
        onCancel={() => setCancelConfirmationBookingsModalOpen(false)}
        onConfirm={onCancel}
        error={error}
        loading={loading}
      />
      <div className={styles.BookingActionButtonsModal__tool}>
        <div className={styles.BookingActionButtonsModal__header}>
          {t('Cancellations')}
        </div>
        <div className={styles.BookingActionButtonsModal__body}>
          {bookings[0].roomRate.cancellationPolicy}
        </div>
        <Button
          className="button transparent w-100"
          onClick={() => setCancelConfirmationBookingsModalOpen(true)}
        >
          {t('Cancel Reservation')}
        </Button>
      </div>
    </div>
  );
};

CancelConfirmationBookingsButton.propTypes = {
  bookings: PropTypes.array,
  error: PropTypes.string,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default CancelConfirmationBookingsButton;
