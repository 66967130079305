import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { ReactComponent as CheckmarkFillIcon } from '../../assets/images/icons/CheckmarkFill.svg';
import calculateAddonPrice from '../../helpers/calculate-addon-price';
import usePrintPrice from '../../hooks/usePrintPrice/usePrintPrice';
import { useScreenDetector } from '../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import styles from './AddonsListItem.module.css';

// more readable title for the pricing type
const pricingTypeTitle = {
  'Per stay': 'Per Stay',
  'Per person per night': 'Per Person / Per Night',
  'Per person': 'Per Person',
  'Per night': 'Per Night',
};

const AddonsListItem = ({
  addon,
  image,
  onAdd,
  onRemove,
  addonPicked,
  booking,
}) => {
  let { code, title, description, pricingType } = addon;
  const { productCode } = booking;

  const { t } = useTranslate();
  const descriptionRef = useRef(null);
  const { isMobile } = useScreenDetector();
  const { printPrice } = usePrintPrice(productCode);
  const [descriptionTruncated, setDescriptionTruncated] = useState(false);
  const isDescriptionTruncated = () =>
    descriptionRef.current.clientHeight < descriptionRef.current.scrollHeight ||
    descriptionRef.current.clientWidth < descriptionRef.current.scrollWidth;

  const toggleDescriptionTruncation = useCallback(() => {
    if (isDescriptionTruncated() && !isMobile) {
      setDescriptionTruncated(true);
    } else {
      setDescriptionTruncated(false);
    }
  }, [isMobile]);

  const calculatedAddonPrice = useMemo(() => {
    return calculateAddonPrice(addon, booking);
  }, [addon, booking]);

  useEffect(() => {
    toggleDescriptionTruncation();
    window.addEventListener('resize', toggleDescriptionTruncation);
    return () =>
      window.removeEventListener('resize', toggleDescriptionTruncation);
  }, [toggleDescriptionTruncation]);

  return (
    <div className={styles.AddonsListItem__container} data-testid="addon">
      <div className={styles.AddonsListItem__imageContainer}>
        {addonPicked && (
          <div className={styles.AddonsListItem__addonAdded}>
            <CheckmarkFillIcon style={{ opacity: 1 }} />
          </div>
        )}
        <img
          src={image}
          alt={title}
          className={styles.AddonsListItem__addonImage}
        ></img>
      </div>
      <div className={styles.AddonsListItem__title}>
        <div className={styles.AddonsListItem__name}>{title}</div>
        <div className="d-flex align-items-start h-100">
          <div
            className={styles.AddonsListItem__description}
            id={`description${code.replace(/\s/g, '-')}`}
            ref={descriptionRef}
            aria-expanded={descriptionTruncated}
            data-testid="addonDescription"
          >
            {descriptionTruncated && (
              <UncontrolledTooltip
                placement="top"
                data-testid="description-tooltip"
                target={`description${code.replace(/\s/g, '-')}`}
                style={{ textAlign: 'left' }}
              >
                {description}
              </UncontrolledTooltip>
            )}
            {description}
          </div>
        </div>
      </div>
      <div className={styles.AddonsListItem__bottomPart}>
        <hr className="bbe-hr my-3" />
        <div>
          <div className={styles.AddonsListItem__priceContainer}>
            {t(pricingTypeTitle[pricingType] || pricingType)}{' '}
            {t('including taxes and fees')}
            <span className={styles.AddonsListItem__price}>
              {printPrice(calculatedAddonPrice)}
            </span>
          </div>
          <Button
            className={`button w-100 ${addonPicked ? 'transparent' : ''}`}
            aria-label={`${addonPicked ? 'Remove' : 'Add'} ${title}`}
            onClick={addonPicked ? onRemove : onAdd}
          >
            {addonPicked ? t('Remove') : t('Add')}
          </Button>
        </div>
      </div>
    </div>
  );
};
AddonsListItem.propTypes = {
  booking: PropTypes.object.isRequired,
  addon: PropTypes.shape({
    code: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    pricingType: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.string,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  addonPicked: PropTypes.bool,
};

export default AddonsListItem;
