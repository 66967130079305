// we get the description of a given room from sabre as html
// detect if its a list, the split out the items so we can style it easier (2 columns)

const splitDescriptionIntoLists = (description) => {
  const listMatch = description.match(/<ul>(.*?)<\/ul>/s);
  if (!listMatch?.[1]) return { firstList: [], secondList: [] };

  const listItems = listMatch[1]
    .split('</li>')
    .map((item) => (item.trim() ? item + '</li>' : ''))
    .filter(Boolean);

  const halfIndex = Math.ceil(listItems.length / 2);
  const firstList = listItems.slice(0, halfIndex);
  const secondList = listItems.slice(halfIndex);

  // Balancing the lists
  if (firstList.length > secondList.length) {
    secondList.push('<li style="list-style-type: none;">&nbsp;</li>');
  }

  return { firstList, secondList };
};

export default splitDescriptionIntoLists;
