import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/icons/ArrowLeft.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/images/icons/ArrowRight.svg';
import { useScreenDetector } from '../../hooks/useScreenDetector/useScreenDetector';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import styles from './ImageCarousel.module.css';

const calculateAspectRatio = (imageSrc) => {
  const image = new Image();
  image.src = imageSrc;

  // default aspect ratio is 1 (square) until the image is fully loaded
  return new Promise((resolve) => {
    image.onload = () => {
      const aspectRatio = image.width / image.height;
      resolve(aspectRatio);
    };
  });
};

const ImageCarousel = ({
  images,
  title,
  video,
  videoFirst,
  onChangeSliderIndex,
}) => {
  const { isMobile } = useScreenDetector();
  const [aspectRatios, setAspectRatios] = useState([]);

  // load the aspect ratios for each image
  useEffect(() => {
    const loadAspectRatios = async () => {
      const newAspectRatios = await Promise.all(
        images.map((imageSrc) => calculateAspectRatio(imageSrc))
      );
      setAspectRatios(newAspectRatios);
    };

    loadAspectRatios();
  }, [images]);

  const sliderArray = video
    ? videoFirst
      ? [{ video }, ...images]
      : [...images, { video }]
    : images;

  const carouselPrevArrow = (onPrevArrowClick, _, label) => {
    return (
      <div className={styles.ImageCarousel__prevArrow}>
        <button
          type="button"
          aria-label="previous slide"
          onClick={onPrevArrowClick}
          title={label}
          style={{ zIndex: 1 }}
        >
          <LeftArrowIcon />
        </button>
      </div>
    );
  };

  const carouselNextArrow = (onNextArrowClick, _, label) => {
    return (
      <div className={styles.ImageCarousel__nextArrow}>
        <button
          aria-label="next slide"
          type="button"
          onClick={onNextArrowClick}
          title={label}
          style={{ zIndex: 1 }}
        >
          <RightArrowIcon />
        </button>
      </div>
    );
  };

  return (
    <Carousel
      renderArrowPrev={carouselPrevArrow}
      renderArrowNext={carouselNextArrow}
      infiniteLoop={true}
      emulateTouch={true}
      showThumbs={false}
      showIndicators={false}
      showArrows={false}
      showStatus={false}
      className={styles.ImageCarousel__carousel}
      onChange={onChangeSliderIndex}
    >
      {sliderArray.map((item, i) => {
        if (item.video) {
          return <VideoPlayer key={`video-${i}`} video={item.video} />;
        } else {
          const aspectRatio = aspectRatios[i] || 1;
          const backgroundSize =
            isMobile || aspectRatio >= 1 ? 'cover' : 'contain';
          return (
            <div
              className={styles.ImageCarousel__imageContainer}
              key={`image-${i}`}
            >
              <div
                data-testid={`image-${i}`}
                style={{ backgroundImage: `url(${item})`, backgroundSize }}
                alt={title}
                className={styles.ImageCarousel__image}
              />
            </div>
          );
        }
      })}
    </Carousel>
  );
};

ImageCarousel.propTypes = {
  images: PropTypes.array,
  title: PropTypes.string,
  video: PropTypes.string,
  videoFirst: PropTypes.bool,
  onChangeSliderIndex: PropTypes.func,
};

export default ImageCarousel;
