import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import { ReactComponent as ArrowDownIcon } from '../../../../../../assets/images/icons/ArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../../../assets/images/icons/ArrowUp.svg';
import useTranslate from '../../../../../../hooks/useTranslate/useTranslate';
import styles from './FilterDropdown.module.css';

const FilterDropdown = ({ open, onClear, onClick, hasValues, title }) => {
  const { t } = useTranslate();
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${!open ? styles.FilterDropdown__boxCollapsed : ''} ${
        hasValues && !open ? styles.FilterDropdown__activeFilters : ''
      }`}
      style={{ width: '100%' }}
      data-testid="filterDropdown"
    >
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={styles.FilterDropdown__title}
          style={{ marginBottom: open && 15 }}
        >
          {title}
        </div>
        <div className="d-flex align-items-center">
          {!open && hasValues && (
            <Button
              className="hoverEffectButton mx-3 text-uppercase"
              onClick={onClear}
            >
              {t('Clear')}
            </Button>
          )}
          {open ? (
            <ArrowUpIcon data-testid="arrowUp" />
          ) : (
            <ArrowDownIcon data-testid="arrowDown" />
          )}
        </div>
      </div>
    </button>
  );
};

FilterDropdown.propTypes = {
  open: PropTypes.bool,
  onClear: PropTypes.func,
  onClick: PropTypes.func,
  hasValues: PropTypes.bool,
  title: PropTypes.string,
};

export default FilterDropdown;
