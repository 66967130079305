import { useSelector } from 'react-redux';

const useDetermineMultipleHotels = (bookingsParam = null) => {
  const stateBookings = useSelector((state) => state.bookings.list);
  const bookings = bookingsParam || stateBookings;

  const isMultipleHotels =
    bookings.length > 1 &&
    bookings
      .slice(1)
      .some(({ productCode }) => productCode !== bookings[0].productCode);

  return isMultipleHotels;
};

export default useDetermineMultipleHotels;
