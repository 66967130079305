import PropTypes from 'prop-types';
import { ReactComponent as AllMealsIncludedIcon } from '../../../../../assets/images/icons/AllMealsIncluded.svg';
import { ReactComponent as BreakFastAndDinnerIncludedIcon } from '../../../../../assets/images/icons/BreakFastAndDinnerIncluded.svg';
import { ReactComponent as BreakfastIncludedIcon } from '../../../../../assets/images/icons/BreakfastIncluded.svg';
import { ReactComponent as BedIcon } from '../../../../../assets/images/icons/bedIcon.svg';

const Icon = ({ rateContent }) => {
  if (!rateContent?.mealPlanList) return <BedIcon data-testid="room_rate" />;
  switch (rateContent.mealPlanList[0].name.toLowerCase()) {
    case 'breakfast included':
      return <BreakfastIncludedIcon data-testid="breakfast" />;
    case 'breakfast and dinner included':
      return (
        <BreakFastAndDinnerIncludedIcon data-testid="breakfast_and_dinner" />
      );
    case 'all meals included':
      return <AllMealsIncludedIcon data-testid="all_meals" />;
    default:
      return null;
  }
};

Icon.propTypes = {
  rateContent: PropTypes.shape({
    mealPlanList: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired
    ),
  }),
};

export default Icon;
