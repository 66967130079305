import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import useTotalPrice from '../../../../hooks/useTotalPrice/useTotalPrice';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import BookingOverview from './BookingOverview';
import styles from './BookingOverviewModal.module.css';

const BookingOverviewModal = ({ open, bookings, onEditRoom, onContinue }) => {
  const { totalPrice } = useTotalPrice(bookings);
  const currency = useSelector((state) => state.appSettings.currencies.current);
  const hotels = useSelector((state) => state.belmond.hotels);
  const media = useSelector((state) => state.media.mediaUrls);

  const { t } = useTranslate();
  return (
    <Modal
      isOpen={open}
      contentClassName={styles.FeedbackModal__modalWrapper}
      scrollable
    >
      <ModalBody className={styles.BookingOverviewModal__modalBody}>
        <div className={styles.BookingOverviewModal__modalView}>
          <div className={styles.BookingOverviewModal__header}>
            {t('Your Booking Overview')}
          </div>
          {bookings.map((booking, i) => (
            <BookingOverview
              booking={booking}
              media={media}
              numOfBookings={bookings.length}
              bookingIndex={i}
              hotel={hotels[booking.productCode]}
              onEditRoom={onEditRoom}
              key={booking.id}
            />
          ))}
          <div className="d-flex justify-content-between">
            <div className={styles.BookingOverviewModal__roomTitle}>TOTAL</div>
            <div className="d-flex flex-column align-items-end">
              <div className={styles.BookingOverviewModal__roomTitle}>
                {totalPrice}
              </div>
              <div>
                ({currency} {t('tax included')})
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <Button className="button" onClick={onContinue}>
            Continue
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

BookingOverviewModal.propTypes = {
  open: PropTypes.bool,
  bookings: PropTypes.array,
  onEditRoom: PropTypes.func,
  onContinue: PropTypes.func,
};

export default BookingOverviewModal;
