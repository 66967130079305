const bookingFindReservation = () => {
  if (!window.dataLayer) return;

  const gtmData = {
    event: 'bookingFindReservation',
  };
  window.dataLayer.push(gtmData);
};

export default bookingFindReservation;
