import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/images/icons/ArrowDown.svg';
import useDetermineMultipleHotels from '../../../../hooks/useDetermineMultipleHotels/useDetermineMultipleHotels';
import AddConfirmationBookingsToCalendarButton from '../AddConfirmationBookingsToCalendarButton/AddConfirmationBookingsToCalendarButton';
import CancelConfirmationBookingsButton from '../CancelConfirmationBookingsButton/CancelConfirmationBookingsButton';
import EditConfirmationBookingsButton from '../EditConfirmationBookingsButton/EditConfirmationBookingsButton';
import PrintConfirmationBookingButton from '../PrintConfirmationBookingButton/PrintConfirmationBookingButton';
import styles from './BookingsConfirmationActionButtons.module.css';

const BookingsConfirmationActionButtons = ({
  bookingsConfirmationViewRef,
  bookings,
  mobileView,
  cancelBookingsLoading,
  cancelBookingsError,
  onEditBooking,
  onCancelBooking,
  onClose,
  actionableBookings,
}) => {
  const multipleHotels = useDetermineMultipleHotels(bookings);
  const editableBookings = actionableBookings.filter(
    ({ specialCodeValue }) => !['COMP', 'DISC'].includes(specialCodeValue)
  );

  const hotel = useSelector((state) =>
    !multipleHotels ? state.belmond.hotels[bookings[0].productCode] : null
  );

  return (
    <div
      className={`${
        mobileView
          ? styles.BookingsConfirmationActionButtons__fullscreen
          : styles.BookingsConfirmationActionButtons__box
      } d-print-none`}
      data-testid="editBox"
    >
      {mobileView && (
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div className={styles.BookingsConfirmationActionButtons__heading}>
            {multipleHotels ? 'BELMOND' : hotel.shortName}
          </div>
          <button
            onClick={onClose}
            aria-label="close modal"
            type="button"
            style={{ width: 30, height: 30 }}
          >
            <ArrowDownIcon />
          </button>
        </div>
      )}
      <div className={styles.BookingsConfirmationActionButtons__actionButtons}>
        <div className={styles.BookingsConfirmationActionButtons__iconButtons}>
          <PrintConfirmationBookingButton
            bookingsConfirmationViewRef={bookingsConfirmationViewRef}
          />
          {bookings.length ? (
            <AddConfirmationBookingsToCalendarButton bookings={bookings} />
          ) : null}
        </div>
        {actionableBookings.length ? (
          <>
            <hr className="bbe-hr mt-4" />
            {editableBookings.length ? (
              <>
                <EditConfirmationBookingsButton
                  bookings={editableBookings}
                  onEdit={onEditBooking}
                />

                <hr className="bbe-hr mt-4" />
              </>
            ) : null}
            <CancelConfirmationBookingsButton
              bookings={actionableBookings}
              onCancel={onCancelBooking}
              loading={cancelBookingsLoading}
              error={cancelBookingsError}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

BookingsConfirmationActionButtons.propTypes = {
  bookingsConfirmationViewRef: PropTypes.object,
  bookings: PropTypes.array,
  mobileView: PropTypes.bool,
  onEditBooking: PropTypes.func,
  onCancelBooking: PropTypes.func,
  onClose: PropTypes.func,
  cancelBookingsLoading: PropTypes.bool,
  cancelBookingsError: PropTypes.string,
  actionableBookings: PropTypes.array,
};

export default BookingsConfirmationActionButtons;
