import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as ErrorCrossIcon } from '../../../../assets/images/icons/ErrorCross.svg';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import bookingConfirmationMessageStyles from '../BookingConfirmationMessage/BookingConfirmationMessage.module.css';
import styles from './FailedAddonsActionsMessage.module.css';

const FailedAddonsActionsMessage = ({ failingAddonsActions }) => {
  const { t } = useTranslate();
  const getSuccessActionsText = () => {
    const successActions = [];
    if (failingAddonsActions.toAdd.find((a) => a.success)) {
      successActions.push(t('added'));
    }
    if (failingAddonsActions.toRemove.find((a) => a.success)) {
      successActions.push(t('removed'));
    }
    return successActions.join(t(' and '));
  };

  return (
    <div
      className={`${bookingConfirmationMessageStyles.BookingConfirmationMessage__rowContainer} ${styles.FailedAddonsActionsMessage__borderColor} d-print-none`}
    >
      <div>
        <div className="d-flex align-items-center">
          <ErrorCrossIcon style={{ marginRight: 10, minWidth: 20 }} />
          <div
            className={
              bookingConfirmationMessageStyles.BookingConfirmationMessage__message
            }
            aria-live="polite"
          >
            {t('We are sorry, although some addons has been')}{' '}
            {getSuccessActionsText()}, {t('other actions have been failed.')}
            {t('Please try to do it again or contact us.')}
            <br /> {t('Failing actions')}:
            <div
              className={
                bookingConfirmationMessageStyles.BookingConfirmationMessage__messageEmail
              }
            >
              {failingAddonsActions.toAdd
                .filter((a) => !a.success)
                .map((addon, i) => (
                  <span key={`addon-add-error-${i}`}>
                    - <b>{addon.title || addon.addonCode}</b> ({t('Booking')}:{' '}
                    {addon.bookingId}) {t('has not been added successfully')}
                  </span>
                ))}
              {failingAddonsActions.toRemove
                .filter((a) => !a.success)
                .map((addon, i) => (
                  <span key={`addon-remove-error-${i}`}>
                    - <b>{addon.title || addon.addonCode}</b> ({t('Booking')}:{' '}
                    {addon.bookingId}) {t('has not been removed successfully')}
                  </span>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FailedAddonsActionsMessage.propTypes = {
  failingAddonsActions: PropTypes.object,
};

export default FailedAddonsActionsMessage;
