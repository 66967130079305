import React from 'react';
import styles from './ViewLoader.module.css';

const ViewLoader = () => {
  return (
    <div className={styles.HotelViewLoader__wrapper} data-testid="loader">
      <svg
        className={styles.HotelViewLoader__container}
        viewBox="0 0 40 40"
        height="40"
        width="40"
      >
        <circle
          className={styles.HotelViewLoader__track}
          cx="20"
          cy="20"
          r="17.5"
          pathLength="100"
          strokeWidth="1px"
          fill="none"
        />
        <circle
          className={styles.HotelViewLoader__car}
          cx="20"
          cy="20"
          r="17.5"
          pathLength="100"
          strokeWidth="1px"
          fill="none"
        />
      </svg>
    </div>
  );
};

export default ViewLoader;
