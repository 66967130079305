const mergeMonths = (currentMonths, monthToAppend) => {
  const newMonthData = [...monthToAppend];
  if (currentMonths.length > 0 && newMonthData.length > 0) {
    newMonthData[0] = {
      ...newMonthData[0],
      isCheckOut: currentMonths[currentMonths.length - 1].isCheckIn,
    };
  }

  return [...currentMonths, ...newMonthData];
};

export default mergeMonths;
