import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/images/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/images/icons/ArrowRight.svg';
import { useScreenDetector } from '../../../../hooks/useScreenDetector/useScreenDetector';
import { setCurrentBooking } from '../../../../redux/slices/bookingsSlice/bookingsSlice';
import styles from './MultiRoomPicker.module.css';
import { MultiRoomPickerItem } from './components';

const MultiRoomPicker = ({
  highlightedRoomIndex,
  onHighlightRoom,
  editing,
  onFocusChange,
  guestsPerRoom,
  isSubmitting,
}) => {
  const roomSelectorContainerRef = useRef(null);
  const roomButtonsContainerRef = useRef(null);
  const { isDesktop } = useScreenDetector();
  const dispatch = useDispatch();
  const bookings = useSelector((state) => state.bookings.list);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [focusedRoomIndex, setFocusedRoomIndex] =
    useState(highlightedRoomIndex); // for scroling purposes
  const [guestsPerRoomAfterSearch, setGuestsPerRoomAfterSearch] =
    useState(guestsPerRoom);

  const handleWindowResize = useCallback(
    (scrollLeftValue = roomButtonsContainerRef.current?.scrollLeft) => {
      const roomSelectorContainerWidth =
        roomSelectorContainerRef.current?.offsetWidth;
      const roomButtonsContainerWidth =
        roomButtonsContainerRef.current?.scrollWidth;

      const isScrollable =
        roomSelectorContainerWidth < roomButtonsContainerWidth;

      const isLeftArrow = isScrollable && scrollLeftValue > 0;

      const isRightArrow =
        isScrollable &&
        scrollLeftValue <
          roomButtonsContainerWidth - roomSelectorContainerWidth;

      setShowLeftArrow(isLeftArrow);
      setShowRightArrow(isRightArrow);
    },
    []
  );

  const handleScroll = useCallback(() => {
    const currentRef = roomButtonsContainerRef.current;
    handleWindowResize(currentRef?.scrollLeft);
  }, [handleWindowResize]);

  const handleLeftArrowClick = () => {
    setFocusedRoomIndex(focusedRoomIndex - 1);
    onFocusChange(focusedRoomIndex - 1);
  };

  const handleRightArrowClick = () => {
    setFocusedRoomIndex(focusedRoomIndex + 1);
    onFocusChange(focusedRoomIndex + 1);
  };

  useEffect(() => {
    if (isSubmitting) {
      setGuestsPerRoomAfterSearch(guestsPerRoom);
    }
  }, [guestsPerRoom, isSubmitting]);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', () => handleWindowResize());
    return () =>
      window.removeEventListener('resize', () => handleWindowResize());
  }, [handleWindowResize, guestsPerRoomAfterSearch]);

  useEffect(() => {
    const roomButtons = roomButtonsContainerRef.current?.children;
    if (
      roomButtons.length > 0 &&
      focusedRoomIndex >= 0 &&
      focusedRoomIndex < roomButtons.length
    ) {
      const selectedButton = roomButtons[focusedRoomIndex];
      const scrollPosition = selectedButton.offsetLeft;

      roomButtonsContainerRef.current?.scrollTo({
        left: scrollPosition - (isDesktop ? 25 : 0),
        behavior: 'smooth',
      });

      handleWindowResize(scrollPosition - (isDesktop ? 10 : 0));
    }
  }, [focusedRoomIndex, handleWindowResize, isDesktop]);

  useEffect(() => {
    setFocusedRoomIndex(highlightedRoomIndex);
    //set current booking to highlighted room (the one that is being focused)
    dispatch(setCurrentBooking(bookings[highlightedRoomIndex]?.id || null));
  }, [bookings, dispatch, highlightedRoomIndex]);

  // call handleWindowResize on scroll of roomButtonsContainer
  useEffect(() => {
    const currentRef = roomButtonsContainerRef.current;
    currentRef?.addEventListener('scroll', handleScroll);
    return () => currentRef?.removeEventListener('scroll', handleScroll);
  }, [handleScroll, handleWindowResize]);

  const disabledRightArrow = focusedRoomIndex >= bookings.length && !isDesktop;

  return (
    <div className="d-flex flex-column gap-3">
      <div
        className="min-w-100 border border-0 mt-4 position-relative d-flex"
        ref={roomSelectorContainerRef}
      >
        <div
          ref={roomButtonsContainerRef}
          className={styles.MultiRoomPicker__roomButtonsContainer}
        >
          {guestsPerRoomAfterSearch.map((_, i) => (
            <MultiRoomPickerItem
              key={`MultiRoomPicker--item-${i}`}
              room={bookings[i] || null}
              guests={guestsPerRoomAfterSearch[i]}
              editing={editing}
              onHighlightRoom={onHighlightRoom}
              highlightedRoomIndex={highlightedRoomIndex}
              index={i}
            />
          ))}
        </div>

        {/* Slider controls */}
        {showLeftArrow && (
          <button
            data-testid="left-arrow-button"
            type="button"
            className={styles.MultiRoomPicker__leftArrow}
            style={{
              height: roomButtonsContainerRef.current?.offsetHeight - 20,
              visibility: editing ? 'hidden' : 'visible',
            }}
            onClick={handleLeftArrowClick}
          >
            <ArrowLeftIcon />
          </button>
        )}

        {showRightArrow && (
          <button
            data-testid="right-arrow-button"
            type="button"
            className={styles.MultiRoomPicker__rightArrow}
            disabled={disabledRightArrow}
            style={{
              opacity: disabledRightArrow ? 0.5 : 1,
              height: roomButtonsContainerRef.current?.offsetHeight - 20,
              visibility: editing ? 'hidden' : 'visible',
            }}
            onClick={handleRightArrowClick}
          >
            <ArrowRightIcon />
          </button>
        )}
      </div>
    </div>
  );
};

MultiRoomPicker.propTypes = {
  highlightedRoomIndex: PropTypes.number.isRequired,
  onHighlightRoom: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  onFocusChange: PropTypes.func.isRequired,
  guestsPerRoom: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool,
};

export default MultiRoomPicker;
