import determineAvailabilityMetadata from './determine-availability-metadata';

const removeEmptyStringProps = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== '') {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

const getCalendarAvailability = async (
  productCode,
  month,
  params,
  useAgentId,
  moment,
  axios,
  countryCode
) => {
  const axiosParams = removeEmptyStringProps({
    productCode,
    month: moment(month).format('YYYY-MM'),
    agentId: useAgentId ? params.agentId : null,
    countryCode,
    ...params,
  });

  try {
    const res = await axios.get('/calendar-availability', {
      params: axiosParams,
    });

    // store availability information metadata for each date
    // using the data from calendar-availability
    const availabilityMetadata = determineAvailabilityMetadata(
      month,
      res?.data[productCode],
      params.numAdults.length,
      moment
    );

    return availabilityMetadata;
  } catch (e) {
    const days = [];
    const startDate = moment(month).startOf('month');
    const endDate = moment(month).endOf('month');

    let currentDate = startDate;
    while (currentDate.isSameOrBefore(endDate)) {
      days.push({
        date: currentDate.format('YYYY-MM-DD'),
        isCheckIn: true,
        isCheckOut: true,
        metadata: {},
      });
      currentDate = currentDate.add(1, 'days');
    }
    return days;
  }
};

export default getCalendarAvailability;
