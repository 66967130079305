const defaultPrefetchAmount = 4;

const getMonthsToFetch = (
  currentMonth,
  fetchedMonths,
  isPreviousMonths,
  moment
) => {
  const monthsToFetch = [];

  const isCurrentDateInPast = moment(currentMonth).isBefore(moment(), 'month');

  if (!fetchedMonths.includes(currentMonth) && !isCurrentDateInPast) {
    monthsToFetch.push(currentMonth);
  }

  Array.from({ length: defaultPrefetchAmount }).forEach((_, i) => {
    let monthToFetch;

    if (isPreviousMonths) {
      monthToFetch = moment(currentMonth)
        .subtract(i + 1, 'month')
        .format('YYYY-MM');
    } else {
      monthToFetch = moment(currentMonth)
        .add(i + 1, 'month')
        .format('YYYY-MM');
    }

    const isDateInPast = moment(monthToFetch).isBefore(moment(), 'month');
    if (!fetchedMonths.includes(monthToFetch) && !isDateInPast) {
      monthsToFetch.push(monthToFetch);
    }
  });

  return monthsToFetch;
};

export default getMonthsToFetch;
