import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Col, Label, Row } from 'reactstrap';
import { gtmBookingFilters } from '../../../../../../gtm/events';
import titleCase from '../../../../../../helpers/title-case';
import { useScreenDetector } from '../../../../../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../../../../../hooks/useTranslate/useTranslate';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import styles from './FilterSection.module.css';

const FilterSection = ({ title, name, values }) => {
  const { t } = useTranslate();
  const { register, setValue, watch } = useFormContext();
  const { isMobile } = useScreenDetector();
  const [open, setOpen] = useState(false);
  const value = watch(name);
  const isFiltered = value.length > 0;

  const displayType = watch('displayType');

  const handleCheckboxChange = (item) => {
    if (value.includes(item.code)) {
      setValue(
        name,
        value.filter((code) => code !== item.code)
      );
    } else {
      setValue(name, [...value, item.code]);

      const bookingFilterType = titleCase(title);
      const bookingFilterValue = titleCase(item.name.toLowerCase());
      const bookingFilterCategory = titleCase(
        (item.categoryName && item.categoryName.toLowerCase()) || ''
      );

      const filterChoice = `${bookingFilterType}: ${
        bookingFilterCategory && bookingFilterCategory + ' - '
      }${bookingFilterValue}`;

      gtmBookingFilters(filterChoice, displayType);
    }
  };

  const handleClearSection = () => {
    setValue(name, []);
  };

  return (
    <div className={open ? styles.FilterSection__boxExpanded : ''}>
      <FilterDropdown
        open={open}
        onClear={(e) => {
          e.stopPropagation();
          setValue(name, []);
        }}
        onClick={() => setOpen((prev) => !prev)}
        title={title}
        hasValues={isFiltered}
      />
      {open && (
        <div>
          <div className="d-flex align-items-center">
            <Row className="w-100">
              {values?.map((item, i) => (
                <Col md="4 mb-2" key={item.code}>
                  <div className={styles.FilterSection__item}>
                    <input
                      type="checkbox"
                      {...register(name)}
                      value={item.code}
                      id={item.name}
                      checked={value.includes(item.code)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                    <Label
                      for={item.name}
                      className={styles.FilterSection__label}
                    >
                      {item.categoryName &&
                        item.categoryName.toLowerCase() + ' - '}
                      {item.name.toLowerCase()}
                    </Label>
                  </div>
                  {isMobile && i !== values.length - 1 && (
                    <hr className={styles.FilterSection__hr} />
                  )}
                </Col>
              ))}
              <div className="position-relative mt-4">
                {isFiltered && (
                  <Button
                    className={`hoverEffectButton ${styles.FilterSection__clearButton} text-uppercase`}
                    onClick={handleClearSection}
                  >
                    {t('Clear All')}
                  </Button>
                )}
              </div>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

FilterSection.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.array,
};

export default FilterSection;
