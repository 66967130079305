import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import styles from './GuestLogin.module.css';

const GuestLoginMobile = ({
  loggedIn,
  firstName,
  loading,
  profileButtons,
  logoutButton,
}) => {
  const { t } = useTranslate();
  return (
    <div>
      {loggedIn && firstName ? (
        <>
          <div className={styles.GuestLogin__mobileLoginButtons}>
            <div style={{ textTransform: 'uppercase' }}>
              {t('Hi')} {firstName}
            </div>
            {logoutButton}
          </div>
          <div className={styles.GuestLogin__mobileProfileButtons}>
            {profileButtons}
          </div>
        </>
      ) : (
        <div
          className={styles.GuestLogin__loginButton}
          aria-label="Log in your account"
          data-testid="GuestLoginMobile--btn"
        >
          {loading ? <LoadingSpinner blackSpinner /> : t('LOG IN')}
        </div>
      )}
    </div>
  );
};

GuestLoginMobile.propTypes = {
  loading: PropTypes.bool,
  loggedIn: PropTypes.bool,
  firstName: PropTypes.string,
  profileButtons: PropTypes.node,
  logoutButton: PropTypes.node,
};

export default GuestLoginMobile;
