import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Input } from 'reactstrap';
import Cookies from 'universal-cookie';
import { CHINA_VERSION } from '../../globals';
import useLocalisedMoment from '../../hooks/useLocalisedMoment/useLocalisedMoment';
import { useScreenDetector } from '../../hooks/useScreenDetector/useScreenDetector';
import { setCurrentLanguage } from '../../redux/slices/appSettingsSlice/appSettingsSlice';
import styles from './LanguageSelector.module.css';

const cookies = new Cookies();
let langParamChecked = false;

const LanguageSelector = ({ productCode }) => {
  const moment = useLocalisedMoment();
  const dispatch = useDispatch();
  const { isLargeDesktop } = useScreenDetector();
  const [searchParams, setSearchParams] = useSearchParams();
  const languageParam = searchParams.get('lang');

  const languagesListStore = useSelector(
    (state) => state.appSettings.languages.list
  );
  const currentLanguage = useSelector(
    (state) => state.appSettings.languages.current
  );

  const hotelLanguages = useSelector(
    (state) => state.belmond.hotels[productCode]?.bbeLanguages
  );

  const hotels = useSelector((state) => state.belmond.hotels);

  const [languageList, setLanguageList] = useState(languagesListStore);

  const handleLanguageChange = useCallback(
    (bcp47) => {
      dispatch(setCurrentLanguage(bcp47));
      cookies.set('langPref', bcp47, { path: '/' });
      setTimeout(() => {
        window.location.reload();
      }, 100);
    },
    [dispatch]
  );

  useEffect(() => {
    if (hotelLanguages) {
      const langList = languagesListStore.filter((lang) =>
        hotelLanguages.includes(lang.iso639)
      );
      setLanguageList(langList);
    }
  }, [hotelLanguages, languagesListStore]);

  useEffect(() => {
    if (!hotels.length) return; // still loading hotels

    // if the selected language it's not in the list
    const lang = languagesListStore.find(
      ({ bcp47, iso639 }) =>
        (!hotelLanguages || hotelLanguages.includes(iso639)) &&
        bcp47 === currentLanguage
    );

    // only default to english if not in china version
    // we want to keep the current language in china version
    // so we can test and debug in english

    if (!lang && !CHINA_VERSION) {
      handleLanguageChange('en-us');
    }
  }, [
    currentLanguage,
    languageList,
    handleLanguageChange,
    languagesListStore,
    hotelLanguages,
    hotels,
  ]);

  useEffect(() => {
    if (languageParam && !langParamChecked) {
      langParamChecked = true;
      const params = new URLSearchParams(searchParams);
      params.delete('lang');
      setSearchParams(params, { replace: true });

      const changeLanguage = currentLanguage !== languageParam;

      if (changeLanguage) {
        const language = languagesListStore.find(
          ({ bcp47, iso639 }) =>
            (!hotelLanguages || hotelLanguages.includes(iso639)) &&
            bcp47 === languageParam
        );

        if (!language) return;

        handleLanguageChange(language.bcp47);
      }
    }
  }, [
    currentLanguage,
    handleLanguageChange,
    languageParam,
    hotelLanguages,
    languagesListStore,
    searchParams,
    setSearchParams,
  ]);

  useEffect(() => {
    if (languageList) {
      const langPref = cookies.get('langPref');

      let langIso = languageList.find(
        ({ bcp47 }) => bcp47 === langPref
      )?.iso639;
      if (langIso === 'zh') langIso = 'zh-cn';
      moment.locale(langIso || 'en');
    }
  }, [languageList, moment]);

  // keep logic, but don't render if CHINA_VERSION is true
  if (CHINA_VERSION) return null;

  return (
    <div style={{ marginRight: '1.5rem' }}>
      {isLargeDesktop ? (
        <div>
          <Input
            aria-label="select-language"
            type="select"
            className={styles.SelectRoomPicker__select}
            onChange={(e) => handleLanguageChange(e.target.value)}
            value={currentLanguage}
          >
            {languageList.map((lang) => (
              <option value={lang.bcp47} key={lang.bcp47}>
                {lang.name.toUpperCase()}
              </option>
            ))}
          </Input>
        </div>
      ) : (
        <div className="d-flex">
          {languageList.map((lang) => (
            <button
              key={lang.bcp47}
              className="mx-3"
              style={{
                fontWeight: lang.bcp47 === currentLanguage ? 600 : 'normal',
              }}
              onClick={() => handleLanguageChange(lang.bcp47)}
            >
              {lang.iso639.toUpperCase()}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;

LanguageSelector.propTypes = {
  productCode: PropTypes.string,
};
