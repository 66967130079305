import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';
import RoomMedia from '../RoomMedia/RoomMedia';
import styles from './RoomMediaModal.module.css';

const RoomMediaModal = ({
  open,
  title,
  images,
  video,
  defaultView,
  onClose,
  onClosed,
}) => (
  <Modal
    isOpen={open}
    toggle={onClose}
    onClosed={onClosed}
    external={
      <ModalCloseButton
        className={styles.RoomTypeModal__closeButton}
        onClose={onClose}
      />
    }
    contentClassName={styles.RoomTypeModal__modalWrapper}
    data-testid="roomGalleryModal"
    scrollable
    centered
  >
    <ModalBody>
      <RoomMedia
        title={title}
        images={images}
        modalView
        defaultView={defaultView}
        video={video}
      />
    </ModalBody>
  </Modal>
);

RoomMediaModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClosed: PropTypes.func,
  title: PropTypes.string,
  images: PropTypes.array,
  defaultView: PropTypes.oneOf(['images', 'video']),
  video: PropTypes.string,
};

export default RoomMediaModal;
