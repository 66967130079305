import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import { BASE_URL } from '../../globals';
import client from '../../helpers/authClient';
import useMessage from '../../hooks/useMessage/useMessage';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import InputComponent from '../InputComponent/InputComponent';
import LoadingButton from '../LoadingButton/LoadingButton';
import styles from './SignInForm.module.css';

const PasswordResetForm = ({ onCancel }) => {
  const {
    constants: {
      formRules: { emailRule },
    },
  } = useTranslate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onTouched' });

  const [isLoading, setIsLoading] = useState(false);
  const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(false);
  const [Message, showMessage, closeMessage] = useMessage();

  const [searchParams] = useSearchParams();
  const productCodeParam = searchParams.get('productCode');

  const handleFormSubmit = async ({ recoverPasswordEmail }) => {
    closeMessage();
    setIsLoading(true);
    let redirectUrl = `${BASE_URL}${window.location.pathname}`;

    if (productCodeParam) {
      redirectUrl = `${redirectUrl}?productCode=${productCodeParam}`;
    }
    try {
      await client.requestPasswordReset({
        email: recoverPasswordEmail,
        redirectUrl,
      });
      setResetPasswordEmailSent(true);
    } catch (e) {
      showMessage(
        e.errorUserMsg ||
          'Oops! Something went wrong. Please refresh your browser or try again later.'
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className={styles.SignInForm__container}>
      <div className={styles.SignInForm__smallHeader}>FORGOTTEN PASSWORD</div>
      <hr className={styles.SignInForm__line} />
      {resetPasswordEmailSent ? (
        <div>Please check your email for the reset password link.</div>
      ) : (
        <Form className="w-100 d-flex flex-column" noValidate>
          <div className="text-center m-3">
            Enter the email address associated with your account, and we’ll
            email you a link to reset your password.
          </div>
          <InputComponent
            name="recoverPasswordEmail"
            control={control}
            rules={emailRule}
            errors={errors}
            passivePlaceholder="alex@example.com"
            label={
              <>
                Email Address <span>*</span>
              </>
            }
            autoComplete="email"
          />
          <LoadingButton
            onClick={handleSubmit(handleFormSubmit)}
            className="button"
            type="button my-3"
            ariaLabel="Send reset link"
            loading={isLoading}
          >
            Send reset link
          </LoadingButton>
        </Form>
      )}
      <div className="my-3">
        <Message />
      </div>
      <div className="d-flex m-3">
        <Button
          className="textButton"
          onClick={onCancel}
          aria-label="Back to Log In"
        >
          Back to Login
        </Button>
      </div>
    </div>
  );
};
PasswordResetForm.propTypes = {
  onCancel: PropTypes.func,
};
export default PasswordResetForm;
