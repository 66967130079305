const bookingStepProgress = (stepName) => {
  if (!window.dataLayer) return;

  const data = {
    event: 'bookingStepProgress',
    stepName,
  };

  window.dataLayer.push(data);
};
export default bookingStepProgress;
