import { AdyenCheckout, Dropin } from '@adyen/adyen-web/auto';
import '@adyen/adyen-web/styles/adyen.css';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { env, REACT_APP_BBE_CONFIG } from '../../../../globals';
import { gtmAddPaymentInfo } from '../../../../gtm/events';
import useAxios from '../../../../hooks/useAxios/useAxios';
import { useScreenDetector } from '../../../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import {
  clearPaymentSession,
  createSession,
} from '../../../../redux/slices/paymentSlice/paymentSlice';
import './Payment.css';

const adyenPaymentFormType = 'dropin';

const Payment = forwardRef(
  (
    {
      onSessionCreated,
      onSuccess,
      onFailure,
      metadata,
      on3dsAuth,
      onSubmit3dsCode,
      onCreateSessionFailure,
      onMountingStart,
      onMountingEnd,
      productCode,
      countryCode,
      payNowData,
      ariaLabelPrefix,
      openFirstPaymentMethod = false,
      forceShowingPaymentMethodCheckBox = false,
      onClick,
      onHardReload,
      onShowMessage,
    },
    ref
  ) => {
    const { t } = useTranslate();
    const axios = useAxios();
    const dispatch = useDispatch();
    const { isMobile } = useScreenDetector();

    const paymentContainer = useRef(null);
    const checkout = useRef(null);
    const paymentBrand = useRef(null);
    const currentSessionId = useRef(null);

    const payment = useSelector((state) => state.payment);
    const bookings = useSelector((state) => state.bookings.list);
    const accessToken = useSelector((state) => state.user.accessToken);
    const shopperEmail = useSelector((state) => state.user.profile?.email);

    const baseConfig = useMemo(
      () => ({
        environment: env === 'prod' || env === 'stg' ? '' : 'test',
        showPayButton: false,
        locale: 'en_US',
      }),
      []
    );

    const paymentMethodsConfiguration = useMemo(
      () => ({
        hideCVC: false,
        ideal: { showImage: true },
        locale: 'en_US',
        card: {
          maskSecurityCode: true,
          challengeWindowSize: isMobile ? '01' : '04',
          hasHolderName: true,
          holderNameRequired: true,
          name: 'Credit or Debit Card',
        },
      }),
      [isMobile]
    );

    const handleCleanup = useCallback(
      (reload) => {
        dispatch(clearPaymentSession());
        if (reload) onHardReload();
      },
      [dispatch, onHardReload]
    );

    const handlePaymentCompleted = useCallback(() => {
      gtmAddPaymentInfo(paymentBrand.current);
      onSuccess({ paymentBrand: paymentBrand.current });
    }, [onSuccess]);

    const handlePaymentFailed = useCallback(
      async (error) => {
        if (error?.message === 'The session has expired.') {
          onShowMessage(
            t(
              'The session has expired. Please try enter your details again to proceed with your booking.'
            ),
            'danger'
          );
        } else {
          onShowMessage(
            error?.message ||
              t('Failure authorizing your payment. Please try again.'),
            'danger'
          );
        }
        handleCleanup(true);
        onFailure();
      },
      [onFailure, handleCleanup, onShowMessage, t]
    );

    const handleAdditionalDetails = useCallback(
      async ({ data }, _c, actions) => {
        // this is where we can find out if the user cancels the 3DS
        const { transStatus } = JSON.parse(atob(data.details.threeDSResult));
        if (transStatus === 'U') {
          onFailure();
          actions.reject();
          return;
        }

        try {
          const { data: responseData } = await axios.post(
            '/adyen-payments-details',
            {
              threeDSResult: data.details.threeDSResult,
              productCode,
            }
          );

          if (responseData.resultCode === 'Authorised') {
            onSubmit3dsCode();
            actions.resolve(data); // this goes to handlePaymentCompleted
          } else {
            onFailure();
            onShowMessage(responseData.resultCode, 'danger');
            actions.reject();
          }
        } catch (e) {
          onFailure();
          onShowMessage(
            t('Failure authorizing your payment. Please try again.'),
            'danger'
          );
          actions.reject();
        }
      },
      [axios, onFailure, onSubmit3dsCode, productCode, onShowMessage, t]
    );

    const handleBeforeSubmit = useCallback((data, _element, { resolve }) => {
      paymentBrand.current = data.paymentMethod.brand;
      resolve(data);
    }, []);

    const handleActionHandled = useCallback(
      ({ componentType }) => {
        // for some reason the component type is different in test vs live.. check both
        if (['3DS2Fingerprint', '3DS2Challenge'].includes(componentType)) {
          on3dsAuth();
        }
      },
      [on3dsAuth]
    );

    const createCheckout = useCallback(
      async (session) => {
        if (!paymentContainer.current) return;

        // clean up if creating a new checkout
        if (checkout.current) {
          checkout.current.unmount();
        }

        const newConfig = {
          ...baseConfig,
          clientKey:
            REACT_APP_BBE_CONFIG.adyen.specific[productCode]?.clientKey ||
            REACT_APP_BBE_CONFIG.adyen.generic.clientKey,
          session,
          onPaymentCompleted: handlePaymentCompleted,
          onPaymentFailed: handlePaymentFailed,
          onAdditionalDetails: handleAdditionalDetails,
          beforeSubmit: handleBeforeSubmit,
          onActionHandled: handleActionHandled,
          countryCode,
          amount: session.amount,
        };

        const adyenCheckout = await AdyenCheckout(newConfig);

        const dropin = new Dropin(adyenCheckout, {
          openFirstPaymentMethod,
          paymentMethodsConfiguration,
          disableFinalAnimation: true,
          showStoredPaymentMethods: env === 'env',
          openFirstStoredPaymentMethod: false,
        }).mount(paymentContainer.current);

        checkout.current = dropin;

        onMountingEnd();
      },
      [
        baseConfig,
        countryCode,
        handleActionHandled,
        handleAdditionalDetails,
        handleBeforeSubmit,
        handlePaymentCompleted,
        handlePaymentFailed,
        onMountingEnd,
        openFirstPaymentMethod,
        paymentMethodsConfiguration,
        productCode,
      ]
    );

    useImperativeHandle(ref, () => ({
      submit() {
        checkout.current.submit();
        return checkout.current.isValid;
      },
      open() {
        checkout.current?.update({ openFirstPaymentMethod: true });
      },
      close() {
        checkout.current?.update({ openFirstPaymentMethod: false });
      },
      waitForThreeDSIframe() {
        return new Promise((resolve, reject) => {
          const MAX_POLL_ATTEMPTS = 60; // 1 mins
          const POLL_INTERVAL = 200; // milliseconds
          let attempts = 0;

          const pollForIframe = () => {
            if (!paymentContainer.current) {
              reject(new Error('Payment container not found'));
              return;
            }

            const iframe = paymentContainer.current.querySelector(
              'iframe[name="threeDSIframe"]'
            );

            if (iframe) {
              resolve(iframe);
            } else if (attempts < MAX_POLL_ATTEMPTS) {
              attempts++;
              setTimeout(pollForIframe, POLL_INTERVAL);
            } else {
              // refresh page
              reject(new Error('Timeout waiting for 3DS iframe'));
              window.location.reload();
            }
          };

          pollForIframe();
        });
      },
    }));

    // Initial session creation
    useEffect(() => {
      const initSession = async () => {
        onMountingStart();
        try {
          await dispatch(
            createSession(
              adyenPaymentFormType,
              accessToken ? shopperEmail : null,
              metadata,
              productCode,
              accessToken ? accessToken : null,
              countryCode,
              payNowData
            )
          );
          onSessionCreated();
        } catch (e) {
          onCreateSessionFailure(e);
          onMountingEnd();
        }
      };

      initSession();
    }, [
      bookings,
      accessToken,
      onMountingStart,
      dispatch,
      shopperEmail,
      metadata,
      productCode,
      countryCode,
      payNowData,
      onSessionCreated,
      onCreateSessionFailure,
      onMountingEnd,
    ]);

    // handle session changes
    useEffect(() => {
      const { session } = payment;
      if (!session || !paymentContainer.current) return;
      if (session.id === currentSessionId.current) return;

      currentSessionId.current = session.id;
      createCheckout(session);
    }, [createCheckout, payment]);

    // cleanup and clear session on unmount
    useEffect(() => {
      return () => {
        handleCleanup(false);
      };
    }, [handleCleanup]);

    return (
      <div>
        <div
          type="button"
          className="w-100"
          style={{ textAlign: 'left' }}
          onClick={onClick}
        >
          <div
            ref={paymentContainer}
            className={`payment${
              forceShowingPaymentMethodCheckBox
                ? ' force-showing-payment-method-checkbox'
                : ''
            }`}
            data-testid="paymentContainer"
            aria-label={`${ariaLabelPrefix}-container`}
          />
        </div>
      </div>
    );
  }
);

Payment.propTypes = {
  onSessionCreated: PropTypes.func,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  metadata: PropTypes.object,
  onSubmit3dsCode: PropTypes.func,
  on3dsAuth: PropTypes.func,
  onCreateSessionFailure: PropTypes.func,
  productCode: PropTypes.string.isRequired,
  countryCode: PropTypes.string,
  payNowData: PropTypes.object,
  onMountingStart: PropTypes.func,
  onMountingEnd: PropTypes.func,
  ariaLabelPrefix: PropTypes.string,
  openFirstPaymentMethod: PropTypes.bool,
  forceShowingPaymentMethodCheckBox: PropTypes.bool,
  onClick: PropTypes.func,
  onHardReload: PropTypes.func.isRequired,
  onShowMessage: PropTypes.func.isRequired,
};

export default Payment;
