import { allowedDatadogErrors } from '../constants';

const checkAllowedDatadogErrors = (event) => {
  // If there's no error, don't filter
  if (!event.error) return false;

  // Check against all error filters
  const matchedFilter = allowedDatadogErrors.find((filter) =>
    filter.condition(event.error)
  );

  return matchedFilter;
};

export default checkAllowedDatadogErrors;
