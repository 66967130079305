import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ReactComponent as ArrowDown } from '../../assets/images/icons/ArrowDownSoftNarrow.svg';
import { ReactComponent as ArrowUp } from '../../assets/images/icons/ArrowUpSoftNarrow.svg';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Picker from '../Picker/Picker';
import styles from './GuestLogin.module.css';

const GuestLoginDesktop = ({
  loggedIn,
  firstName,
  loading,
  profileButtons,
  logoutButton,
}) => {
  const { t } = useTranslate();
  const [open, setOpen] = useState(false);

  return (
    <div>
      {loggedIn && firstName ? (
        <Picker
          onToggle={() => setOpen(!open)}
          picker={
            <div className={styles.GuestLogin__loginHeader}>
              {t('Hi')}, {firstName}
              {open ? (
                <ArrowUp style={{ marginLeft: 5 }} />
              ) : (
                <ArrowDown style={{ marginLeft: 5 }} />
              )}
            </div>
          }
          content={() => (
            <div className={styles.loginItems__wrapper}>
              {profileButtons}
              <hr className="bbe-hr mb-2" />
              {logoutButton}
            </div>
          )}
          label="GuestLogin"
        />
      ) : (
        <div
          className={styles.GuestLogin__loginButton}
          aria-label="Log in your account"
          data-testid="GuestLoginDesktop--btn"
        >
          {loading ? (
            <LoadingSpinner blackSpinner />
          ) : (
            <div className="text-uppercase">{t('Log In')}</div>
          )}
        </div>
      )}
    </div>
  );
};
GuestLoginDesktop.propTypes = {
  loading: PropTypes.bool,
  loggedIn: PropTypes.bool,
  firstName: PropTypes.string,
  profileButtons: PropTypes.node,
  logoutButton: PropTypes.node,
};

export default GuestLoginDesktop;
