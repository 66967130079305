import moment from 'moment';

const calculateAddonPrice = (addon, booking) => {
  const { numAdults, children = [], startDate, endDate } = booking;
  const numOfNights = moment(endDate).diff(moment(startDate), 'days');
  const { price, tax, pricingType } = addon;

  switch (pricingType) {
    case 'Per stay':
      return Number(price + tax);
    case 'Per person per night':
      return (numAdults + children.length) * numOfNights * Number(price + tax);
    case 'Per person':
      return (numAdults + children.length) * Number(price + tax);
    case 'Per night':
      return numOfNights * Number(price + tax);
    default:
      return Number(price + tax);
  }
};

export default calculateAddonPrice;
