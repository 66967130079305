import React, { useState } from 'react';
import { Form, Input } from 'reactstrap';
import useMessage from '../../hooks/useMessage/useMessage';

const DevLogin = () => {
  const [Message, showMessage] = useMessage();
  const [password, setPassword] = useState('');

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (password === 'diamond') {
      window.localStorage.setItem(
        'validUser',
        JSON.stringify({
          valid: true,
          timestamp: Date.now(),
        })
      );
      window.location = window.location.href;
    } else {
      showMessage('Wrong Password!');
    }
  };

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: '100vh' }}
    >
      <div>
        <Message />
      </div>
      <div style={{ fontSize: '20px' }} className="my-3">
        Please Enter Password to Continue
      </div>
      <Form onSubmit={handleOnSubmit} noValidate>
        <Input
          aria-label="password"
          type="password"
          autoComplete="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form>
    </div>
  );
};

export default DevLogin;
