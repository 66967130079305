import PropTypes from 'prop-types';
import React, { useState } from 'react';
import client from '../../helpers/authClient';
import useMessage from '../../hooks/useMessage/useMessage';
import LoadingButton from '../LoadingButton/LoadingButton';
import styles from './SignUpForm.module.css';

const SignUpEmailVerificationMessage = ({ accessToken }) => {
  const [Message, setMessage, closeMessage] = useMessage();
  const [loading, setLoading] = useState(false);

  const handleSendEmailVerificationBtnClick = async () => {
    closeMessage();
    setLoading(true);
    try {
      await client.sendEmailVerification({
        accessToken: accessToken,
        redirectUrl: `${window.location.origin}${window.location.pathname}?email_verified=1`,
      });
      setMessage(
        'Email succesfuly sent! Please check your email for verification.',
        'success'
      );
    } catch (e) {
      setMessage(e.errorUserMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.SignUpForm__container}>
      <div className={styles.SignUpForm__header}>
        WELCOME TO OUR WONDERFUL WORLD
      </div>
      <hr className={styles.SignUpForm__line} />

      <div className="my-2">Thank you for signing up for an account!</div>
      <div className="text-center my-2">
        Please check your inbox for the verification email and click the link
        provided. If you do not receive a message within a few minutes, please
        check your Spam folder.
      </div>
      <div className="text-center mb-4">
        If you have not received this email, please click the button below to
        re-send the email.
      </div>
      <Message />
      <LoadingButton
        className="button"
        ariaLabel="Confirm Email"
        onClick={handleSendEmailVerificationBtnClick}
        loading={loading}
      >
        Re-send email
      </LoadingButton>
    </div>
  );
};
SignUpEmailVerificationMessage.propTypes = {
  accessToken: PropTypes.string,
};
export default SignUpEmailVerificationMessage;
