import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Label } from 'reactstrap';
import AccesibilityElement from '../AccesibilityElement/AccesibilityElement';
import styles from './CurrencyPickerContent.module.css';

const topCurrencies = ['USD', 'GBP', 'EUR', 'BRL', 'CNY', 'CHF', 'CAD', 'AUD'];

const CurrencyPickerContent = ({ onSelectCurrency, defaultCurrency }) => {
  const [searchValue, setSearchValue] = useState('');

  const currencies = useSelector((state) => state.appSettings.currencies.list);

  const defaultOption = {
    code: defaultCurrency,
    name: `${currencies[defaultCurrency]} (default)`,
  };

  const topOptions = topCurrencies
    .filter((curr) => curr !== defaultCurrency)
    .map((curr) => ({
      code: curr,
      name: currencies[curr],
    }));

  const otherOptions = Object.entries(currencies)
    .filter(([key]) => !topCurrencies.includes(key) && key !== defaultCurrency)
    .map(([key, attribute]) => ({ code: key, name: attribute }));

  const filteredCurrencies = useMemo(
    () =>
      Object.entries(currencies)
        .map(([key, attribute]) => ({
          code: key,
          name: key === defaultCurrency ? `${attribute} (default)` : attribute,
        }))
        .filter(
          (curr) =>
            searchValue === '' ||
            curr.code.toLowerCase().includes(searchValue.toLowerCase()) ||
            curr.name.toLowerCase().includes(searchValue.toLowerCase())
        ),
    [currencies, searchValue, defaultCurrency]
  );

  const allOptions = [
    defaultOption,
    { code: 'divider1', isDivider: true },
    ...topOptions,
    { code: 'divider2', isDivider: true },
    ...otherOptions,
  ];

  return (
    <>
      <div className={styles.Currency__title}>SELECT CURRENCY</div>
      <div className="position-relative my-3">
        <Input
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          className={styles.Currency__input}
          placeholder=" "
          aria-label="Filter currencies"
        />
        <Label className={styles.Currency_floatingLabel}>
          Search for currency...
        </Label>
      </div>
      <ul className={styles.Currency__currencies}>
        {(searchValue ? filteredCurrencies : allOptions).map((curr) =>
          curr.isDivider ? (
            <li key={curr.code} className={styles.Currency__divider}>
              <hr />
            </li>
          ) : (
            <AccesibilityElement
              key={curr.code}
              tagName="li"
              className={styles.Currency__option}
              onClick={() => {
                onSelectCurrency(curr.code);
                setSearchValue('');
              }}
              tabIndex={curr.disabled ? -1 : undefined}
            >
              {curr.name || curr.code}
            </AccesibilityElement>
          )
        )}
      </ul>
    </>
  );
};

CurrencyPickerContent.propTypes = {
  onSelectCurrency: PropTypes.func,
  defaultCurrency: PropTypes.string.isRequired,
};

export default CurrencyPickerContent;
