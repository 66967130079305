import PropTypes from 'prop-types';
import { useState } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../../assets/images/icons/ArrowDownSoft.svg';
import { ReactComponent as ArrowUpIcon } from '../../../assets/images/icons/ArrowUpSoft.svg';
import { ReactComponent as InfoIcon } from '../../../assets/images/icons/Info.svg';
import usePrintPrice from '../../../hooks/usePrintPrice/usePrintPrice';
import useTranslate from '../../../hooks/useTranslate/useTranslate';
import AccesibilityElement from '../../AccesibilityElement/AccesibilityElement';
import styles from './TaxesAndFees.module.css';
import TaxesAndFeesItem from './TaxesAndFeesItem';

const TaxesAndFees = ({ taxes, productCode, currencyCode }) => {
  const { t } = useTranslate();
  const { printPrice } = usePrintPrice(productCode);
  const [collapsed, setCollapsed] = useState(true);

  const { price } = printPrice({
    value: taxes.total,
    baseCurrency: currencyCode,
  });

  return (
    <div className="d-flex align-item-start my-4">
      <InfoIcon style={{ marginRight: '10px' }} />
      <div className="w-100">
        <AccesibilityElement
          role="button"
          className="d-flex align-items-center w-100"
          tagName="div"
          style={{ cursor: 'pointer' }}
          ariaLabel="Open taxes and fees"
          onClick={() => setCollapsed((prev) => !prev)}
        >
          <div className="d-flex justify-content-between align-items-center w-100 gap-2">
            <div>{t('Taxes and Fees')}</div>
            {collapsed ? (
              <ArrowDownIcon style={{ marginTop: 4 }} />
            ) : (
              <ArrowUpIcon />
            )}
            <div className="flex-grow-1 text-end bold">{price}</div>
          </div>
        </AccesibilityElement>

        {!collapsed && (
          <div>
            <hr
              className={styles.TaxesAndFees__dottedHr}
              style={{ marginTop: 20 }}
            />

            {taxes.list.map((charge, i) =>
              charge.isInclusive ? null : (
                <div key={charge.code}>
                  <TaxesAndFeesItem
                    charge={charge}
                    productCode={productCode}
                    currencyCode={currencyCode}
                  />
                  {i !== taxes.list.length - 1 && (
                    <hr className={styles.TaxesAndFees__dottedHr} />
                  )}
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

TaxesAndFees.propTypes = {
  taxes: PropTypes.object,
  productCode: PropTypes.string,
  currencyCode: PropTypes.string.isRequired,
};

export default TaxesAndFees;
