import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, Col, Label, Row } from 'reactstrap';
import { CURRENCY_SYMBOLS } from '../../../../../../constants';
import { gtmBookingFilters } from '../../../../../../gtm/events';
import useTranslate from '../../../../../../hooks/useTranslate/useTranslate';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import styles from './PriceRangeSlider.module.css';

const sliderStyle = {
  rail: {
    backgroundColor: '#F8F7F5',
    height: '4px',
    borderRadius: 0,
  },
  handle: {
    position: 'absolute',
    top: '17px',
    height: '20px',
    width: '20px',
    backgroundColor: 'white',
    opacity: 1,
    border: '1px solid black',
    boxShadow: 'none',
  },
  track: {
    backgroundColor: 'black',
    height: '4px',
  },
};

const PriceRangeSlider = ({
  lowerPriceAvailable,
  higherPriceAvailable,
  baseCurrency,
}) => {
  const { t } = useTranslate();
  const { setValue, watch, getValues } = useFormContext();

  const selectedCurrency = useSelector(
    (state) => state.appSettings.currencies.current
  );

  const [sectionOpen, setSectionOpen] = useState(false);
  const [activeMinPrice, setActiveMinPrice] = useState(
    getValues('filters.minPrice') || lowerPriceAvailable
  );
  const [activeMaxPrice, setActiveMaxPrice] = useState(
    getValues('filters.maxPrice') || higherPriceAvailable
  );
  const [inputMinPrice, setInputMinPrice] = useState(String(activeMinPrice));
  const [inputMaxPrice, setInputMaxPrice] = useState(String(activeMaxPrice));

  const filterMinPrice = watch('filters.minPrice');
  const filterMaxPrice = watch('filters.maxPrice');
  const displayType = getValues('displayType');

  useEffect(() => {
    if (
      filterMinPrice === lowerPriceAvailable &&
      filterMaxPrice === higherPriceAvailable
    ) {
      setActiveMinPrice(lowerPriceAvailable);
      setActiveMaxPrice(higherPriceAvailable);
      setInputMinPrice(String(lowerPriceAvailable));
      setInputMaxPrice(String(higherPriceAvailable));
    }
  }, [
    higherPriceAvailable,
    lowerPriceAvailable,
    filterMinPrice,
    filterMaxPrice,
  ]);

  const handleReset = () => {
    setActiveMinPrice(lowerPriceAvailable);
    setActiveMaxPrice(higherPriceAvailable);
    setInputMinPrice(String(lowerPriceAvailable));
    setInputMaxPrice(String(higherPriceAvailable));
    setValue('filters.minPrice', lowerPriceAvailable);
    setValue('filters.maxPrice', higherPriceAvailable);
  };

  const handleInputChange = (e, setter, activeSetter) => {
    const value = e.target.value;
    setter(value);
    if (value === '') {
      activeSetter(lowerPriceAvailable);
    } else {
      const numValue = Number(value);
      if (!isNaN(numValue)) {
        activeSetter(numValue);
      }
    }
  };

  const handleGTMBookingFilter = useCallback(
    (newMinPrice, newMaxPrice) => {
      if (
        newMinPrice !== lowerPriceAvailable ||
        newMaxPrice !== higherPriceAvailable
      ) {
        gtmBookingFilters('Price', displayType);
      }
    },
    [higherPriceAvailable, lowerPriceAvailable, displayType]
  );

  const handleInputBlur = (inputValue, setter, activeSetter, field) => {
    let newValue = Number(inputValue);
    if (isNaN(newValue) || newValue < lowerPriceAvailable) {
      newValue = lowerPriceAvailable;
    } else if (newValue > higherPriceAvailable) {
      newValue = higherPriceAvailable;
    }

    if (field === 'filters.minPrice') {
      handleGTMBookingFilter(newValue, activeMaxPrice);
    } else {
      handleGTMBookingFilter(activeMinPrice, newValue);
    }

    setter(String(newValue));
    activeSetter(newValue);
    setValue(field, newValue);
  };

  const handleBookingComplete = (newMinPrice, newMaxPrice) => {
    setValue('filters.minPrice', newMinPrice);
    setValue('filters.maxPrice', newMaxPrice);
    handleGTMBookingFilter(newMinPrice, newMaxPrice);
  };

  const isFiltered =
    lowerPriceAvailable !== null &&
    higherPriceAvailable !== null &&
    (filterMinPrice !== lowerPriceAvailable ||
      filterMaxPrice !== higherPriceAvailable);

  return (
    <div className={sectionOpen ? styles.PriceRangeSlider__boxExpanded : ''}>
      <FilterDropdown
        open={sectionOpen}
        hasValues={isFiltered}
        title={`${t('Price')} (${t('average per night')})`}
        onClear={handleReset}
        onClick={() => setSectionOpen((prev) => !prev)}
      />
      {sectionOpen && (
        <div>
          <div className="d-flex justify-content-between align-items-center position-relative">
            <Row className="d-flex w-100 align-items-center mb-4">
              <Col md="3" className={styles.PriceRangeSlider__inputField}>
                <Label>{t('Price From')}</Label>
                <div className="d-flex align-items-center">
                  <span>
                    {CURRENCY_SYMBOLS[baseCurrency || selectedCurrency]?.symbol}
                  </span>
                  <input
                    type="text"
                    inputMode="numeric"
                    aria-label="price-range-from"
                    value={inputMinPrice}
                    onChange={(e) =>
                      handleInputChange(e, setInputMinPrice, setActiveMinPrice)
                    }
                    onBlur={() =>
                      handleInputBlur(
                        inputMinPrice,
                        setInputMinPrice,
                        setActiveMinPrice,
                        'filters.minPrice'
                      )
                    }
                  />
                </div>
              </Col>
              <Col md="6">
                <Slider
                  className={styles.PriceRangeSlider__slider}
                  styles={sliderStyle}
                  range
                  min={lowerPriceAvailable}
                  max={higherPriceAvailable}
                  value={[activeMinPrice, activeMaxPrice]}
                  allowCross={false}
                  onChange={(value) => {
                    setActiveMinPrice(value[0]);
                    setActiveMaxPrice(value[1]);
                    setInputMinPrice(String(value[0]));
                    setInputMaxPrice(String(value[1]));
                  }}
                  onChangeComplete={(value) => {
                    handleBookingComplete(value[0], value[1]);
                  }}
                />
              </Col>
              <Col md="3" className={styles.PriceRangeSlider__inputField}>
                <Label>{t('Price To')}</Label>
                <div className="d-flex align-items-center">
                  <span>
                    {CURRENCY_SYMBOLS[baseCurrency || selectedCurrency]?.symbol}
                  </span>
                  <input
                    type="text"
                    inputMode="numeric"
                    aria-label="price-range-to"
                    value={inputMaxPrice}
                    onChange={(e) =>
                      handleInputChange(e, setInputMaxPrice, setActiveMaxPrice)
                    }
                    onBlur={() =>
                      handleInputBlur(
                        inputMaxPrice,
                        setInputMaxPrice,
                        setActiveMaxPrice,
                        'filters.maxPrice'
                      )
                    }
                  />
                </div>
              </Col>
            </Row>
            {isFiltered && (
              <Button
                className="hoverEffectButton mx-2 position-absolute text-uppercase"
                style={{ bottom: '0px', right: 0 }}
                aria-label="reset-price-range"
                onClick={handleReset}
              >
                {t('Reset')}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

PriceRangeSlider.propTypes = {
  lowerPriceAvailable: PropTypes.number,
  higherPriceAvailable: PropTypes.number,
  baseCurrency: PropTypes.string,
};

export default PriceRangeSlider;
