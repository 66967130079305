import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const LoadingButton = ({
  loading,
  className,
  ariaLabel,
  children,
  type,
  disabled,
  onClick,
  style,
}) => {
  return (
    <Button
      className={className}
      aria-label={ariaLabel}
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      {loading && (
        <LoadingSpinner blackSpinner={className.includes('transparent')} />
      )}
      <div
        style={{
          visibility: loading ? 'hidden' : 'visible',
          color: className.includes('transparent') ? 'black' : 'white',
        }}
      >
        {children}
      </div>
    </Button>
  );
};

LoadingButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default LoadingButton;
