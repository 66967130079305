import PropTypes from 'prop-types';
import React from 'react';
import ReactToPrint from 'react-to-print';
import { ReactComponent as PrintIcon } from '../../../../assets/images/icons/PrintIcon.svg';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';

const PrintConfirmationBookingButton = ({ bookingsConfirmationViewRef }) => {
  const { t } = useTranslate();
  return (
    <div style={{ marginRight: 12, cursor: 'pointer' }}>
      <PrintIcon style={{ marginRight: 7 }} />
      <ReactToPrint
        trigger={() => <span>{t('Print this page')}</span>}
        content={() => bookingsConfirmationViewRef.current}
      />
    </div>
  );
};

PrintConfirmationBookingButton.propTypes = {
  bookingsConfirmationViewRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default PrintConfirmationBookingButton;
