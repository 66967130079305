import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as AppleIcon } from '../../assets/images/icons/AppleIcon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/images/icons/facebookIcon.svg';
import { ReactComponent as GoogleIcon } from '../../assets/images/icons/googleIcon.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/images/icons/linkedIcon.svg';
import { env } from '../../globals';
import client from '../../helpers/authClient';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import styles from './SocialLoginButtons.module.css';

const SocialLoginButtons = ({ onError, onButtonsClick }) => {
  const { t } = useTranslate();
  const [searchParams, setSearchParams] = useSearchParams();
  const redirectionErrorMessageParam = searchParams.get('error_description');

  const handleSocialLoginButtonsClick = async (provider) => {
    onButtonsClick();
    try {
      await client.loginWithSocialProvider(provider, {
        redirectUri: `${window.location.origin}/login/callback`,
        useWebMessage: true,
        requireRefreshToken: true,
        state: btoa(
          JSON.stringify({
            returnUrl: window.location.pathname,
            params: window.location.search,
          })
        ),
      });
    } catch (e) {
      onError(
        'Oops! Something went wrong. Please refresh your browser or try again later.'
      );
    }
  };

  useEffect(() => {
    if (redirectionErrorMessageParam) {
      onError(redirectionErrorMessageParam);
      const params = new URLSearchParams(searchParams);
      params.delete('error_description');
      params.delete('error_message_key');
      params.delete('error');
      setSearchParams(params);
    }
  }, [onError, redirectionErrorMessageParam, setSearchParams, searchParams]);

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div
          className={styles.SocialLoginButtons__container}
          style={{ width: env !== 'prod' ? 'auto' : '100px' }}
        >
          <button
            aria-label="linkedIn social login"
            type="button"
            className={styles.SocialLoginButtons__iconBorder}
            onClick={() => handleSocialLoginButtonsClick('linkedin')}
          >
            <LinkedInIcon
              data-testid="linkedIn"
              className={styles.SocialLoginButtons__icon}
            />
          </button>
          <button
            aria-label="google social login"
            type="button"
            className={styles.SocialLoginButtons__iconBorder}
            onClick={() => handleSocialLoginButtonsClick('google')}
          >
            <GoogleIcon
              className={styles.SocialLoginButtons__icon}
              data-testid="google"
            />
          </button>
          {env !== 'prod' ? (
            <>
              <button
                aria-label="facebook social login"
                type="button"
                className={styles.SocialLoginButtons__iconBorder}
                onClick={() => handleSocialLoginButtonsClick('facebook')}
              >
                <FacebookIcon
                  className={styles.SocialLoginButtons__icon}
                  data-testid="facebook"
                />
              </button>
              <button
                aria-label="apple social login"
                type="button"
                className={styles.SocialLoginButtons__iconBorder}
                onClick={() => handleSocialLoginButtonsClick('apple')}
              >
                <AppleIcon
                  className={styles.SocialLoginButtons__icon}
                  data-testid="apple"
                />
              </button>
            </>
          ) : null}
        </div>
      </div>
      <div className={styles.SocialLoginButtons__terms}>
        {t(
          'By signing on using these social channels, you acknowledge you have read the'
        )}{' '}
        <a
          href="https://www.belmond.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          {t('Privacy Policy')}
        </a>{' '}
        {t('and')}{' '}
        <a
          href="https://www.belmond.com/legal/terms-and-conditions"
          target="_blank"
          rel="noreferrer"
        >
          {t('T&Cs')}
        </a>
        .
      </div>
    </div>
  );
};
SocialLoginButtons.propTypes = {
  onError: PropTypes.func,
  onButtonsClick: PropTypes.func,
};
export default SocialLoginButtons;
