import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { FormGroup, Label } from 'reactstrap';
import { ReactComponent as ErrorCrossIcon } from '../../assets/images/icons/ErrorCross.svg';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import styles from './InputComponent.module.css';

const InputComponent = forwardRef(
  (
    {
      name,
      rules,
      control,
      type,
      label,
      errors,
      placeholder,
      passivePlaceholder,
      onFocus,
      extraClassName,
      autoComplete,
      disabled,
      inputClassName,
    },
    ref
  ) => {
    const { t } = useTranslate();
    const passivePlaceholderFocus = (e) => {
      e.target.placeholder = passivePlaceholder;
    };

    const passivePlaceholderBlur = (e) => {
      e.target.placeholder = ' ';
    };

    return (
      <FormGroup
        className={`${styles.InputComponent__formGroup} ${extraClassName}`}
      >
        <div className="position-relative">
          {label && (
            <Label className={styles.InputComponent__label} for={`${name}ID`}>
              {label}
            </Label>
          )}
          <Controller
            name={name}
            rules={rules}
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <>
                <input
                  className={`${styles.InputComponent__bbeFormControl} ${
                    fieldState.invalid
                      ? styles.InputComponent__inputTextError
                      : styles.InputComponent__inputText
                  } ${inputClassName}`}
                  type={type}
                  {...field}
                  placeholder={passivePlaceholder ? ' ' : placeholder}
                  onFocus={
                    passivePlaceholder ? passivePlaceholderFocus : onFocus
                  }
                  ref={ref || field.ref}
                  aria-label={name}
                  id={`${name}ID`}
                  aria-describedby={`${name}-error`}
                  required={rules}
                  autoComplete={autoComplete}
                  disabled={disabled}
                  onBlur={(e) => {
                    passivePlaceholder && passivePlaceholderBlur(e);
                    field.onBlur();
                  }}
                />
                {fieldState.invalid && (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      field.onChange('');
                    }}
                    disabled={!field.value}
                    style={{ cursor: 'pointer' }}
                    className={styles.InputComponent__errorButton}
                    aria-label={`Remove ${name} value`}
                  >
                    <ErrorCrossIcon
                      className={styles.InputComponent__errorIcon}
                    />
                  </button>
                )}
              </>
            )}
          />
        </div>
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <div
              className={styles.InputComponent__errorText}
              id={`${name}-error`}
              data-testid={`${name}-error`}
            >
              {t('Error')}: {message}
            </div>
          )}
        />
      </FormGroup>
    );
  }
);
InputComponent.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  onFocus: PropTypes.func,
  passivePlaceholder: PropTypes.string,
  placeholder: PropTypes.string,
  rules: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  type: PropTypes.string,
  extraClassName: PropTypes.string,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string,
};

export default InputComponent;
