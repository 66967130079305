import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';
import styles from './VideoPlayer.module.css';

const VideoPlayer = ({ video }) => {
  return (
    <div
      className={styles.VideoPlayer__container}
      aria-label="video-player"
      data-testid="videoPlayer"
    >
      <ReactPlayer
        className={styles.VideoPlayer__reactVideoPlayer}
        url={video}
        width="100%"
        height="100%"
        controls={true}
      />
    </div>
  );
};
VideoPlayer.propTypes = {
  video: PropTypes.string,
};

export default VideoPlayer;
