import PropTypes from 'prop-types';
import { createElement } from 'react';

const AccesibilityElement = ({
  children,
  tagName,
  ariaLabel,
  onClick,
  role,
  className,
  ariaExpanded,
  style,
  tabIndex,
  id,
}) => {
  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  return createElement(
    tagName,
    {
      'aria-label': ariaLabel,
      onClick,
      onKeyDown,
      role,
      tabIndex: tabIndex || 0,
      className,
      'aria-expanded': ariaExpanded,
      style,
      id,
    },
    children
  );
};

AccesibilityElement.propTypes = {
  children: PropTypes.node,
  tagName: PropTypes.string,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  role: PropTypes.string,
  className: PropTypes.string,
  ariaExpanded: PropTypes.bool,
  style: PropTypes.object,
  tabIndex: PropTypes.number,
  id: PropTypes.string,
};

export default AccesibilityElement;
