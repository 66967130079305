import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  values: [],
};

export const guestFormSlice = createSlice({
  initialState,
  name: 'guestFormSlice',
  reducers: {
    resetGuestFormState: () => initialState,
    setGuestFormValuesFromObjectsArray: (state, action) => {
      state.values = action.payload.reduce((prev, curr) => {
        const {
          guest: { address, ...guest },
          secondaryGuests,
          isMarketingOptin,
          id,
          productCode,
        } = curr;
        const guestFormValuesId = `${id}-${productCode}`;

        const guestValues = Object.entries(guest).map(([name, value]) => ({
          name: `bookingsGuestValues[${guestFormValuesId}].${name}`,
          value,
        }));
        const guestAddressValues = Object.entries(address).map(
          ([name, value]) => ({
            name: `bookingsGuestValues[${guestFormValuesId}].address.${name}`,
            value,
          })
        );
        const secondaryGuestsValues = secondaryGuests.reduce(
          (prev, curr, i2) => {
            const secondaryGuest = Object.entries(curr).map(
              ([name, value]) => ({
                name: `bookingsGuestValues[${guestFormValuesId}].secondaryGuests.${i2}.${name}`,
                value,
              })
            );
            return [...prev, ...secondaryGuest];
          },
          []
        );

        const isMarketingOptinValue = {
          name: `bookingsGuestValues[${guestFormValuesId}].isMarketingOptin`,
          value: isMarketingOptin,
        };
        return [
          ...prev,
          ...guestValues,
          ...guestAddressValues,
          ...secondaryGuestsValues,
          isMarketingOptinValue,
        ];
      }, []);
    },
    setGuestFormValue: (state, action) => {
      const valueObj = state.values.find(
        (obj) => obj.name === action.payload.name
      );
      if (valueObj) {
        valueObj.value = action.payload.value;
      } else {
        state.values.push(action.payload);
      }
    },
  },
});

export const {
  setGuestFormValuesFromObjectsArray,
  setGuestFormValue,
  resetGuestFormState,
} = guestFormSlice.actions;

export default guestFormSlice.reducer;
