// Consents
// C0001 -> Strictly Necessary Cookies
// C0002 -> Performance Cookies
// C0003 -> Functional Cookies
// C0004 -> Targeting Cookies

import { useState, useEffect, useCallback } from 'react';

export const useConsentGroups = () => {
  const [activeGroups, setActiveGroups] = useState(() => {
    // Get initial state from OnetrustActiveGroups if available
    if (typeof window !== 'undefined' && window.OnetrustActiveGroups) {
      return window.OnetrustActiveGroups.split(',')
        .map((group) => group.trim())
        .filter((group) => group.length > 0);
    }
    return [];
  });

  // Memoize the parsing function to ensure consistent reference
  const parseGroups = useCallback((groupString) => {
    return groupString
      .split(',')
      .map((group) => group.trim())
      .filter((group) => group.length > 0);
  }, []);

  useEffect(() => {
    // Function to handle consent updates
    const handleConsentUpdate = () => {
      if (window.OnetrustActiveGroups) {
        const newGroups = parseGroups(window.OnetrustActiveGroups);

        // Only update state if groups have actually changed
        setActiveGroups((prevGroups) => {
          const areEqual =
            prevGroups.length === newGroups.length &&
            prevGroups.every((group, index) => group === newGroups[index]);

          return areEqual ? prevGroups : newGroups;
        });
      }
    };

    // Add listener for consent updates
    window.addEventListener('OneTrustGroupsUpdated', handleConsentUpdate);

    // Get initial state in case it wasn't available during useState initialization
    handleConsentUpdate();

    // Cleanup listener
    return () => {
      window.removeEventListener('OneTrustGroupsUpdated', handleConsentUpdate);
    };
  }, [parseGroups]);

  return activeGroups;
};

export default useConsentGroups;
