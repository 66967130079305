import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import addressFormStyles from '../../../../components/AddressForm/AddressForm.module.css';
import countries from '../../../../components/AddressForm/Countries.json';
import guestDetailsStyles from '../../../../components/GuestDetailsForm/GuestDetailsForm.module.css';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import Label from '../../../../components/Label/Label';
import SelectComponent from '../../../../components/SelectComponent/SelectComponent';
import singleGuestformStyles from '../../../../components/SingleGuestForm/SingleGuestForm.module.css';
import { agentTypes } from '../../../../constants';
import { CHINA_VERSION } from '../../../../globals';
import useGoogleMapsLookup from '../../../../hooks/useGoogleMapsLookup/useGoogleMapsLookup';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from './AgentFormFields.module.css';

const AgentFormFields = ({ disabled }) => {
  const {
    t,
    constants: {
      prefixOptions,
      formRules: { emailRule },
    },
  } = useTranslate();

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const inputRef = useRef();
  const [placeValues, googleMapsLoaded] = useGoogleMapsLookup(
    !CHINA_VERSION ? inputRef : null
  );

  useEffect(() => {
    if (placeValues) {
      Object.entries(placeValues).forEach(([key, value]) => {
        setValue(`agent.address.${key}`, value, {
          shouldValidate: true,
        });
      });
      setValue(`agent.address.find`, '');
    }
  }, [placeValues, setValue]);

  return (
    <Row className="mb-5 mx-1">
      <div className={singleGuestformStyles.SingleGuestForm__sectionContainer}>
        <div className={singleGuestformStyles.SingleGuestForm__sectionHeading}>
          <h2 style={{ marginTop: 0 }}>{t('Travel Agent Information')}</h2>
        </div>
        <Row>
          <Col
            xs="12 mb-2"
            md="6"
            className={guestDetailsStyles.GuestDetailsForm__prefixGroup}
          >
            <div style={{ width: '30%', marginRight: 30 }}>
              <SelectComponent
                name="agent.prefix"
                control={control}
                label={t('Prefix')}
                setValue={setValue}
                options={prefixOptions}
                watch={watch}
                errors={errors}
                autoComplete="honorific-prefix"
                disabled={disabled}
              />
            </div>
            <InputComponent
              name={`agent.firstName`}
              control={control}
              rules={{ required: t('First name cannot be blank') }}
              errors={errors}
              placeholder=" "
              label={<Label text={t('First Name')} required />}
              autoComplete="given-name"
              disabled={disabled}
            />
          </Col>
          <Col md="6">
            <InputComponent
              name={`agent.lastName`}
              control={control}
              rules={{ required: t('Last name cannot be blank') }}
              errors={errors}
              placeholder=" "
              label={<Label text={t('Last Name')} required />}
              autoComplete="family-name"
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <InputComponent
              name={`agent.phone`}
              control={control}
              rules={{
                pattern: {
                  message: t('Please provide a valid phone number'),
                  value: /^\+?[0-9 -]+$/,
                },
                required: t('Phone number cannot be blank'),
              }}
              errors={errors}
              placeholder=" "
              label={<Label text={t('Agent Telephone Number')} required />}
              autoComplete="tel"
              disabled={disabled}
            />
          </Col>
          <Col md="6">
            <InputComponent
              name={`agent.email`}
              control={control}
              rules={emailRule}
              errors={errors}
              passivePlaceholder="alex@example.com"
              label={<Label text={t('Agent email')} required />}
              autoComplete="email"
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <SelectComponent
              name="agent.type"
              control={control}
              label={<Label text="Agent Type" required />}
              rules={{
                required: t('Agent Type cannot be blank'),
              }}
              setValue={setValue}
              options={agentTypes}
              watch={watch}
              errors={errors}
              disabled={disabled}
            />
          </Col>
          <Col md="6">
            <InputComponent
              name={`agent.id`}
              control={control}
              rules={{
                required: t('Agent ID cannot be blank'),
              }}
              errors={errors}
              placeholder=" "
              label={<Label text={t('Agent ID')} required />}
              disabled
            />
          </Col>
        </Row>
        <h5 className={`mt-5 ${styles.AgentFormFields__agencyDetailsTitle}`}>
          {t('Agency Details')}
        </h5>
        <Row>
          <Col md="12">
            <InputComponent
              name={`agent.companyName`}
              control={control}
              rules={{
                required: t('Agency Name cannot be blank'),
              }}
              errors={errors}
              placeholder=" "
              label={<Label text={t('Agency Name')} required />}
              disabled={disabled}
            />
          </Col>
        </Row>
        {!CHINA_VERSION && (
          <Row>
            <Col md="12">
              {/* we have a space in placeholder in order to keep the label inside the input field  */}
              {googleMapsLoaded && (
                <InputComponent
                  ref={inputRef}
                  name={`agent.address.find`}
                  control={control}
                  errors={errors}
                  placeholder=" "
                  autoComplete="off"
                  label={
                    <span style={{ fontWeight: '500' }}>
                      {t('Find Your Agency Address')}
                    </span>
                  }
                  Autocomplete={false}
                  extraClassName={addressFormStyles.AddressForm__icon}
                  disabled={disabled}
                />
              )}
            </Col>
          </Row>
        )}
        <Row>
          <Col md="6">
            <InputComponent
              name={`agent.address.address`}
              control={control}
              rules={{ required: t('Address cannot be blank') }}
              errors={errors}
              placeholder=" "
              label={<Label text={t('Address')} required />}
              autoComplete="street-address"
              disabled={disabled}
            />
          </Col>
          <Col md="6">
            <InputComponent
              name={`agent.address.city`}
              control={control}
              rules={{ required: t('City cannot be blank') }}
              errors={errors}
              placeholder=" "
              label={<Label text={t('City')} required />}
              autoComplete="address-level2"
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <SelectComponent
              name={`agent.address.countryCode`}
              control={control}
              label={<Label text={t('Country / Region')} required />}
              setValue={setValue}
              options={countries}
              watch={watch}
              errors={errors}
              rules={{ required: t('Country cannot be blank') }}
              autoComplete="country"
              disabled={disabled}
            />
          </Col>
          <Col md="6">
            <InputComponent
              name={`agent.address.postalCode`}
              control={control}
              rules={{
                required: t('Zip / Postal Code  cannot be blank'),
              }}
              errors={errors}
              placeholder=" "
              label={<Label text={t('Zip / Postal Code')} required />}
              autoComplete="postal-code"
              disabled={disabled}
            />
          </Col>
        </Row>
      </div>
    </Row>
  );
};

AgentFormFields.propTypes = {
  disabled: PropTypes.bool,
};

export default AgentFormFields;
