import { datadogRum } from '@datadog/browser-rum';
import PropTypes from 'prop-types';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import ErrorView from '../../views/ErrorView/ErrorView';
const handleError = (error, errorInfo) => {
  if (error.message?.includes('ResizeObserver loop')) {
    return;
  }

  // Create new error with modified message
  const enrichedError = new Error(`Error Page - ${error.message}`);
  // Set stack at construction time
  Object.defineProperty(enrichedError, 'stack', {
    value: error.stack,
    enumerable: true,
  });

  console.error(enrichedError);
  datadogRum.addError(enrichedError, { errorInfo });
};

const ErrorBoundary = ({ children }) => {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorView} onError={handleError}>
      {children}
    </ReactErrorBoundary>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
