import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CurrencyPickerContent from '../CurrencyPickerContent/CurrencyPickerContent';
import CurrencyPickerHeader from '../CurrencyPickerHeader/CurrencyPickerHeader';
import Picker from '../Picker/Picker';

const CurrencyDesktop = ({ currencies, onSelectCurrency, defaultCurrency }) => {
  const [open, setOpen] = useState(false);
  return (
    <Picker
      onToggle={() => setOpen(!open)}
      picker={
        <CurrencyPickerHeader
          currencies={currencies}
          open={open}
          defaultCurrency={defaultCurrency}
        />
      }
      content={(close) => (
        <CurrencyPickerContent
          defaultCurrency={defaultCurrency}
          onSelectCurrency={(currencyCode) => {
            onSelectCurrency(currencyCode);
            close();
            setOpen(false);
          }}
        />
      )}
      label="CurrencyType"
      offset
    />
  );
};
CurrencyDesktop.propTypes = {
  currencies: PropTypes.object.isRequired,
  onSelectCurrency: PropTypes.func.isRequired,
  defaultCurrency: PropTypes.string.isRequired,
};

export default CurrencyDesktop;
