import { useCallback, useEffect, useRef, useState } from 'react';

const useGoogleMapsLookup = (inputRef) => {
  const autoCompleteRef = useRef();
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [placeValues, setPlaceValues] = useState({});

  const initGoogleMaps = useCallback(() => {
    const options = {
      fields: ['address_components', 'name'],
    };

    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener('place_changed', async function () {
      const place = await autoCompleteRef.current.getPlace();
      if (!place.address_components?.length) return;

      const postalCode = place.address_components.filter(({ types }) =>
        types.includes('postal_code')
      )?.[0]?.long_name;

      const city = place.address_components.filter(
        ({ types }) =>
          types.includes('locality') || types.includes('postal_town')
      )?.[0]?.long_name;

      const countryCode = place.address_components.filter(({ types }) =>
        types.includes('country')
      )?.[0]?.short_name;

      const state = place.address_components.filter(({ types }) =>
        types.includes('administrative_area_level_1')
      )?.[0]?.long_name;

      const addressRoute =
        place.address_components.filter(({ types }) =>
          types.includes('route')
        )?.[0]?.long_name || '';

      const streetNumber =
        place.address_components.filter(({ types }) =>
          types.includes('street_number')
        )?.[0]?.long_name || '';

      setPlaceValues({
        address: `${addressRoute} ${streetNumber}`,
        postalCode,
        city,
        countryCode,
        state,
      });
    });
  }, [inputRef, setPlaceValues]);

  useEffect(() => {
    if (inputRef?.current && googleMapsLoaded) {
      initGoogleMaps();
    }
  }, [googleMapsLoaded, initGoogleMaps, inputRef]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window?.google?.maps?.places) {
        setGoogleMapsLoaded(true);
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);

  return [placeValues, googleMapsLoaded];
};

export default useGoogleMapsLookup;
