import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { ReactComponent as ArrowLeftIcon } from '../../../../../../assets/images/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../../../assets/images/icons/ArrowRight.svg';
import { useScreenDetector } from '../../../../../../hooks/useScreenDetector/useScreenDetector';
import styles from './RoomTypeSelector.module.css';

const RoomTypeSelector = ({ roomTypes, selectedRoomTypeCode, onChange }) => {
  // create a ref for the room selector container and the room buttons container
  const roomSelectorContainerRef = useRef(null);
  const roomButtonsContainerRef = useRef(null);
  const { isDesktop } = useScreenDetector();

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [focusedRoomIndex, setFocusedRoomIndex] = useState(0);

  useEffect(() => {
    const i = roomTypes.findIndex(({ code }) => selectedRoomTypeCode === code);
    setFocusedRoomIndex(i);
  }, [selectedRoomTypeCode, roomTypes]);

  // on resize, get the width of the room selector container
  // and the scroll width of the room buttons container
  const handleWindowResize = useCallback(
    (scrollLeftValue = roomButtonsContainerRef.current?.scrollLeft) => {
      const roomSelectorContainerWidth =
        roomSelectorContainerRef.current?.offsetWidth;
      const roomButtonsContainerWidth =
        roomButtonsContainerRef.current?.scrollWidth;

      // show/hide room button elements based on scroll state

      const isScrollable =
        roomSelectorContainerWidth < roomButtonsContainerWidth;

      const isLeftArrow = isScrollable && scrollLeftValue > 0;

      const isRightArrow =
        isScrollable &&
        scrollLeftValue <
          roomButtonsContainerWidth - roomSelectorContainerWidth;

      setShowLeftArrow(isLeftArrow);
      setShowRightArrow(isRightArrow);
    },
    []
  );

  // trigger resize function on window resize
  useEffect(() => {
    // call initially, and whenever the rooms change
    handleWindowResize();

    // set an event for resizes
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [roomTypes, handleWindowResize]);

  useEffect(() => {
    // Calculate the position of the selected room button
    const roomButtons = roomButtonsContainerRef.current?.children;
    if (
      roomButtons.length > 0 &&
      focusedRoomIndex >= 0 &&
      focusedRoomIndex < roomButtons.length
    ) {
      // Scroll to the left edge of the selected button
      const selectedButton = roomButtons[focusedRoomIndex];
      let scrollPosition = selectedButton.offsetLeft;
      const isFirstButton = focusedRoomIndex === 0;
      const isLastButton = focusedRoomIndex === roomButtons.length - 1;

      // ensure that the left arrow is not overlapping the button by scrolling a bit more
      scrollPosition = isFirstButton ? 0 : scrollPosition - 30;

      handleWindowResize(scrollPosition);
      if (
        (isLastButton && showRightArrow) ||
        (isFirstButton && showLeftArrow)
      ) {
        // wait for the next lifecycle where to buttons will be hidden/shown
        // before we scroll
        return;
      }

      roomButtonsContainerRef.current?.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      });
    }
  }, [focusedRoomIndex, handleWindowResize, showRightArrow, showLeftArrow]);

  const containerStyles = useMemo(
    () => ({
      marginLeft: !showLeftArrow || isDesktop ? 20 : 0,
      marginRight: !showRightArrow || isDesktop ? 20 : 0,
    }),
    [showLeftArrow, showRightArrow, isDesktop]
  );

  const buttonContainerStyles = useMemo(
    () => ({
      paddingRight: showRightArrow ? 20 : 0,
      paddingLeft: showLeftArrow ? 30 : 0,
    }),
    [showRightArrow, showLeftArrow]
  );

  return (
    <div
      ref={roomSelectorContainerRef}
      className={styles.Room__select_container}
      style={containerStyles}
    >
      <div
        ref={roomButtonsContainerRef}
        className={styles.Room__select_buttons}
        style={buttonContainerStyles}
      >
        {roomTypes.map((roomType, i) => {
          const isSelected = selectedRoomTypeCode === roomType.code;
          return (
            <Button
              data-roomindex={i}
              style={{
                background: isSelected ? '#ebebe9' : 'white',
                color: 'black',
                border: `1px solid ${isSelected ? '#171717' : '#ebebe9'}`,
                marginRight: 10,
              }}
              className="button"
              key={roomType.code}
              onClick={() => onChange(roomType)}
              aria-label={`Select ${roomType.title}`}
            >
              {roomType.title}
            </Button>
          );
        })}
      </div>

      {/* Slider controls */}
      {showLeftArrow && (
        <button
          data-testid="left-arrow-button"
          type="button"
          className="select-arrow-left"
          aria-label="previous room type"
          onClick={() => setFocusedRoomIndex(focusedRoomIndex - 1)}
        >
          <ArrowLeftIcon />
        </button>
      )}

      {showRightArrow && (
        <button
          data-testid="right-arrow-button"
          type="button"
          className="select-arrow-right"
          onClick={() => setFocusedRoomIndex(focusedRoomIndex + 1)}
          aria-label="next room type"
        >
          <ArrowRightIcon />
        </button>
      )}
    </div>
  );
};

RoomTypeSelector.propTypes = {
  roomTypes: PropTypes.array,
  selectedRoomTypeCode: PropTypes.string,
  onChange: PropTypes.func,
};

export default RoomTypeSelector;
