const newsletterSubscription = (siteLocation) => {
  if (!window.dataLayer) return;

  const gtmData = {
    event: 'newsletterSubscription',
    siteLocation,
  };
  window.dataLayer.push(gtmData);
};

export default newsletterSubscription;
