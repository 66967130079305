import PropTypes from 'prop-types';
import React from 'react';
import styles from './LoadingSpinner.module.css';

const LoadingSpinner = ({ blackSpinner }) => {
  const spinnerStyle = {
    '--uib-color': blackSpinner ? 'black' : 'white',
  };

  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div
        className={styles.LoadingSpinner__container}
        style={spinnerStyle}
        data-testid="spinner"
      >
        <div className={styles.LoadingSpinner__line}></div>
        <div className={styles.LoadingSpinner__line}></div>
        <div className={styles.LoadingSpinner__line}></div>
        <div className={styles.LoadingSpinner__line}></div>
        <div className={styles.LoadingSpinner__line}></div>
        <div className={styles.LoadingSpinner__line}></div>
        <div className={styles.LoadingSpinner__line}></div>
        <div className={styles.LoadingSpinner__line}></div>
        <div className={styles.LoadingSpinner__line}></div>
        <div className={styles.LoadingSpinner__line}></div>
        <div className={styles.LoadingSpinner__line}></div>
        <div className={styles.LoadingSpinner__line}></div>
      </div>
    </div>
  );
};
LoadingSpinner.propTypes = {
  blackSpinner: PropTypes.bool,
};

export default LoadingSpinner;
