import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/images/icons/ArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/images/icons/ArrowUp.svg';
import AddonsListItem from '../../../../components/AddonsListItem/AddonsListItem';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import useAxios from '../../../../hooks/useAxios/useAxios';
import useMessage from '../../../../hooks/useMessage/useMessage';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from './AddonsList.module.css';

const AddonsList = ({
  booking,
  pendingAddonsActions,
  onAddAddon,
  onRemoveAddon,
}) => {
  const {
    t,
    constants: { errorMessage },
  } = useTranslate();
  const axios = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [expandMoreAddons, setExpandMoreAddons] = useState(false);
  const [addons, setAddons] = useState([]);
  const [Message, showMessage, closeMessage] = useMessage();
  const media = useSelector((state) => state.media.mediaUrls);

  const getAddonImage = (addonCode) => {
    return media.find((mediaItem) =>
      mediaItem.includes(`AddonImages/${addonCode}`)
    );
  };

  const isAddonPicked = (addonCode) => {
    return !!(
      pendingAddonsActions.toAdd.find(
        (a) =>
          a.addon.code === addonCode &&
          a.bookingId === booking.id &&
          a.productCode === booking.productCode
      ) ||
      (booking.addons.find((a) => a.code === addonCode) &&
        !pendingAddonsActions.toRemove.find(
          (a) =>
            a.addon.code === addonCode &&
            a.bookingId === booking.id &&
            a.productCode === booking.productCode
        ))
    );
  };

  const fetchAddons = useCallback(async () => {
    closeMessage();
    setIsLoading(true);
    const params = {
      numAdults: booking.numAdults,
      productCode: booking.productCode,
      startDate: booking.startDate,
      endDate: booking.endDate,
    };
    try {
      const res = await axios.get('/addons', { params });
      setAddons(() => [...res.data]);
    } catch (e) {
      showMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [
    booking.endDate,
    booking.numAdults,
    booking.productCode,
    booking.startDate,
    closeMessage,
    showMessage,
    errorMessage,
    axios,
  ]);

  useEffect(() => {
    fetchAddons();
  }, [fetchAddons]);

  return (
    <div className="mt-4 d-print-none">
      <div>
        <div className={styles.AddonsList__containerHeading}>
          {t('Enhance Your Stay')}
        </div>
      </div>
      <Message />
      <Row>
        {addons.length > 0
          ? (expandMoreAddons ? addons : addons.slice(0, 2)).map((addon) => (
              <Col xs="12" md="6 my-2" key={addon.title}>
                <AddonsListItem
                  booking={booking}
                  addon={addon}
                  image={getAddonImage(addon.crsCode)}
                  addonPicked={isAddonPicked(addon.code)}
                  onAdd={() =>
                    onAddAddon(booking.productCode, booking.id, addon)
                  }
                  onRemove={() =>
                    onRemoveAddon(booking.productCode, booking.id, addon)
                  }
                />
              </Col>
            ))
          : isLoading && (
              <div className="my-5">
                <LoadingSpinner blackSpinner />
              </div>
            )}
      </Row>
      {addons.length > 2 && (
        <div className="d-flex justify-content-center">
          <Button
            aria-label={`See ${
              expandMoreAddons ? 'less' : 'more'
            } addon options`}
            className="button transparent my-4"
            onClick={() => setExpandMoreAddons(!expandMoreAddons)}
          >
            {expandMoreAddons ? (
              <div className="d-flex align-items-center justify-content-center text-uppercase">
                {t('View Less Add-ons')}
                <ArrowUpIcon style={{ marginLeft: '13px' }} />
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-center text-uppercase">
                {t('View More Add-ons')}
                <ArrowDownIcon style={{ marginLeft: '13px' }} />
              </div>
            )}
          </Button>
        </div>
      )}
    </div>
  );
};
AddonsList.propTypes = {
  booking: PropTypes.object.isRequired,
  pendingAddonsActions: PropTypes.object.isRequired,
  onAddAddon: PropTypes.func.isRequired,
  onRemoveAddon: PropTypes.func.isRequired,
};
export default AddonsList;
