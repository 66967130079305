import { createSlice } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';
import {
  getCalendarAvailability,
  getMonthsToFetch,
  updateCalendarAvailability,
} from './helpers';

const initialState = {
  calendar: {
    products: {}, // Keyed by productCode
    isOpen: false,
  },
};

const createProductState = () => ({
  lastFetched: null,
  lastFetchedParams: null,
  list: [],
  fetchedMonths: [],
  isLoading: false,
  currentMonth: null,
});

const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    setCalendarAvailability: (state, action) => {
      const { productCode, data } = action.payload;
      state.calendar.products[productCode] = {
        ...state.calendar.products[productCode],
        list: data,
        lastFetched: new Date(),
      };
    },
    setLastCalendarFetchedParams: (state, action) => {
      const { productCode, params } = action.payload;
      state.calendar.products[productCode] = {
        ...state.calendar.products[productCode],
        lastFetchedParams: params,
      };
    },
    addFetchedMonths: (state, action) => {
      const { productCode, months } = action.payload;
      const product =
        state.calendar.products[productCode] || createProductState();
      state.calendar.products[productCode] = {
        ...product,
        fetchedMonths: [...new Set([...product.fetchedMonths, ...months])],
      };
    },
    resetFetchedMonths: (state, action) => {
      const { productCode } = action.payload;
      if (state.calendar.products[productCode]) {
        state.calendar.products[productCode].fetchedMonths = [];
      }
    },
    setIsLoading: (state, action) => {
      const { productCode, isLoading } = action.payload;
      if (!state.calendar.products[productCode]) {
        state.calendar.products[productCode] = createProductState();
      }
      state.calendar.products[productCode].isLoading = isLoading;
    },
    setCurrentCalendarMonth: (state, action) => {
      const { productCode, month } = action.payload;
      if (!state.calendar.products[productCode]) {
        state.calendar.products[productCode] = createProductState();
      }
      state.calendar.products[productCode].currentMonth = month;
    },
    setIsCalendarOpen: (state, action) => {
      state.calendar.isOpen = action.payload;
    },
  },
});

export const fetchCalendarAvailability =
  (productCode, currentMonth, params, moment, axios, countryCode) =>
  async (dispatch, getState) => {
    const {
      bookings: { agentProfileRatePlanAssociated },
      availability: {
        calendar: { products },
      },
    } = getState();

    const productState = products[productCode] || createProductState();
    const {
      fetchedMonths: fm,
      list: l,
      lastFetched,
      lastFetchedParams,
      isLoading,
    } = productState;

    let fetchedMonths = fm;
    let list = l;

    const paramsChanged = !isEqual(
      { productCode, ...params },
      { productCode, ...lastFetchedParams }
    );

    if (isLoading && paramsChanged) {
      return;
    }

    if (paramsChanged) {
      fetchedMonths = [];
    }

    const isPrevMonth =
      !!fetchedMonths.length &&
      fetchedMonths.every((month) => {
        return moment(currentMonth).isBefore(month, 'month');
      });

    let monthsToFetch = getMonthsToFetch(
      currentMonth,
      fetchedMonths,
      isPrevMonth,
      moment
    );

    const monthAlreadyFetched = fetchedMonths.includes(currentMonth);
    const expired = lastFetched
      ? moment().diff(moment(lastFetched), 'minutes') > 15
      : true;

    const fetchNewBatch = monthsToFetch.length > 2;

    if (!isPrevMonth && monthAlreadyFetched && !fetchNewBatch && !expired) {
      return;
    }

    if (!lastFetchedParams || paramsChanged || expired) {
      dispatch(setLastCalendarFetchedParams({ productCode, params }));
      dispatch(setCalendarAvailability({ productCode, data: [] }));
      dispatch(resetFetchedMonths({ productCode }));

      list = [];
      fetchedMonths = [];
      monthsToFetch = getMonthsToFetch(
        currentMonth,
        fetchedMonths,
        isPrevMonth,
        moment
      );
    }

    dispatch(setIsLoading({ productCode, isLoading: true }));
    dispatch(addFetchedMonths({ productCode, months: monthsToFetch }));

    const results = await Promise.all(
      monthsToFetch.map((month) =>
        getCalendarAvailability(
          productCode,
          month,
          params,
          agentProfileRatePlanAssociated,
          moment,
          axios,
          countryCode
        )
      )
    );

    let updatedCalendarAvailability;
    if (results.length > 0) {
      updatedCalendarAvailability = updateCalendarAvailability(
        results,
        list,
        moment
      );

      dispatch(
        setCalendarAvailability({
          productCode,
          data: updatedCalendarAvailability,
        })
      );
    }

    dispatch(setIsLoading({ productCode, isLoading: false }));
    return updatedCalendarAvailability;
  };

export const {
  setCalendarAvailability,
  addFetchedMonths,
  setIsLoading,
  setLastCalendarFetchedParams,
  setCurrentCalendarMonth,
  resetFetchedMonths,
  setIsCalendarOpen,
} = availabilitySlice.actions;

export default availabilitySlice.reducer;
