const formatWithThousandPostfix = (number) => {
  const inThousands = number / 1000;
  // Handle different scales while maintaining K format
  if (inThousands >= 1000) {
    // 1 million or more
    return `${inThousands.toFixed(0)}K`;
  }
  return `${inThousands.toFixed(1)}K`;
};

export default formatWithThousandPostfix;
