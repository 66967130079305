import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as YellowInfoIcon } from '../../assets/images/icons/YellowInfo.svg';
import { ReactComponent as GreenCheckIcon } from '../../assets/images/icons/greenCheck.svg';
import { ReactComponent as RedCancelCrossIcon } from '../../assets/images/icons/redCancelCross.svg';
import styles from './SearchRoomAlert.module.css';

const SearchRoomAlert = ({ title, description, type }) => {
  const iconType = {
    success: <GreenCheckIcon data-testid="success" />,
    info: <YellowInfoIcon data-testid="info" />,
    warning: <RedCancelCrossIcon data-testid="warning" />,
  };

  return (
    <div className={`${styles.SearchRoomAlert__container} ${styles[type]}`}>
      <div className={styles.SearchRoomAlert__icon}>{iconType[type]}</div>
      <div>
        <div className={styles.SearchRoomAlert__title}>{title}</div>
        <div className={styles.SearchRoomAlert__description}>{description}</div>
      </div>
    </div>
  );
};
SearchRoomAlert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
};

export default SearchRoomAlert;
