import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ModalCloseButton from '../../../../components/ModalCloseButton/ModalCloseButton';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from './PaymentInformationModal.module.css';

const PaymentInformationModal = ({ onClose, open }) => {
  const { t } = useTranslate();
  return (
    <Modal
      isOpen={open}
      external={
        <ModalCloseButton
          onClose={onClose}
          className={styles.PaymentInformationModal__closeButton}
        />
      }
      contentClassName={styles.PaymentInformationModal__modalWrapper}
      toggle={onClose}
    >
      <ModalBody>
        <div className={styles.PaymentInformationModal__container}>
          <h2>{t('Why We Request This Information')}</h2>
          <ul
            className={`${styles.PaymentInformationModal__list} diamond-list`}
          >
            <li>
              {t(
                'A valid credit/debit card is necessary to secure your reservation; your card will only be charged if this is specifically stated'
              )}
            </li>
            <li>
              {t(
                "If you've opted for a rate that requires a deposit or prepayment, the specified amount will be charged to the credit/debit card details you have provided"
              )}
            </li>
            <li>
              {t(
                'If cancellation is permitted, please note that failure to cancel within the specified timeframe (refer to rate rules) may lead to charges'
              )}
            </li>
            <li>
              {t(
                'Your credit/debit card details will be requested during check-in'
              )}
            </li>
            <li>{t('There is no fee associated with using a credit card')}</li>
          </ul>
        </div>
      </ModalBody>
    </Modal>
  );
};
PaymentInformationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default PaymentInformationModal;
